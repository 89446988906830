@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.express_card_cards {
  @include flex-justify-align(space-between, flex-start);
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 450px) {
    flex-direction: column;
    @include flex-justify-align(stretch, stretch);
    gap: 10px;
  }
}
