@import "styles/vars";
@import "styles/mixins";

.table_summary {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-bottom: 30px;

  @media (max-width: 1100px) {
    gap: 5%;
  }

  @media (max-width: 768px) {
    gap: 12px;
  }

  @media (max-width: 450px) {
    gap: 0px;
  }
}

.slider {
  margin-left: 10px;
}