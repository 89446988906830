@import "styles/vars";
@import "styles/mixins";

.price {
  border-top: 1px solid $divider_color;
  padding: 20px 60px;
  width: 100%;
  bottom: 0px;
  display: flex;
  position: sticky;
  gap: 10px;
  justify-content: space-between;
  color: $grey;
  flex-direction: column;
  background-color: rgba(247, 248, 253, 1);
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba($font-color-black-secondary, 0.7);
    h3 {
      @include font-size(14, $regular, 24);
    }
    span {
      @include font-size(14, $regular, 24);
    }
    &:first-child {
      color: $font-color-black-secondary;
      h3 {
        @include font-size(16, $regular, 24);
      }
      span {
        @include font-size(16, $demibold, 24);
        margin-left: 5px;
      }
    }
    &:last-child {
      border-top: 1px solid $divider_color;
      color: $purple;
      padding-top: 16px;
      h3 {
        @include font-size(16, $demibold, 24);
      }
      span {
        @include font-size(16, $bold, 24);
        margin-left: 5px;
      }
    }
  }
  @media (max-width: 1150px) {
    margin-left: 0px;
    padding: 20px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    padding: 20px 10px;
  }
  @media (max-width: 450px) {
    position: static;
    padding: 20px 20px;
    width: 100%;
    div {
      &:last-child {
        margin-top: 20px;
        h3 {
          @include font-size(14, $demibold, 20);
        }
        span {
          @include font-size(14, $demibold, 20);
          margin-left: 5px;
        }
      }
      padding-bottom: 0px;
      h3 {
        @include font-size(12, $regular, 20);
      }
      span {
        @include font-size(12, $regular, 20);
      }
      &:first-child {
        h3 {
          @include font-size(13, $medium, 20);
        }
        span {
          @include font-size(13, $demibold, 20);
        }
      }
    }
  }
}

.taxes_included {
  h3 {
    font-weight: 300 !important;
  }
  span {
    font-weight: 300 !important;
  }
}
