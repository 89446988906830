@import "styles/vars";
@import "styles/mixins";

.card {
  padding: 22px 36px 19px 47px;
  background-color: rgba(228, 234, 238, 1);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 127px 127px 1fr;
  gap: 74px;
  margin-bottom: 20px;

  div {
    display: flex;
    flex-direction: column;

    p {
      &:first-child {
        @include font-size(12, $medium, 20);
        color: rgba(25, 28, 35, 0.65);
      }

      &:last-child {
        @include font-size(14, $medium, 18, -0.4);
        color: rgba(25, 28, 35, 0.65);
      }
    }

    &:last-child {
      justify-content: flex-end;
      align-items: flex-end;
    }

    div {
      display: flex;
      flex-direction: row;
    }
  }

  &_links {
    .link {

      @include font-size(14, $medium, 18, -0.4);
      color: $purple;

      border-right: 1px solid rgba(25, 28, 35, 0.65);
      padding-right: 11px;

      &:last-child {
        border-right: none;
        padding: 0 0 0 11px;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 90px 100px 1fr;
  }

  @media (max-width: 450px) {
    &_links {
      width: 100%;
      @include flex-justify-align(space-between);

      .link {
        width: 50%;
        padding: auto;

        &:last-child {
          text-align: end;
        }
      }
    }
  }
}

.card_mobile {
  padding: 26px 20px;
  margin-bottom: 10px;

  border-radius: 4px;
  background-color: rgba(228, 234, 238, 1);

  display: flex;
  flex-direction: column;
  gap: 10px;

  &_title {
    @include font-size(12, $medium, 20);
    color: rgba(25, 28, 35, 0.65);
  }

  &_row {
    @include flex-justify-align(space-between);

    &_title {
      @include font-size(12, $medium, 20);
      color: rgba(25, 28, 35, 1);
    }

    &_value {
      @include font-size(14, $medium, 18, -0.4);
      color: rgba(25, 28, 35, 1);
    }


  }

}