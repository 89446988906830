@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.quantity {
  color: #fff;
  @include font-size(12, $medium, 22);
  display: flex;
  align-items: center;
  &_button {
    background: $background-button;
    animation: all 0.3s ease-out;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    padding: 6px;
    width: 45px;
    height: 40px;

    &:disabled {
      svg {
        opacity: 0.2;
      }
    }
  }

  input {
    border: none;
    -webkit-appearance: none;
    border-radius: 0;
    height: 40px;
    width: 50px;
    background: $font-color-black-secondary;
    box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
    color: $white;
    text-align: center;
    @include font-size(16, $bold, 24);

    &:disabled {
      background: $font-color-black-secondary;
      color: $white;
      opacity: 1;
    }
  }

  .quantity_input_disabled {
    color: rgba($white, 0.2) !important;
  }
}
