@import "styles/vars";
@import "styles/mixins";

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table_summary {
  display: flex;
  gap: 12px;
  justify-content: center;
  @media (max-width: 1100px) {
    gap: 5%;
  }

  @media (max-width: 768px) {
    gap: 12px;
  }

  @media (max-width: 450px) {
    gap: 0px;
  }
}

.table_headers {
  color: $font-color-black-secondary;
  display: grid;
  padding: 0 1.5rem;
  grid-template-columns: 15.8% 15.8% 15.8% 15.8% 15.8% auto;
  grid-gap: 10px;
  border-bottom: 1px solid rgba(226, 209, 249, 0.3);
  @media (max-width: 772px) {
    grid-template-columns: 21% 16% 17% 10% 13% auto;
  }
}

.table_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  &_item_mb {
    @include font-size(12, $regular, 20, 1);
    @include rounded-box(1.25rem, 1.25rem);
    color: $font-color-gray;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    :first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      :first-child {
        @include font-size(12, $medium, 20);
        color: #7233cc;
      }
    }
    :nth-child(3) {
      @include font-size(12, $medium, 20);
      color: #20272b;
    }
    @media (max-width: 772px) {
      margin-bottom: 18px;
    }
  }

  &_item {
    @include rounded-box-list-second(1.5rem, 16px);
    border-bottom: 1px solid rgb(242, 242, 242);
    display: grid;
    grid-template-columns: 15% 15% 25% 15% 10% auto;
    grid-gap: 10px;
    @include font-size(14, $regular, 20);
    color: $violet;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: $font-color-purple;
    }
  }
}

.more_btn {
  margin: 1rem;
  align-self: flex-start;
  @media (max-width: 772px) {
    align-self: center;
  }
}

.mb_date {
  @include font-size(12, $regular, 20);
  color: rgb(125, 134, 169);
}

.nodata {
  display: flex;
  color: rgba($violet, 0.5);
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 38vh;
  @include font-size(14, $medium, 20);
  @media (max-width: 772px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-content: center;
  }
}

.transaction_list {
  margin-top: 32px;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;
  padding: 23px 63px;
  @media (max-width: 1100px) {
    padding: 23px 0px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  svg {
    width: 50px !important;
    height: 50px !important;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
}

.slider {
  margin-left: 10px;
}
