@import "styles/vars";
@import "styles/mixins";

.root {
  float: right;
  width: 47%;
  background-color: rgba(247, 248, 253, 1);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  height: 100%;
  background-color: $background_color;
  @media (max-width: 1157px) {
    width: 42%;
  }
  @media (max-width: 768px) {
    width: calc(100% + 40px);
    margin-right: -20px;
    position: static;
  }
}

.root_main {
  transition: all 0.5s ease-out;
  padding: 60px 20px;
  height: 100%;
  width: 100%;
  h2 {
    @include font-size(24, $bold, 40);
    padding-bottom: 20px;
    border-bottom: 1px solid $divider_color;
    margin-bottom: 20px;
  }
  @media (max-width: 1157px) {
    padding: 60px 20px 20px;
  }
  @media (max-width: 768px) {
    padding: 20px 20px;
    h2 {
      @include font-size(16, $demibold, 40);
      padding-bottom: 0px;
    }
  }
}

.root_price {
  border-top: 1px solid $divider_color;
  padding: 20px 60px;
  width: 100%;
  bottom: 0;
  display: flex;
  position: absolute;
  justify-content: space-between;
  color: $grey;
  flex-direction: column;
  background-color: $background_color;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    color: rgba(25, 28, 35, 0.7);
    h3 {
      @include font-size(14, $regular, 24);
    }
    span {
      @include font-size(14, $regular, 24);
    }
    &:first-child {
      color: $font-color-black-secondary;
      h3 {
        @include font-size(16, $regular, 24);
      }
      span {
        @include font-size(16, $demibold, 24);
        margin-left: 5px;
      }
    }
    &:last-child {
      border-top: 1px solid $divider_color;
      color: $purple;
      padding-top: 16px;
      padding-bottom: 0px;
      h3 {
        @include font-size(16, $demibold, 24);
      }
      span {
        @include font-size(16, $bold, 24);
        margin-left: 5px;
      }
    }
  }
  @media (max-width: 1157px) {
    padding: 20px 20px;
  }
  @media (max-width: 768px) {
    position: static;
    padding: 20px 20px;
    div {
      &:last-child {
        margin-top: 20px;
        h3 {
          @include font-size(14, $demibold, 20);
        }
        span {
          @include font-size(14, $demibold, 20);
          margin-left: 5px;
        }
      }
      padding-bottom: 0px;
      h3 {
        @include font-size(12, $regular, 20);
      }
      span {
        @include font-size(12, $regular, 20);
      }
      &:first-child {
        h3 {
          @include font-size(13, $medium, 20);
        }
        span {
          @include font-size(13, $demibold, 20);
        }
      }
    }
  }
}

.taxes_included {
  h3 {
    color: rgba($font-color-black-secondary, 0.6);
    @include font-size(14, $thin, 20);
    font-weight: 300 !important;
  }
  span {
    color: rgba($font-color-black-secondary, 0.6);
    @include font-size(14, $thin, 20);
    font-weight: 300 !important;
  }
}

.details_accordion {
  background-color: #f7f8fd;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(228, 234, 238, 1);
  div:nth-child(1) {
    margin-bottom: 0px;
  }
}

.checkoutMobile_details {
  padding: 20px;
  background-color: #f7f8fd;
  &_subtotal {
    display: flex;
    justify-content: space-between;
    color: $purple;
    h3 {
      @include font-size(14, $demibold, 20);
    }
    span {
      @include font-size(14, $bold, 20);
    }
  }
}

.root_pay {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  height: 100%;
  background-color: rgba(247, 248, 253, 1);
  padding: 60px 0px 24px;
  @media (max-width: 768px) {
    padding: 0px 20px;
  }
}

.root_pay_main {
  padding: 0 60px;
  height: 100%;
  width: 100%;
  h2 {
    @include font-size(24, $bold, 40);
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(228, 234, 238, 1);
    margin-bottom: 20px;
  }
  @media (max-width: 1157px) {
    padding: 20px 20px;
  }
  @media (max-width: 768px) {
    padding: 20px 0px;
    h2 {
      display: none;
    }
    border-top: 1px solid rgba(228, 234, 238, 1);
  }
}

.root_pay_price {
  border-top: 1px solid rgba(228, 234, 238, 1);
  padding: 20px 60px;
  width: 100%;
  bottom: 0;
  display: flex;
  position: absolute;
  justify-content: space-between;
  color: rgba(91, 107, 130, 1);
  flex-direction: column;
  background-color: #f7f8fd;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    color: rgba(25, 28, 35, 0.7);
    h3 {
      @include font-size(14, $regular, 24);
    }
    span {
      @include font-size(14, $regular, 24);
    }
    &:first-child {
      color: $font-color-black-secondary;
      h3 {
        @include font-size(16, $regular, 24);
      }
      span {
        @include font-size(16, $demibold, 24);
        margin-left: 5px;
      }
    }
    &:last-child {
      border-top: 1px solid rgba(228, 234, 238, 1);
      color: $purple;
      padding-top: 16px;
      padding-bottom: 0px;
      h3 {
        @include font-size(16, $demibold, 24);
      }
      span {
        @include font-size(16, $bold, 24);
        margin-left: 5px;
      }
    }
  }
  @media (max-width: 1157px) {
    padding: 20px 20px;
  }
  @media (max-width: 768px) {
    position: static;
    padding: 20px 0px;
    div {
      &:last-child {
        display: none;
      }
      padding-bottom: 0px;
      h3 {
        @include font-size(12, $regular, 20);
      }
      span {
        @include font-size(12, $regular, 20);
      }
      &:first-child {
        h3 {
          @include font-size(13, $medium, 20);
        }
        span {
          @include font-size(13, $demibold, 20);
        }
      }
    }
  }
}

.taxes_included {
  color: rgba(25, 28, 35, 0.5);
}

.checkoutMobile_details_price {
  border-top: 1px solid rgba(228, 234, 238, 1);
  padding: 20px 60px;
  width: 100%;
  bottom: 0;
  display: flex;
  position: absolute;
  justify-content: space-between;
  color: rgba(91, 107, 130, 1);
  flex-direction: column;
  background-color: #f7f8fd;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    color: rgba(25, 28, 35, 0.7);
    h3 {
      @include font-size(14, $regular, 24);
    }
    span {
      @include font-size(14, $regular, 24);
    }
    &:first-child {
      color: $font-color-black-secondary;
      h3 {
        @include font-size(16, $regular, 24);
      }
      span {
        @include font-size(16, $demibold, 24);
        margin-left: 5px;
      }
    }
  }
  @media (max-width: 1157px) {
    padding: 20px 20px;
  }
  @media (max-width: 768px) {
    position: static;
    padding: 20px 0px;
    div {
      padding-bottom: 0px;
      h3 {
        @include font-size(12, $regular, 20);
      }
      span {
        @include font-size(12, $regular, 20);
      }
      &:first-child {
        h3 {
          @include font-size(13, $medium, 20);
        }
        span {
          @include font-size(13, $demibold, 20);
        }
      }
    }
  }
}
