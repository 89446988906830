@import "styles/vars";
@import "styles/mixins";

.root {
  background-color: #fff;
}

.accordion_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;

  h3 {
    @include font-size(24, $bold, 42);
    color: rgba(144, 149, 166, 0.65);
  }

  svg {
    path {
      fill: rgba(144, 149, 166, 0.65);
    }
  }

  &_active {
    @extend .accordion_header;

    padding-bottom: 0;
    border: none;

    h3 {
      color: $purple;
    }

    svg {
      path {
        fill: $purple;
      }
    }
  }
}

.accordion_content {
  @include font-size(12, $regular, 16);
  color: $font-color-black;
  max-height: 0px;

  overflow: hidden;
  transition: all 0.45s;
}

.open {
  max-height: 1000px;
  margin-bottom: 20px;
}

.icon {
  width: 12px;
  height: 6px;

  transition: 0.5s;
  color: $font-color-purple;
}

.icon_open {
  @extend .icon;
  transform: rotate(180deg);
}

.divider {
  border-bottom: 1px solid $divider-color;
  margin-bottom: 10px;
}