@import "styles/vars";
@import "styles/mixins";

.footer {
  width: 100%;
  padding: 18px 100px 30px;
  background-color: $background-color;
  @include flex-justify-align(space-between, center);
  box-shadow: 0px 4px 16px $shadow;
  &_left {
    @include font-size(14, $regular, 20);
    @include flex-justify-align(space-between, flex-start);
    flex-direction: column;
    gap: 13px;
    &_price {
      @include font-size(24, $medium, 20);
    }
  }
  &_right {
    @include font-size(24, $medium, 20);
    @include flex-justify-align(space-between, flex-end);
    flex-direction: column;
    gap: 13px;
  }

  @media (max-width: 1150px) {
    padding: 18px 12px 22px 15px;
    width: calc(100% - 10px);
    margin-left: 10px;
  }

  @media (max-width: 700px) {
    padding: 2%;
    width: calc(100% - 2%);
    margin-left: 2%;
    &_left {
      @include font-size(12, $regular, 20);
      &_price {
        @include font-size(20, $medium, 20);
      }
    }
  }

  @media (max-width: 450px) {
    margin-bottom: 10px;
    flex-direction: column;
    padding: 17px 45px;
    width: 100%;
    margin-left: 0px;

    &_left {
      @include flex-justify-align(space-between, center);
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;

      @include font-size(14, $regular, 20);

      &_price {
        @include font-size(14, $medium, 20);
      }
    }

    &_right {
      width: 100%;
    }
  }
}

.footer_process_order_btn {
  @include reset-button();
  width: 250px;
  height: 60px;
  border-radius: 4px;

  background: $background-button;
  color: $white;
  @include font-size(18, $medium, 20);

  transition: all 0.5s;

  &:not(:disabled):hover {
    opacity: 0.5;
  }

  &:disabled {
    background: rgba(216, 216, 216, 1);
    color: rgba($violet, 0.5);
    opacity: 0.6;
  }

  @media (max-width: 700px) {
    width: 10em;
    @include font-size(16, $medium, 20);
  }

  @media (max-width: 450px) {
    width: 100%;
    @include font-size(18, $medium, 20);
  }
}

.footer_cancel_order_btn {
  @include reset-button();
  width: 250px;
  height: 60px;
  border: 1px solid $purple;
  border-radius: 10px;

  color: $purple;
  @include font-size(18, $medium, 20);

  transition: all 0.5s;

  &:not(:disabled):hover {
    opacity: 0.5;
  }

  &:disabled {
    border: none;
    background: rgba(216, 216, 216, 1);
    color: rgba($violet, 0.5);
    opacity: 0.6;
  }

  @media (max-width: 700px) {
    width: 10em;
    @include font-size(16, $medium, 20);
  }

  @media (max-width: 450px) {
    width: 100%;
    @include font-size(18, $medium, 20);
  }
}

.cancel_modal {
  @include flex-justify-align(space-between);
  flex-direction: column;
  height: 200px;
  gap: 20px;
  margin: 40px 76px 50px;

  @media (max-width: 450px) {
    margin: 20px;
    gap: 0px;
    justify-content: flex-start;
    h1 {
      color: $violet;
      @include font-size(16, $medium, 40);
      text-align: center;
    }
    p {
      @include font-size(14, $regular, 18);
    }
    button {
      &:first-child {
        @include font-size(14, $medium, 18);
        text-transform: uppercase;
      }

      &:last-child {
        width: 120px;
        @include font-size(14, $bold, 18);
        text-transform: uppercase;
      }
    }
  }
}
