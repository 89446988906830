@import "styles/vars";
@import "styles/mixins";

$black: $font-main-color;
$grey: rgba(255, 255, 255, 0.5);
$red: rgba(232, 74, 95, 1);
$grey-secondary: rgba(25, 28, 35, 0.6);

.item_name {
  @include flex-justify-align(space-between);
  gap: 1em;
  h3 {
    color: $black;
    @include font-size(16, $regular, 24);
    flex: 1;
  }
}

.item_avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid $purple;
  span {
    @include font-size(28, $regular, 35, -0.5);
    color: $purple;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_btn {
    @include reset-button;
    @include font-size(12, $regular, 11);

    width: 92px;
    height: 24px;

    color: #fff;
    position: absolute;
    bottom: -10px;

    background-color: rgba(91, 107, 130, 1);
    border-radius: 12px;

    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
  @media (max-width: 768px) {
    width: 40px;
    span {
      @include font-size(20, $regular, 35, -0.5);
    }
    height: 40px;
    &_btn {
      width: 78px;
      height: 78px;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.item_taxes {
  gap: 10px;
  padding-bottom: 20px;
  @include flex-justify-align(space-between, space-between);
  flex-direction: column;
  .item_tax {
    @include flex-justify-align(space-between, flex-start);
    color: $black;
    @include font-size(16, $thin, 20);
  }
}

.item_description {
  display: flex;
  padding: 20px 0px;
  border-top: 1px solid $divider-color;
  flex-direction: column;
  gap: 20px;
  div {
    p {
      @include font-size(16, $regular, 24);
      color: $font-color-black-secondary;
      &:last-child {
        opacity: 0.5;
      }
    }
  }
}

.description_value {
  font-weight: 300 !important;
}

.item_price_range {
  color: $font-main-color;
  @media (max-width: 450px) {
    @include font-size(16, $bold, 20);
  }
}

.btn_delete {
  border: none;
  background: $background-button;
  border-radius: 4px;
  width: 100%;
  height: 60px;
  color: #fff;
  @include font-size(18, $medium, 20);
  transition: all 0.5s;
  &:hover {
    opacity: 0.5;
  }
  @media (max-width: 450px) {
    display: none;
  }
}
