@import "styles/vars";
@import "styles/mixins";

.contacts {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 0px 10%;
  &_wrapper {
    @include flex-justify-align(space-between, flex-start);
  }

  @media (max-width: 1024px) {
    padding: 0px 71px 0px 25px;
  }

  @media (max-width: 772px) {
    padding: 0px 43px 0px 30px;
  }

  @media (max-width: 450px) {
    &_wrapper {
      flex-direction: column;
      @include flex-justify-align(space-between, flex-start);
    }
    padding: 0px 20px;
    gap: 16px;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  &_title {
    @include font-size(16, $medium, 36);
    color: $black;
  }
  &_address {
    padding: 20px 0px 0px;
    @include font-size(14, $regular, 26);
    color: $grey;
  }
  &_transaction_title {
    @include flex-justify-align(space-between, flex-start);
    flex-direction: column;
    gap: 5px;
    &_name {
      @include font-size(16, $bold, 40);
      color: rgba($font-color-black-secondary, 0.6);
    }
    &_status {
      @include flex-justify-align(center);
      @include font-size(14, $bold, 20);
      width: 90px;
      height: 40px;
      color: #fff;
      background-color: $font-color-black-secondary;
      border-radius: 4px;
      &_paid {
        @extend .contact_transaction_title_status;
        background-color: rgba($font-color-black-secondary, 0.3);
        color: #fff;
      }
    }
  }
  @media (max-width: 450px) {
    width: 100%;
    &_title {
      @include font-size(16, $bold, 20);
    }
    &_address {
      @include font-size(14, $regular, 18);
    }
    &_transaction_title {
      flex-direction: row;
      padding: 26px 0px 0px;
    }
  }
}
