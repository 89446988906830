@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  display: flex;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
  }
}
