@import "styles/vars";
@import "styles/mixins";

.table_content {
  display: flex;
  flex-direction: column;

  &_item {
    display: grid;
    grid-template-columns: repeat(6, minmax(10%, 1fr));
    align-items: start;
    gap: 15px;

    background-color: #fff;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(226, 209, 249, 0.3);

    @include font-size(16, $regular, 20);
    color: $violet;

    margin-bottom: 5px;

    text-align: left;

    &:hover {
      color: $purple;
    }

    @media (max-width: 768px) {
      gap: 0px;
      padding: 20px;
    }
  }
}

.item_mb {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 16px 0px $shadow;
  @include rounded-box-list-second(10px, 14px);

  &_header {
    @include flex-justify-align(space-between);
    margin-bottom: 12px;

    &_transaction_id {
      display: flex;
      flex-direction: column;
      @include font-size(12, $regular, 18);
      color: rgba($violet, 0.5);

      &_value {
        color: $violet;
        @include font-size(12, $thin, 18);
      }
    }

    &_date {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;

      @include font-size(12, $regular, 18);
      color: rgba($violet, 0.5);

      &_value {
        color: $violet;
        @include font-size(12, $thin, 18);
      }
    }
  }

  &_name {
    @include font-size(14, $medium, 18);
    color: $violet;
  }

  &_value {
    margin-bottom: 6px;

    @include font-size(14, $regular, 18);
    color: $violet;
  }

  &_bottom {
    @include flex-justify-align(stretch, stretch);
    flex-direction: column;

    @include font-size(12, $medium, 18);
    color: rgba($violet, 0.5);

    &_first_row {
      @include flex-justify-align(space-between);
      margin-bottom: 2px;
    }

    &_second_row {
      @include flex-justify-align(space-between);
    }
  }
}
