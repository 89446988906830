@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.product {
  padding: 8px 32px 12px 22px;

  box-shadow: 0px 4px 16px $shadow;
  border-radius: 4px;

  &_top {
    @include flex-justify-align(space-between);

    .product_description {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &_count {
        padding: 2px 8px;
        max-width: max-content;
        background: rgba(240, 99, 90, 0.2);
        border-radius: 10px;

        @include font-size(8, $bold, 13, 0.007);
        color: #f0635a;
      }

      &_name {
        @include font-size(16, $bold, 20, -0.41);
        color: rgba(0, 0, 0, 1);
        @include text-truncate();
      }

      &_price {
        p:first-child {
          @include font-size(12, $medium, 13);
          color: $purple;
        }

        p:last-child {
          @include font-size(10, $regular, 13);
          color: $font-color-black-secondary;
        }
      }

      &_sold_out {
        @extend .product_description;

        .product_description_count {
          padding: 2px 8px;
          background: rgba(184, 193, 204, 0.2);
          border-radius: 10px;

          @include font-size(8, $bold, 13, 0.007);
          color: #f0635a;
        }

        .product_description_name {
          text-decoration: line-through;
        }

        .product_description_price {
          text-decoration: line-through;
        }
      }
    }
  }

  @media (max-width: 820px) {
    padding: 48px 12px 12px 22px;
  }

  @media (max-width: 450px) {
    position: relative;
    &_top {
      padding-bottom: 24px;
    }
  }
}

.taxes {
  @include flex-justify-align(space-between);

  padding-bottom: 24px;

  border-bottom: 1px solid $divider-color;

  .tax_name {
    @include font-size(14, $regular, 21);
    color: rgba($font-color-black-secondary, 0.5);
  }

  .tax_amount {
    @include font-size(14, $regular, 21);
    color: rgba($font-color-black-secondary, 0.5);
  }

  &_sold_out {
    @extend .taxes;
    text-decoration: line-through;
  }
}

.product_bottom {
  padding-top: 14px;
  @include flex-justify-align(space-between);

  .quantity {
    @include flex-justify-align(space-between);
    gap: 20px;
  }

  .item_price {
    @include font-size(16, $bold, 40);
    color: $font-color-black-secondary;
  }

  @media (max-width: 450px) {
    .quantity {
      button {
        width: 34px;
        height: 30px;

        svg {
          width: 12px;
          height: 12px;
        }
      }

      input {
        width: 38px;
        height: 30px;

        @include font-size(14, $bold, 18);
      }
    }

    .delete {
      position: absolute;
      top: 16px;
      right: 20px;
      width: 30px;
      height: 30px;

      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
}

.product_bottom_sold_out {
  @extend.product_bottom;

  .quantity {
    button {
      opacity: 0.2;
    }

    input {
      opacity: 0.2;
    }

    .delete {
      button {
        opacity: 1 !important;
      }
    }
  }

  .item_price {
    text-decoration: line-through;
  }
}
