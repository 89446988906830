@import "styles/vars";
@import "styles/mixins";

$paid_color: #3f505a;
$hover_chart_background: #7233cc;

.graph_wrapper {
  position: absolute;
  width: calc(100% - 42px);
  bottom: 25px;
  line {
    opacity: 0;
  }
  @media (max-width: 450px) {
    width: calc(100% - 56px);
    height: 139px;
    bottom: 0px;
    top: 97px;
  }
}

.tooltip {
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: -151px;
  right: 20px;
  width: max-content;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid rgba(255, 207, 38, 1);
  border-radius: 4px;
  &_month {
    display: flex;
    align-items: center;
    @include font-size(14, $regular, 32);
    color: rgba(105, 106, 114, 1);
    margin-right: 50px;
  }
  &_container {
    display: flex;
    padding-right: inherit;
    flex-direction: column;
    align-items: flex-start;
    &_value {
      color: rgba(0, 211, 200, 1);
      @include font-size(14, $bold, 18);
    }
    &_name {
      color: $violet;
      @include font-size(10, $medium, 12);
    }
    &_first {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgba(233, 233, 233, 1);
      padding-right: 26px;
      &_value {
        color: rgba(132, 146, 162, 1);
        @include font-size(14, $bold, 18);
      }
      &_name {
        color: $violet;
        @include font-size(10, $medium, 12);
      }
    }
    &_second {
      display: flex;
      margin-left: 16px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: inherit;

      &_value {
        color: rgba(0, 185, 255, 1);
        @include font-size(14, $medium, 20);
      }
      &_name {
        color: $violet;
        @include font-size(10, $demibold, 12);
      }
    }
  }
  @media (max-width: 450px) {
    top: 150px;
    width: 100%;
    left: 0;
    padding: 6px 11px;
    &_month {
      margin: 0;
    }
    &_container {
      &_first {
        margin: auto;
        padding-right: 13px;
      }
      &_second {
        margin: 0;
      }
    }
  }
}

.d3_tooltip_mobile {
  transform: translate(200, 200);
  position: absolute;
  background-color: $hover_chart_background;
  padding: 15px;
  width: 100%;
  top: 70%;
  left: 0;
  bottom: -80px;
  height: auto;
  display: flex;
  flex-direction: column;
  &_modal_close {
    align-self: flex-end;
    margin-bottom: 14px;
  }
  &_date {
    color: #696a72;
    @include font-size(12, $medium, 20);
    margin-bottom: 20px;
  }
  &_title {
    color: white;
    @include font-size(12, $medium, 20);
  }
  &_result_paid {
    display: flex;
    justify-content: space-between;
    color: white;
    @include font-size(12, $bold, 20);
  }
  &_total {
    align-self: flex-end;
    @include font-size(12, 600, 20);
  }
}

.layer {
  background-color: red;
}
