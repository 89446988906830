@import "styles/vars";
@import "styles/mixins";

.contacts {
  margin-bottom: 47px;
  .contact:last-child {
    align-items: flex-end;
    text-align: end;
  }

  &_wrapper {
    @include flex-justify-align(space-between, flex-start);
  }
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &_title {
    @include font-size(8, $medium, 10);
    letter-spacing: 0.08em;
    color: $font-color-gray-third;
  }

  &_address {
    @include font-size(10, $regular, 14);
    color: $font-color-black-third;
  }
}
