@import "styles/vars";
@import "styles/mixins";

.directoryDetails {
  height: 100%;
  margin-bottom: 20px;
  overflow: scroll;

  @media (max-width: 1024px) {
    overflow: hidden;
    margin-bottom: 20px;
  }
}

.overview {
  box-shadow: 0px 4px 16px $shadow;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 20px;
}

.chart_wrapper {
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, minmax(168px, 1fr));
  grid-template-columns: 432px 42px repeat(2, minmax(180px, 1fr));
  gap: 12px;

  height: 100%;
  margin-left: 102px;

  @media (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 100%;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  @media (max-width: 450px) {
    grid-template-rows: 348px repeat(auto-fill, minmax(144px, 1fr));
    grid-template-columns: 100%;
  }
}

.card {
  &_highest_month {
    grid-row: 1;
    grid-column: 3;
    height: 168px;

    h2 {
      @include font-size(12, $medium, 15, 1);
    }
  }

  &_lowest_month {
    grid-row: 1;
    grid-column: 4;
    height: 168px;

    h2 {
      @include font-size(12, $medium, 15, 1);
    }
  }

  &_before_thirty_days {
    grid-row: 2;
    grid-column: 3;
    height: 168px;

    h2 {
      @include font-size(12, $medium, 15, 1);
    }

    h3 {
      font-weight: 300;
    }
  }

  &_after_thirty_days {
    grid-row: 2;
    grid-column: 4;
    height: 168px;

    h2 {
      @include font-size(12, $medium, 15, 1);
    }

    h3 {
      font-weight: 300;
    }
  }

  @media (max-width: 450px) {
    &_highest_month {
      @include font-size(12, $medium, 15, 1);
      grid-row: 2;
      grid-column: 1;
      height: 168px;
    }

    &_lowest_month {
      @include font-size(12, $medium, 15, 1);
      grid-row: 3;
      grid-column: 1;
      height: 168px;
    }

    &_before_thirty_days {
      @include font-size(12, $medium, 15, 1);
      grid-row: 4;
      grid-column: 1;
      height: 168px;
      h6 {
        display: none;
      }
    }

    &_after_thirty_days {
      @include font-size(12, $medium, 15, 1);
      grid-row: 5;
      grid-column: 1;
      height: 168px;
      h6 {
        display: none;
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  div {
    width: 175px;
    height: 175px;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}
