@import "styles/vars";
@import "styles/mixins";

.header {
  @include flex-justify-align(space-between, center);
  position: fixed;
  border-bottom: 1px solid rgba(242, 242, 242, 1);
  background-color: $white;
  padding: 30px 5%;
  width: 100%;
  z-index: 10;
  &_title {
    @include font-size(23, $medium, 30);
    color: $font-color-black-secondary;
  }
  @media (max-width: 1024px) {
    padding: 30px 50px 30px 20px;
  }
  @media (max-width: 450px) {
    border: none;
    padding: 27px 20px 20px 32px;
    align-items: center;
    z-index: 3;
    &_title {
      @include font-size(23, $bold, 29);
    }
  }
}

.close {
  cursor: pointer;

  transition: all 0.5s;

  &:hover {
    opacity: 0.5;
  }
}
