@keyframes extend {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

@keyframes logo-loading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-out {
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 350px;
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show-hide {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  25% {
    opacity: 1;
    visibility: visible;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
