@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.subtitle {
  padding: 0 20px 20px;

  @include font-size(14, $regular, 18);
  color: $grey;

  span {
    @include font-size(14, $medium, 18);
    color: $font-color-black-secondary;
  }
}

.pay_later_options_list {
  margin: 0 20px 20px;
  @include flex-justify-align(flex-start);
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 450px) {
    margin: 0 15px 20px;
    @include flex-justify-align(center);
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    width: max-content;
    text-align: center;
  }
}

.pay_later_options_title {
  padding: 0 20px 9px;
  @include font-size(16, $bold, 20);
  color: rgba($font-color-black-secondary, 0.65);
}

.pay_later_option {
  @include flex-justify-align(space-between);
  flex-direction: column;
  text-align: center;
  img {
    margin-bottom: 10px;
  }

  @include font-size(12, $regular, 18);
  color: $font-color-black-secondary;
}

.pay_later_option_pay_later {
  @include flex-justify-align(space-between);
  flex-direction: column;
  gap: 10px;

  &_title {
    @include font-size(16, $bold, 30);
    color: $font-color-black-secondary;
  }

  &_due {
    &_title {
      @include font-size(12, $regular, 18);
      color: rgba($font-color-black-secondary, 0.7);
    }

    &_value {
      text-align: center;
      @include font-size(12, $bold, 18);
      color: $font-color-black-secondary;
    }
  }
}

.description {
  margin: 0 20px 20px;

  @include font-size(14, $regular, 18);
  color: rgba(25, 28, 35, 0.65);
}

.payment_later_options_modal {
  width: 720px;
  height: 100%;

  min-height: 842px;
  max-height: 95%;
  max-width: 95%;
  overflow-y: scroll;

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: auto;
    max-width: 100%;
    overflow-x: hidden;
  }
}
