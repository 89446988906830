@import "styles/vars";
@import "styles/mixins";

.items {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 300px);
  @media (max-width: 768px) {
    height: auto;
  }
}
