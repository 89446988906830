@import "styles/vars";
@import "styles/mixins";

.table_header {
  @include reset-button;
  display: flex;
  @include font-size(16, $medium, 21);
  width: 100%;
  justify-content: center;
  color: $font-color-black-secondary;
  transition: all 1s ease-in-out;
  &:disabled {
    opacity: 0.5;
  }
  &_name {
    margin-right: 5px;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
  }
  svg {
    transition: all 0.5s ease-in-out;
  }
  @media (max-width: 772px) {
    @include font-size(14, $medium, 18);
    &_name {
      padding-left: 0px;
      margin-right: 5px;
    }
    svg {
      width: 14px;
      height: 14px;
      transition: all 0.5s ease-in-out;
    }
  }
}

.table_header_two {
  @extend .table_header;
  &_name {
    @extend .table_header_name;
    overflow: visible;
    white-space: initial;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
}
