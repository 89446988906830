@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.msg_fail {
  background-color: rgba(246, 75, 60, 1);
  display: flex;
  transition: all 0.3s ease-in-out;
  animation: fade-out 0.5s ease-out;
  flex-direction: column;
  justify-content: space-between;
  gap: 1px;
  padding: 30px;
  border-radius: 4px;
  width: 100%;
  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_text {
      @include font-size(34, $medium, 48);
      color: #fff;
      letter-spacing: -0.035em;
    }
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: white;
      }
    }
  }
  &_text {
    @include font-size(14, $regular, 18);
    letter-spacing: 0.02em;
    color: white;
  }
  @media (max-width: 450px) {
    padding: 14px 22px;
    width: 260px;
    gap: 12px;
    &_top {
      &_text {
        @include font-size(18, $bold, 20);
        color: #fff;
      }
      svg {
        width: 14px;
        height: 14px;
      }
    }
    &_text {
      @include font-size(14, $regular, 20);
    }
  }
}
