@import "styles/vars";
@import "styles/mixins";

$black: $font-main-color;
$grey: rgba(255, 255, 255, 0.5);
$red: rgba(232, 74, 95, 1);
$grey-secondary: rgba(25, 28, 35, 0.6);

.modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($font-color-black, 0.8);
  @extend .modal;
  display: block;

  &_wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;

    background: white;
    min-width: 700px;
    max-height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    @media (max-width: 850px) {
      min-width: 80%;
      max-height: 90%;
    }
    @media (max-width: 562px) {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
    }
  }
  &_close {
    position: absolute;
    cursor: pointer;
    top: -66px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(100% - 40px);
    &:hover {
      opacity: 0.5;
    }
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: rgba(32, 39, 43, 0.8);
      }
    }
    @media (max-width: 562px) {
      top: 20px;
      background-color: $purple;
      width: 20px;
      height: 20px;
      z-index: 9999;
      svg {
        width: 8px;
        height: 8px;
        path {
          fill: #fff;
        }
      }
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 40px 50px;
    padding-bottom: 0px;
  }
  @media (max-width: 772px) {
    padding: 20px 20px 40px 20px;
  }
  @media (max-width: 562px) {
    &_wrapper {
      border-radius: 0px;
    }
    &_content {
      padding: 0px;
      height: 100vh;
      margin: 82px 0px 150px;
    }
  }
}

.title {
  @include font-size(24, $bold, 40);
  color: $black;
  margin-bottom: 50px;
  @media (max-width: 562px) {
    position: fixed;
    padding: 20px;
    width: 100%;

    @include font-size(14, $bold, 21);
    color: $black;
    top: 0px;
    border-bottom: 1px solid $divider-color;
  }
}

.price_form {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0px;
  gap: 20px;

  &_item {
    &:nth-child(2) {
      opacity: 0.6;
      margin-bottom: 90px;
    }
    @include flex-justify-align(space-between, center);
    &_description {
      display: flex;
      flex-direction: column;
      &_label {
        @include font-size(14, $medium, 24);
        color: $font-color-black-secondary;
      }
      &_optional {
        @include font-size(12, $regular, 30);
        color: rgba($font-color-black-secondary, 0.6);
      }
    }
    &_input {
      @include font-size(16, $regular, 24);
      padding: 20px;
      color: $font-color-black-secondary;
      border: 1px solid rgba(221, 227, 232, 1);
      transition: all 0.3s ease-in-out;
      height: 60px;
      width: 160px;
      text-align: right;
      &::placeholder {
        color: rgba($font-color-black-secondary, 0.3);
      }
      &:focus {
        border: 1px solid $font-color-black-secondary;
      }
    }
  }
  @media (max-width: 562px) {
    padding: 20px;
    &_item {
      @include flex-justify-align(space-between, stretch);
      flex-direction: column;
      gap: 10px;
      &_input {
        width: 100%;
        text-align: left;
      }
    }
  }
}

.add_discount_footer {
  position: sticky;
  width: calc(100% + 100px);
  margin: 0;
  margin-left: -50px;
  padding: 0;
  @include flex-justify-align(space-between, center);
  background-color: rgba(239, 239, 239, 1);
  padding: 30px 50px;
  height: 100px;
  &_btn {
    width: 170px;
    height: 60px;
    background-color: $purple;
    border: none;
    @include font-size(16, $demibold, 20, 1.33);
    color: white;
    text-transform: uppercase;
    border-radius: 4px;
    &:disabled {
      background-color: rgba(151, 151, 151, 0.2);
    }
  }
  &_left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &_title {
      @include font-size(12, $regular, 14);
      color: $font-color-black-secondary;
    }
    &_price {
      @include font-size(24, $medium, 20);
      color: $font-color-black-secondary;
    }
  }
  @media (max-width: 562px) {
    bottom: 0px;
    height: 150px;
    position: absolute;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    margin-left: -20px;
    align-items: stretch;
    &_left {
      flex-direction: row;
      @include flex-justify-align(space-between, center);
      &_price {
        @include font-size(20, $medium, 20);
      }
    }
    &_btn {
      width: 100%;
      height: 60px;
    }
  }
}

.quote_total {
  display: flex;
  justify-content: space-between;
  &_title {
    @include font-size(14, $medium, 24);
    color: $font-main-color;
  }
  &_price {
    @include font-size(24, $medium, 24);
    color: $font-color-black-secondary;
  }
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(228, 234, 238, 1);
  @media (max-width: 562px) {
    padding: 0 0 30px;
    margin: 0 20px;
    flex-direction: column;
    gap: 10px;
  }
}
