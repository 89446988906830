@import "styles/vars";
@import "styles/mixins";

.card {
  padding: 19px;
  background-color: rgba(228, 234, 238, 1);
  border-radius: 4px;
  min-width: 350px;

  @include flex-justify-align(flex-start);
  gap: 20px;

  transition: 0.5s;

  &:hover {
    opacity: 0.5;
  }

  div {
    display: flex;
    flex-direction: column;

    p {
      @include font-size(12, $medium, 15);
      color: rgba(25, 28, 35, 0.65);

      &:first-child {
        @include font-size(14, $bold, 20);
        color: $purple;
      }
    }
  }

  @media (max-width: 450px) {
    min-width: 100%;

    svg {
      min-width: 42px;
      min-height: 42px;
    }
  }
}