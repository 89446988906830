@import "styles/vars";
@import "styles/mixins";

.react_tabs {
  padding: 20px;

  &__tab_list {
    display: flex;
    gap: 20px;

    padding: 0;

    flex-wrap: nowrap;
  }

  &__tab {
    cursor: pointer;
    position: relative;
    z-index: 1;
    list-style: none;
    padding-bottom: 10px;
    transition: border-bottom-color 0.3s ease;
    color: $font-color-black-secondary;
    white-space: nowrap;
    @include font-size(16, $medium, 20);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 23px;
      border-bottom: 1.5px solid transparent;
    }

    &__selected {
      color: $purple;
      &:before {
        border-bottom: 1.5px solid $purple;
      }
    }
  }

  &__tab_panel {
    p {
      @include font-size(14, $regular, 18, 3%);
      color: rgba(144, 149, 166, 1);

      padding-bottom: 20px;
    }
  }
}

.delivery_options {
  @include flex-justify-align(flex-start, center);
  gap: 41px;

  @media (max-width: 450px) {
    @include flex-justify-align(flex-start, flex-start);
    flex-direction: column;
    gap: 20px;
  }
}

.delivery_option {
  @include flex-justify-align(center, center);
  gap: 10px;

  p {
    @include font-size(14, $regular, 20);
    color: $violet;

    padding-bottom: 0px;
  }
}
