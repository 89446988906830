@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.menu {
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 4px 2px 14px 20px rgba(0, 0, 0, 0.1);
  height: 100%;
  visibility: hidden;
  opacity: 0;
  left: -100%;
  overflow: hidden;

  top: 0px;
  bottom: 0;
  left: -100%;
  right: 0;
  width: 100%;
  transition: all 0.5s;

  z-index: 999;

  padding-top: 24px;

  &_active {
    top: 0px;
    height: 100%;

    visibility: visible;
    opacity: 1;
    left: 0;
    overflow-y: auto;

    box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.25);
  }

  header {
    padding: 0 10% 30px;
    @include flex-justify-align(space-between, flex-start);

    svg {
      @include black-close-icon;
    }
  }

  &_content {
    height: 100%;
    padding: 0 20px;
  }

  &_bottom {
    position: fixed;
    bottom: 0;

    @include flex-justify-align(space-between, flex-start);

    padding: 11px 10% 13px;

    width: 100%;

    background-color: rgba(242, 245, 247);

    p {
      @include font-size(12, $medium, 20);
      color: rgba(0, 0, 0, 0.5);
    }

    @media (max-width: 450px) {
      position: initial;
    }
  }

  @media (max-width: 450px) {
    padding: 0;

    header {
      padding: 24px 21px 34px;
    }
  }
}

.main {
  padding: 0 10%;
}