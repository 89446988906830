@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.address {
  display: none;

  &_active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 83%;
    width: 100%;
    padding: 0 20px;
    gap: 30px;
  }

  @media (max-width: 450px) {
    &_active {
      margin-top: 20px;
      gap: 20px;
    }
  }
}

.subtitle {
  @include font-size(18, $bold, 23);
  color: rgba($font-color-black-secondary, 0.65);
  margin-bottom: 20px;

  @media (max-width: 450px) {
    @include font-size(16, $regular, 20);
  }
}

.address_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

.address_inputs_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .name_input {
    @include input();
    max-width: 100%;
    width: 100%;
    height: 60px;
    text-align: left;
    padding: 20px;
    outline: none;

    @include font-size(16, $regular, 21);
    color: $font-color-black-secondary;

    &:nth-child(2) {
      margin-bottom: 10px;
    }

    &:focus {
      border-color: $purple;
    }

    &::placeholder {
      color: rgba($font-color-black-secondary, 0.4);
    }

    &_error {
      @extend .name_input;
      border: 1px solid #bf1650;
    }

    @media (max-width: 450px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 4px;
    }
  }
}

.form_name {
  &_label {
    @include font-size(14, $medium, 18);
    color: rgba($font-color-black-secondary, 0.4);
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  &_inputs {
    @include flex-justify-align(space-between);
  }
}

.customer_info_main {
  padding: 20px;
  padding-bottom: 0px;

  .form_buttons {
    @include flex-justify-align(space-between);
    position: sticky;
    width: calc(100% + 40px);
    padding: 29px 20px;
    margin-left: -20px;
    left: 0;
    background-color: rgba(252, 252, 255, 1);

    &_submit {
      @include purple-btn();
      width: 152px;
      height: 45px;

      &:disabled {
        color: $white;
        background: linear-gradient(55.52deg, #7233cc -5.2%, #6b59ed 65.07%);
        opacity: 0.2;
      }
    }

    &_cancel {
      border: 1px solid $purple;

      @include font-size(16, $medium, 20);
      color: $purple;
      height: 45px;

      background-color: $white;
      border-radius: 4px;

      padding: 12px 49px;
    }

    @media (max-width: 768px) {
      @include flex-justify-align(center);
      &_submit {
        width: 260px;
      }

      &_cancel {
        display: none;
      }
    }
  }

  @media (max-width: 450px) {
    .form_name {
      margin-top: 20px;
      &_inputs {
        @include flex-justify-align(flex-start, flex-start);
        flex-direction: column;
        gap: 4px;

        input {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.error {
  margin-top: 5px;
  @include font-size(14, $thin, 18);
  color: #bf1650;

  &::before {
    color: #bf1650;
    display: inline;
    content: "⚠ ";
  }
}

.name_input {
  @include input();
  max-width: 280px;
  width: 280px;
  height: 60px;
  text-align: left;
  padding: 20px;
  outline: none;

  @include font-size(16, $regular, 21);
  color: $font-color-black-secondary;

  &_error {
    @extend .name_input;
    border: 1px solid #bf1650;
  }

  &:focus {
    border-color: $purple;
  }

  &::placeholder {
    color: rgba($font-color-black-secondary, 0.4);
  }

  @media (max-width: 450px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 4px;
  }
}

.address_input_wrapper {
  position: relative;
}

.address_city_postcode_wrapper {
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 20px;
  }
}

.form_buttons {
  @include flex-justify-align(flex-end);
  position: sticky;
  width: calc(100% + 40px);
  padding: 29px 20px;
  margin-left: -20px;
  left: 0;
  background-color: rgba(252, 252, 255, 1);

  &_submit {
    @include purple-btn();
    width: 152px;
    height: 45px;

    &:disabled {
      color: $white;
      background: linear-gradient(55.52deg, #7233cc -5.2%, #6b59ed 65.07%);
      opacity: 0.2;
    }
  }

  &_cancel {
    border: 1px solid $purple;

    @include font-size(16, $medium, 20);
    color: $purple;
    height: 45px;

    background-color: $white;
    border-radius: 4px;

    padding: 12px 49px;
  }

  @media (max-width: 768px) {
    @include flex-justify-align(center);
    &_submit {
      width: 260px;
    }

    &_cancel {
      display: none;
    }
  }
}
