@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  height: 100%;
  min-height: 84vh;
  display: flex;
  flex-direction: column;
  margin: 3% 22%;

  @media (max-width: 600px) {
    min-height: 100vh;
    margin: 0;
  }
}

.content {
  flex: 1 0 auto;
  margin: 20px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  gap: 15px;

  @media (max-width: 768px) {
    margin: 20px 15px 0 20px;
  }
}

.footer {
  flex-shrink: 0;
}

.header {
  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    flex-direction: row-reverse;
    @include flex-justify-align(space-between);
  }
}

.go_to_account {
  @include reset-button();
  @include flex-justify-align(flex-end);
  justify-self: flex-end;
  gap: 8px;

  @include font-size(14, $medium, 20);
  color: $purple;
}

.title {
  @include flex-justify-align(space-between);

  &_name {
    @include font-size(20, $bold, 36);
    color: rgba(0, 0, 0, 1);
  }

  &_order {
    @include font-size(20, $regular, 36);
    color: rgba(136, 136, 136, 1);
  }
}

.footer {
  @include flex-justify-align(space-between);
  padding: 20px;

  background-color: $font-color-black-secondary;

  &_title {
    @include font-size(10, $medium, 13);
    color: rgba(149, 149, 149, 1);
    text-transform: uppercase;
    margin-bottom: 11px;
  }

  &_address {
    p {
      @include font-size(10, $medium, 14);
      color: $white;

      &:first-child {
        margin-bottom: 3px;
      }
    }
  }

  &_right {
    padding-top: 24px;
    @include flex-justify-align(space-between, flex-start);
    flex-direction: column;

    p,
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include font-size(10, $medium, 14);
      color: $white;

      &:nth-child(2) {
        margin-bottom: 6px;
      }
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    @include flex-justify-align(space-between, flex-start);
    gap: 11px;

    &_right {
      @include flex-justify-align(space-between, flex-start);
      flex-direction: column;
      padding-top: 0;
    }
  }
}

.socials {
  display: flex;
  gap: 10px;
  a {
    width: 18px;
    height: 18px;
    svg {
      height: 18px;
    }
  }
}

.order_content {
  @include flex-justify-align(space-between, stretch);
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
}
