@import "styles/vars";
@import "styles/mixins";

.root {
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
}

.wrapper {
  position: relative;
  display: flex;
  width: calc(100% - 460px);
  flex-direction: column;
  padding-top: 100px;

  @media (max-width: 1024px) {
    width: calc(100% - 411px);
  }

  @media (max-width: 768px) {
    width: calc(100% - 292px);
  }

  @media (max-width: 450px) {
    padding: 120px 10px 0px;
    width: 100%;
  }
}

.overview {
  box-shadow: 0px 4px 16px $shadow;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 20px;

  @media (max-width: 450px) {
    box-shadow: none;
    border-radius: none;
    margin-bottom: 0px;
  }
}

.info_wrapper {
  @media (max-width: 450px) {
    box-shadow: 0px 4px 16px $shadow;
    border-radius: 4px 4px 0px 0px;
  }
}
