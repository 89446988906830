@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.organization {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
  padding-right: 30px;
  cursor: pointer;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: calc(100% + 30px);
  margin-left: -20px;

  &_header {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
  }

  &_avatar {
    @include flex-justify-align(center, center);
    width: 2.5em;
    height: 2.5em;
    margin-right: 0.5rem;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    padding: 10px;
    background-color: transparent;
    color: #fff;
    @include font-size(14, $bold, 18);
    letter-spacing: -0.5px;
    color: rgba(255, 255, 255, 0.7);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_name {
    @include font-size(14, $medium, 34);
    color: #fff;
    margin-top: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 772px) {
    width: 100%;
    padding: 19px 20px;
    transition: transform 0.5s ease-out;
    margin-left: 0px;
    border-bottom: 1px solid rgba(155, 164, 176, 0.3);
    &_avatar {
      display: none;
    }
    &_name {
      @include font-size(14, $medium, 18);
      margin: 0;
      white-space: normal;
      text-overflow: clip;
      overflow: auto;
      word-wrap: break-word;
    }
  }
}

.organization_open {
  @extend .organization;
  transform: translateX(-70vw);
  transition: transform 0.5s ease-out;
}

.dropdown {
  width: 90%;
  position: absolute;
  top: calc(0px + 65px);
  transition: all 0.3s ease-in-out;
  animation: fade-out 0.3s linear;
  z-index: 6;
  border: 1px solid #7233cc;
  background-color: #fff;
  box-shadow: 0px 1px 2px #000000;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  padding: 20px 10px;

  &_title {
    color: rgba(25, 28, 35, 0.5);
    @include font-size(12, $medium, 20);
    margin-bottom: 1rem;
    @media (max-width: 1024px) {
      text-transform: uppercase;
    }
  }

  &_subtitle {
    color: $color-grey-secondary;
    @include font-size(12, $demibold, 20);
    text-transform: uppercase;
    margin-bottom: 1.25rem;
    @media (max-width: 1024px) {
      margin-top: 1.25rem;
      @include font-size(10, $demibold, 20);
    }
  }

  &_item {
    display: flex;
    align-items: center;
    color: rgba(140, 136, 150, 1);
    @include font-size(12, $regular, 40);
    transition: all 0.5s ease-out;
    margin-bottom: 20px;

    &_active {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      font-size: small;
      font-weight: $demibold;
      color: #7233cc;
    }

    &_avatar {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      transition: all 0.5s ease-out;
      align-items: center;
      @include font-size(18, $medium, 20);
      border-radius: 2px;
      margin-right: 1.25rem;
      background-color: #fff;
      border: 1px solid rgba(140, 136, 150, 1);
      color: rgba(140, 136, 150, 1);
      &_active {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-size(18, $medium, 20);
        border-radius: 2px;
        margin-right: 1.25rem;
        background-color: #fff;
        border: 1px solid $font-color-purple;
        color: $font-color-purple;
      }
    }

    &:hover {
      .dropdown_item_avatar {
        border: 1px solid $font-color-purple;
        color: $font-color-purple;
      }
      color: $font-color-purple;
    }
  }
  @media (max-width: 1024px) {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0px;
    justify-content: flex-start;
    padding: 0;
    border-radius: 0px;
    border: none;
    &_mobile_header {
      height: 61px;
      display: flex;
      background-color: #411d75;
      align-items: center;
      padding-left: 23px;
      svg {
        fill: #fff;
        width: 14px;
        height: 14px;
      }
    }
    &_mobile_content {
      padding: 20px;
    }
  }
}

.root_icon {
  transition: 0.3s ease-in-out;
  transform: rotate(90deg);
}

.root_icon_open {
  @extend .root_icon;
  transform: rotate(180deg);
}

.title_button {
  color: $font-color-black-secondary;
  @include font-size(12, $medium, 20);
  margin-bottom: 10px;
}

.dropdown_header {
  background-color: $font-color-purple;
}
