.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;

  &::after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #7233cc;
    border-color: #7233cc transparent #7233cc transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

.lds-dual-ring-small {
  animation: lds-dual-ring 1.5s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container_two {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    position: relative;
    svg:first-child {
      position: absolute;
      width: 197px;
      height: 186px;
      top: -4%;
    }
  }

  animation: lds-dual-ring 1.5s linear infinite;
}
