@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.status {
  @include flex-justify-align(space-between, flex-start);
  margin-bottom: 30px;

  &_name {
    @include font-size(12, $medium, 15);
    letter-spacing: -0.02em;
    color: rgba(164, 175, 208, 1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 20px;
    background-color: rgba(244, 247, 254, 1);
    border-radius: 4px;

    &_unpaid {
      @extend .status_name;
      color: rgba(248, 0, 49, 1);
      background-color: rgba(253, 209, 218, 1);
    }

    &_pending {
      @extend .status_name;
      color: rgba(136, 136, 136, 1);
      background-color: rgba(245, 245, 245, 1);
    }
  }

  .dates {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 6px;

    &_date {
      @include font-size(12, $medium, 15);
      display: flex;
      color: rgba(0, 0, 0, 1);
    }

    &_due_date {
      &_title {
        @include font-size(12, $medium, 15);
        color: rgba(0, 0, 0, 1);
      }

      &_value {
        @include font-size(12, $regular, 15);
        color: rgba(0, 0, 0, 1);
      }
    }

    &_btn {
      @include reset-button();
      @include font-size(12, $regular, 12);
      cursor: pointer;
      color: $purple;
      text-decoration: underline;
      margin-left: 2px;
    }
  }

  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
}
