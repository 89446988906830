@import "styles/vars";
@import "styles/mixins";

.items {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  &_cancelled {
    opacity: 0.5;
  }

  &_headers {
    display: grid;
    grid-template-columns: 40% 10% 16% 1fr;
    gap: 21px;

    padding-bottom: 18px;
    margin-bottom: 25px;
    border-bottom: 3px solid rgba(115, 115, 115, 1);

    p {
      @include flex-justify-align(center);
      @include font-size(14, $medium, 20);
      letter-spacing: 0.03em;
      color: rgba(21, 21, 21, 1);

      &:first-child {
        @include flex-justify-align(flex-start);
      }

      &:nth-child(3) {
        @include flex-justify-align(flex-start);
      }

      &:last-child {
        @include flex-justify-align(flex-end);
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;

    &_title {
      @include font-size(14, $medium, 20);
      letter-spacing: 0.03em;
      color: rgba(21, 21, 21, 1);
    }
  }
}
