@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.header {
  width: 100%;
  background-color: rgba(252, 252, 255, 1);
  padding: 20px;
  display: flex;
  flex-direction: column;

  &_bottom {
    @include flex-justify-align(space-between);
    width: 100%;

    &_image {
      width: 140px;
      height: 140px;

      picture {
        width: 140px;
        height: 100px;
      }
    }

    &_button {
      @include purple-btn;
      @include font-size(16, $medium, 20);
      height: 45px;
      width: 152px;

      &:disabled {
        background: $background-button;
        color: $white;
        opacity: 0.2;
      }
    }
  }

  .close_icon {
    background-color: $font-color-black-secondary;
    border-radius: 50%;
    width: 20px;
    height: 20px;

    @include flex-justify-align(center);
    align-self: flex-end;

    @media (max-width: 450px) {
      width: 24px;
      height: 24px;
      padding: 6px;
      padding-left: 7px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  @media (max-width: 450px) {
    padding: 20px 20px 10px;

    &_bottom {
      justify-content: center;
      &_button {
        display: none;
      }
    }
  }
}

.product_avatar_wrapper {
  @include flex-justify-align(center);
  width: 100%;
}
