@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.item_transaction {
  @include flex-justify-align(flex-start, center);
  @include font-size(16, $medium, 20);
  border-bottom: 0.5px solid #e1e1e1;
  padding: 20px 13px;
  gap: 14px;
  a {
    color: $font-color-black-secondary;

    &:hover {
      color: $purple;
    }
  }
  overflow-y: hidden;
}

.transaction_icon {
  fill: $purple;
}

.catalog_icon {
  color: $purple;
}
