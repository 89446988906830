@import "styles/vars";
@import "styles/mixins";

$paid_color: #34b53a;
$unpaid_color: #e84a5f;
.content_container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0px;
  width: 98%;
  gap: 20px;
}

.chart_container {
  grid-column: 1;
  grid-row: 1;
}

.monthly_average {
  grid-column: 1;
  grid-row: 2;
}

.high {
  grid-column: 1;
  grid-row: 3;
}

.low {
  grid-column: 1;
  grid-row: 4;
}

.total {
  grid-column: 1;
  grid-row: 5;
}

.paid {
  grid-column: 1;
  grid-row: 6;
}

.unpaid {
  grid-column: 1;
  grid-row: 7;
}

.points {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  &_point {
    margin-right: 20px;
    display: flex;
    align-items: center;
    p {
      margin-left: 6px;
      color: #7d86a9;
      @include font-size(12, 400, 20);
    }
    &_dot_paid {
      width: 14px;
      height: 14px;
      background-color: $paid_color;
      border-radius: 50%;
    }

    &_dot_unpaid {
      width: 14px;
      height: 14px;
      background-color: $unpaid_color;
      border-radius: 50%;
    }
  }
}

.nodata {
  position: absolute;
  width: 196px;
  height: 120px;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p:first-child {
    @include font-size(14, $demibold, 20);
    color: rgba(0, 0, 0, 0.5);
  }
  button {
    color: rgb(114, 51, 204);
    text-decoration: underline;
  }
  p:last-child {
    @include font-size(12, $medium, 20);
    color: rgba(203, 198, 215, 1);
  }
}

.lines {
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  div:nth-child(1) {
    height: 30%;
  }
  div:nth-child(2) {
    height: 80%;
  }
  div:nth-child(3) {
    margin-right: 0px;
  }
}

.line {
  width: 9.5px;
  background-color: rgba(203, 198, 215, 1);
  height: 100%;
  border-radius: 4px;
  margin-right: 12px;
}

.chart {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 320px;
  background-color: #fff;
  border-radius: 4px;
  padding: 21px 27px 36px 33px;
  box-shadow: 0px 4px 16px 0px rgba(204, 188, 252, 0.15);
  &_years {
    @include font-size(12, $medium, 15, 1);
    color: rgba($violet, 0.5);
  }
  &_titles {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  &_title {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 7px;
    border-bottom: 1px solid #eeeafc;
  }
  &_text {
    @include font-size(24, $thin, 33);
    color: $violet;
  }
}

.right_side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  .average {
    margin-bottom: 20px;
  }
}

.hight_low {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    margin: 0 20px;
  }
}

.income_statement_wrapper {
  margin-left: 20px;
  margin-right: 20px;
  &_header {
    display: block;
    text-align: center;
    justify-content: space-between;
    &_title {
      @include font-size(12, $regular, 20);
      color: rgb(32, 39, 43);
    }
  }
  &_subtitle {
    color: rgb(32, 39, 43);
    text-align: center;
    @include font-size(12, $demibold, 18);
  }
  &_content_mobile {
    display: flex;
    flex-direction: column;
    &_title {
      @include font-size(10, $regular, 20);
      color: rgb(32, 39, 43);
    }
    &_subtitle {
      @include font-size(11, $demibold, 20);
    }
  }
}

.box {
  border: 0.4px solid #7d86a9;
  border-radius: 2px;
  padding: 20px 17px;
  margin-bottom: 20px;
  max-height: 1000px;
  transition: max-height 0.8s ease;
  &_closed {
    @extend .box;
    max-height: 69px;
    overflow: hidden;
  }
  &_filled {
    @extend .box;
    background-color: #7233cc;
    border: 0.4px solid #7233cc;
    padding: 20px 15px;
  }
  &_main_content {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &_no_expand {
      display: flex;
      margin-bottom: 0;
      padding-right: 30px;
    }
    &_less {
      color: #7d86a9;
      @include font-size(12, 400, 24);
    }
    &_label {
      flex: 1;
      color: #7233cc;
      @include font-size(12, $medium, 20);
      @media (max-width: 772px) {
        @include font-size(12, $medium, 20);
      }
      &_secondary {
        flex: 1;
        color: #7d86a9;
        @include font-size(12, $regular, 20);
        @media (max-width: 772px) {
          @include font-size(14, 500, 24);
        }
      }
      &_white {
        flex: 1;
        color: #fff;
        @include font-size(12, $medium, 20);
        @media (max-width: 772px) {
          @include font-size(14, 500, 24);
        }
      }
    }
    &_value {
      color: #7233cc;
      @include font-size(12, $demibold, 20);
      @media (max-width: 1024px) {
        @include font-size(12, $demibold, 20);
      }
      &_secondary {
        color: #7d86a9;
        @include font-size(12, $demibold, 17);
        @media (max-width: 1024px) {
          @include font-size(12, $regular, 17);
        }
      }
      &_white {
        color: #fff;
        @include font-size(12, 600, 27);
        @media (max-width: 1024px) {
          @include font-size(12, 500, 24);
        }
      }
    }
    &_arrow_icon {
      margin-left: 13px;
      width: 10px;
    }
  }

  &_more_content {
    &_row {
      margin-bottom: 20px;
      padding: 0 25px 0 0;
      @include font-size(12, $demibold, 20);
      color: #7d86a9;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1024px) {
        @include font-size(12, 400, 21);
      }
    }
  }
}

.chart_text {
  display: flex;
  justify-content: space-between;
}
