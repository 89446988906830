@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.error {
  position: absolute;
  top: 100%;
  padding: 21px;
  z-index: 1;
  opacity: 0;
  pointer-events: none;

  background-color: rgba(246, 75, 60, 1);

  @include font-size(14, $medium, 18);
  color: $white;

  transition: all 0.5s;

  &_active {
    opacity: 1;
  }

  &_triangle {
    position: absolute;
    top: -30px;
    @include triangle("up", 15px, rgba(246, 75, 60, 1));
  }

  &_up {
    @extend .error;
    top: -100%;

    .error_triangle {
      position: absolute;
      top: initial;
      bottom: -30px;
      @include triangle("down", 15px, rgba(246, 75, 60, 1));
    }
  }

  @media (max-width: 450px) {
    @include font-size(14, $regular, 18);

    &_up {
      top: 100%;

      .error_triangle {
        position: absolute;
        top: -30px;
        @include triangle("up", 15px, rgba(246, 75, 60, 1));
      }
    }
  }
}
