@import "styles/vars";
@import "styles/mixins";

.overview {
  display: flex;
  flex-direction: column;

  &_activity {
    box-shadow: 0px 4px 16px 0px rgba(204, 188, 252, 0.15);
    padding: 30px 5% 30px 10%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    div {
      display: flex;
      justify-content: space-between;
      p:first-child {
        color: $violet;
        @include font-size(16, $regular, 24);
      }
      p:last-child {
        color: $violet;
        @include font-size(16, $thin, 24);
      }
    }
    @media (max-width: 1024px) {
      margin-right: 46px;
      padding: 30px 5% 30px 25px;
    }
  }
  &_button {
    display: flex;
    justify-content: space-between;
    span {
      color: rgba($violet, 0.6);
      @include font-size(12, $regular, 20);
    }
    button {
      @include font-size(12, $demibold, 20);
    }
  }
  &_footer {
    @include flex-justify-align(space-between, stretch);
    flex-direction: column;
    gap: 20px;
    padding: 25px 5% 25px 10%;
    box-shadow: 0px 4px 16px 0px $shadow;
    margin-right: 5%;
    background-color: $background-color;
    &_buttons {
      @include flex-justify-align(space-between);
    }
    &_remaining_balance {
      @include flex-justify-align(space-between);
      gap: 10px;
      &_label {
        @include font-size(14, $regular, 20);
        color: $font-color-black-secondary;
      }
      &_value {
        @include font-size(24, $medium, 20);
        color: $font-color-black-secondary;
        &_paid {
          @extend .overview_footer_remaining_balance_value;
          color: rgba($violet, 0.5);
        }
      }
      &_button {
        border: none;
        border-radius: 10px;
        border: 1px solid $purple;
        width: 250px;
        height: 60px;
        text-transform: uppercase;
        background: transparent;
        color: $purple;
        @include font-size(18, $medium, 20);
        transition: all 0.5s ease-in-out;
        &:hover:not(:disabled) {
          opacity: 0.8;
        }
        &:disabled {
          background: rgba(216, 216, 216, 1);
          color: rgba($violet, 0.5);
        }
      }
    }
    &_record_payment_button {
      border: none;
      border-radius: 4px;
      border: 1px solid $purple;
      width: 250px;
      height: 60px;
      text-transform: uppercase;
      background: $background-button;
      color: #fff;
      @include font-size(18, $medium, 20);
      transition: all 0.5s ease-in-out;
      &:hover:not(:disabled) {
        opacity: 0.8;
      }
      &:disabled {
        border: none;
        background: rgba(216, 216, 216, 1);
        color: rgba($violet, 0.5);
      }
    }
    &_paid {
      @extend .overview_footer;
      flex-direction: row;
      .overview_footer_remaining_balance {
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        &_button {
          display: none;
        }
        @media (max-width: 450px) {
          flex-direction: row;
          @include flex-justify-align(space-between);
        }
      }
    }
    &_pending {
      @extend .overview_footer;
      .overview_footer {
        &_remaining_balance {
          &_button {
            @include flex-justify-align(center);
          }
        }
        &_record_payment_button {
          display: none;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    &_footer {
      margin-right: 46px;
      padding: 25px 5% 25px 25px;
    }
  }
  @media (max-width: 768px) {
    &_footer {
      margin-right: 12px;
      padding: 20px 25px;
      &_remaining_balance {
        &_button {
          width: 160px;
          @include font-size(16, $medium, 20);
        }
      }
      &_record_payment_button {
        @include font-size(16, $medium, 20);
        width: 160px;
      }
    }
  }
  @media (max-width: 450px) {
    padding: 0px;
    &_activity {
      padding: 31px 22px 36px;
      margin: 0px;
      div {
        flex-direction: column;
        p:first-child {
          color: rgba($violet, 0.5);
          @include font-size(12, $regular, 18);
        }
        p:last-child {
          color: $violet;
          @include font-size(16, $thin, 24);
        }
      }
    }
    &_footer {
      padding: 26px 16px;
      margin: 0px;
      flex-direction: column;
      align-items: stretch;
      gap: 15px;
      &_buttons {
        flex-direction: column;
        gap: 10px;
      }
      &_record_payment_button {
        width: 100%;
      }
      &_remaining_balance {
        justify-content: space-between;
        &_value {
          @include font-size(18, $medium, 20);
        }
        &_button {
          width: 100%;
        }
      }
    }
  }
}

.na {
  color: $violet;
  @include font-size(16, $regular, 24);
}

.payment_schedule {
  @include reset-button;
  color: $purple;
  @include font-size(16, $medium, 24);
  @media (max-width: 450px) {
    width: max-content;
  }
}
