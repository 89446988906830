@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.title {
  padding: 0 20px 10px;

  @include font-size(38, $bold, 49);
  color: $font-color-black-secondary;

  @media (max-width: 450px) {
    padding: 0 20px;

    @include font-size(20, $bold, 42);
    color: rgba($font-color-black-secondary, 0.65);
  }
}
