@import "styles/vars";
@import "styles/mixins";

.notification {
  padding: 40px 60px;
  @include flex-justify-align(center, center);
  overflow: auto;
  flex-direction: column;
  h1 {
    margin-top: 30px;
    display: flex;
    align-items: center;
    color: $violet;
    @include font-size(24, $regular, 40, 1);
  }
  button {
    @include font-size(16, $demibold, 20, 1.33);
    @include flex-justify-align(center, center);
    background: $background-button;
    color: white;
    border: none;

    border-radius: 4px;
    text-transform: uppercase;
    transition: all 0.5s;
    width: 250px;
    height: 60px;
    outline: none;

    &:disabled {
      outline: none;
      background: rgba($purple, 0.2);
    }

    &:hover:not(:disabled) {
      outline: none;
      opacity: 0.7;
    }
  }
  @media (max-width: 450px) {
    padding: 20px;
    h1 {
      margin-top: 0px;
      font-size: 16px !important;
      font-weight: $medium !important;
      margin-bottom: 11px;
    }
    button {
      width: 162px;
    }
    p {
      padding: 0 20px 21px;
      @include font-size(16, $regular, 20);
    }
  }
}
