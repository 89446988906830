@import "styles/vars";
@import "styles/mixins";

.chart {
  grid-column: 1;
  grid-row: 1/3;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 34px 20px 8px;

  background-color: $white;
  box-shadow: 0px 4px 16px $shadow;

  &_years {
    text-transform: uppercase;

    @include font-size(12, $medium, 15, 1);
    color: rgba($violet, 0.5);
  }

  &_text {
    padding: 10px 0px;

    color: $violet;
    @include font-size(24, $thin, 30);

    &_nodata {
      @include font-size(24, $thin, 40);
      color: rgba($violet, 1);
    }
  }

  @media (max-width: 450px) {
    grid-row: 1;
    &_text {
      margin-bottom: 0;
    }

    &_years {
      @include font-size(12, $medium, 15, 1);
    }

    &_title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 7px;
      border-bottom: 1px solid rgba(238, 234, 252, 1);
    }
  }
}

.tooltip {
  position: absolute;
  @include flex-justify-align(space-between, center);
  width: 200px;
  top: -135px;
  left: calc(100% - 210px);
  padding: 10px 20px 8px 17px;

  border: 1px solid rgba(255, 207, 38, 1);
  border-radius: 4px;

  &_month {
    display: flex;
    align-items: center;
    @include font-size(14, $regular, 32);
    color: rgba(105, 106, 114, 1);
  }
  &_container {
    display: flex;
    padding-right: inherit;
    flex-direction: column;
    align-items: flex-start;

    &_value {
      color: rgba(0, 211, 200, 1);
      @include font-size(14, $bold, 18);
    }

    &_name {
      color: rgba($violet, 1);
      @include font-size(10, $medium, 12);
    }
  }

  @media (max-width: 1024px) {
    right: 0;
    left: 67%;
  }

  @media (max-width: 768px) {
    right: 0;
    left: 57%;
  }

  @media (max-width: 450px) {
    top: 176px;
    width: 100%;
    left: 0;
  }
}

.nodata {
  position: absolute;
  width: 196px;
  height: 120px;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p:first-child {
    @include font-size(14, $demibold, 20);
    color: rgba(0, 0, 0, 0.5);
  }
  button {
    color: rgb(114, 51, 204);
    text-decoration: underline;
  }
  p:last-child {
    @include font-size(12, $medium, 20);
    color: rgba(203, 198, 215, 1);
  }
}

.lines {
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  div:nth-child(1) {
    height: 30%;
  }
  div:nth-child(2) {
    height: 80%;
  }
  div:nth-child(3) {
    margin-right: 0px;
  }
}

.line {
  width: 9.5px;
  background-color: rgba(203, 198, 215, 1);
  height: 100%;
  border-radius: 4px;
  margin-right: 12px;
}
