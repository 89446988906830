@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.pay_later_invoice {
  margin: 0 20px;
}

.input {
  display: flex;
  flex-direction: column;
  width: 280px;
  margin: 0;
  margin: 20px 0px 10px;

  span {
    @include font-size(14, $medium, 18);
    color: rgba($font-color-black-secondary, 0.4);
    text-transform: uppercase;
  }

  input {
    padding: 20px;
    height: 60px;
    outline: none;

    @include font-size(16, $regular, 20);
    color: $font-color-black-secondary;

    border: 1px solid rgba(221, 227, 232, 1);
    border-radius: 3px;

    &:focus {
      border-color: $purple;
    }

    &::placeholder {
      color: rgba($font-color-black-secondary, 0.4);
    }
  }
}

.pay_later_invoice_title {
  @include font-size(14, $bold, 20, -0.41);
  color: rgba($font-color-black-secondary, 0.65);
  margin-bottom: 9px;
}

.pay_later_invoice_name {
  @include font-size(15, $medium, 20);
  color: rgba($font-color-black-secondary, 0.85);
}

.pay_later_invoice_name {
  @include font-size(15, $medium, 20);
  color: rgba($font-color-black-secondary, 0.85);
}

.pay_later_invoice_number,
.pay_later_invoice_address {
  @include font-size(14, $regular, 24);
  color: rgba($font-color-black-secondary, 0.65);
}
