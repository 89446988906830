@import "styles/vars";
@import "styles/mixins";

.card {
  box-shadow: 0px 4px 16px 0px rgba(204, 188, 252, 0.15);
  padding: 33px 20px;
  display: flex;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  flex-direction: column;
  // align-items: flex
  text-align: right;
  @media (max-width: 1388px) {
    padding: 13px 5px 21px 20px;
  }
  @media (max-width: 450px) {
    padding: 20px 14px;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_text {
    @include font-size(12, $demibold, 20);
    color: rgba($violet, 0.5);
    text-transform: uppercase;
    margin-bottom: 3px;
    text-align: left;
  }
  @media (max-width: 1388px) {
    &_text {
      @include font-size(12, $medium, 15, 1);
      margin-bottom: 5px;
    }
  }
}

.subtitle {
  color: $violet;
  text-align: left;
  @include font-size(14, $medium, 20);
  @media (max-width: 1388px) {
    @include font-size(14, $medium, 18);
    color: $violet;
    margin-bottom: 10px;
  }
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_nodata {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  @media (max-width: 1388px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 450px) {
    flex-direction: row;
    &_nodata {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.value {
  color: $purple;
  @include font-size(24, $bold, 40);
  margin-bottom: 0px;
  @media (max-width: 1388px) {
    @include font-size(16, $demibold, 21);
    margin-bottom: 10px;
    color: rgba(203, 198, 215, 0.5);
  }
}

.right_side {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  &_value {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
  }
  @media (max-width: 1388px) {
    &_value {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  @media (max-width: 450px) {
    &_value {
      align-items: flex-end;
    }
  }
}

.percents_container {
  &_growth {
    display: flex;
    align-items: center;
    div {
      background-color: #caf1c3;
      border-radius: 100%;
      height: 14px;
      width: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      svg {
        display: block;
        width: 10px;
        height: 10px;
        transform: rotate(180deg);
        path {
          fill: #51c03e;
          stroke: #51c03e;
        }
      }
    }
    .percents {
      @include font-size(12, $medium, 20);
      color: rgba(81, 192, 62, 1);
      margin-bottom: 0px;
      &_zero {
        @include font-size(12, $medium, 20);
        margin-bottom: 0px;
        color: rgb(203, 198, 215);
      }
      @media (max-width: 1388px) {
        @include font-size(12, $medium, 15);
        margin-top: 0px;
        &_zero {
          @include font-size(12, $medium, 15);
        }
      }
    }
  }
  &_decline {
    @extend .percents_container_growth;
    display: flex;
    align-items: center;
    div {
      background-color: rgba(247, 191, 188, 1);
      border-radius: 100%;
      height: 14px;
      width: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      svg {
        display: block;
        width: 10px;
        height: 10px;
        transform: rotate(0deg);
        path {
          fill: $red;
          stroke: $red;
        }
      }
    }
    .percents {
      @include font-size(12, $medium, 20);
      color: $red;
      margin-bottom: 0px;
      &_zero {
        @include font-size(12, $medium, 20);
        margin-bottom: 0px;
        color: rgb(203, 198, 215);
      }
      @media (max-width: 1388px) {
        margin-top: 4px;
      }
    }
  }
}

.vs_previous {
  @include font-size(12, $regular, 20);
  margin-bottom: 0px;
  color: rgba(139, 135, 149, 1);
  @media (max-width: 1388px) {
    @include font-size(12, $regular, 15);
    margin-bottom: 10px;
  }
  @media (max-width: 450px) {
    text-align: right;
  }
}

.ytd {
  @include font-size(12, $thin, 20, 1);
  color: rgba($violet, 0.5);
  margin-bottom: 0px;
  @media (max-width: 1388px) {
    @include font-size(12, $thin, 15, 1);
    color: rgba($violet, 0.5);
    margin-bottom: 0px;
    text-align: left;
  }
  @media (max-width: 450px) {
    text-align: right;
  }
}

.nodata {
  @include font-size(12, $medium, 20);
  color: rgb(203, 198, 215);
  @media (max-width: 1388px) {
    @include font-size(12, $medium, 15);
    margin-bottom: 24px;
    color: rgba(203, 198, 215, 0.5);
  }
}
.unit {
  font-weight: 300;
}
