@import "styles/vars";
@import "styles/mixins";

.content {
  @include flex-justify-align(space-between);
  flex-direction: column;
  height: 200px;
  gap: 20px;
  margin: 40px 76px 50px;
  &_title {
    color: $violet;
    @include font-size(24, $medium, 40);
  }
  &_description {
    color: $violet;
    @include font-size(16, $regular, 20);
    width: 408px;
    text-align: center;
  }
  &_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 48px;
    &_cancel {
      width: 100px;
      border: none;
      height: 100%;
      border-radius: 4px;
      background-color: rgba(239, 239, 243, 1);
      @include font-size(16, $regular, 24);
      color: rgba(139, 135, 149, 1);
      transition: all 0.5s ease-in-out;
      &:hover:not(:disabled) {
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.2;
      }
    }
    &_continue {
      width: 162px;
      background: $background-button;
      border-radius: 4px;
      border: none;
      height: 100%;
      @include font-size(16, $bold, 24);
      color: #fff;
      transition: all 0.5s ease-in-out;
      &:hover:not(:disabled) {
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.2;
      }
    }
  }
  @media (max-width: 450px) {
    margin: 20px;
    gap: 0px;
    justify-content: flex-start;
    &_title {
      @include font-size(14, $medium, 40);
    }
    &_description {
      @include font-size(12, $regular, 15);
      width: 240px;
      padding: 0 15px;
      &_continue {
        @include font-size(14, $bold, 15);
      }
    }
    &_buttons {
      margin-top: 40px;
      &_cancel {
        @include font-size(14, $medium, 18);
      }
      &_continue {
        width: 120px;
        @include font-size(14, $bold, 18);
      }
    }
  }
}
