@import "styles/vars";
@import "styles/mixins";

.about_us {
  &_title {
    padding: 0px 10% 22px;

    @include font-size(24, $bold, 42);
    color: rgba($font-color-black-secondary, 0.52);

    @media (max-width: 450px) {
      border-bottom: 1px solid rgba($light-blue, 0.65);
      padding: 0 0 13px;
      margin: 0 10% 10px;
    }
  }

  &_content {
    @include font-size(16, $regular, 20);
    color: rgba($font-color-black-secondary, 0.6);

    padding: 0 10%;
    white-space: pre-line;
  }
}
