@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.payments {
  padding: 25px 53px;

  box-shadow: 0px 4px 16px $shadow;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  &_title {
    @include font-size(18, $bold, 23);
    color: $font-color-black-secondary;

    padding-bottom: 15px;
  }

  svg {
    opacity: 0.6;
    text-align: right;
  }

  &_cards {
    @include flex-justify-align(space-between);

    padding-bottom: 10px;
    border-bottom: 1px solid $divider-color;

    p {
      @include font-size(16, $medium, 24);
      color: rgba($font-color-black-secondary, 0.3);
    }

    div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &_wallets {
    @include flex-justify-align(space-between);

    padding-bottom: 10px;
    border-bottom: 1px solid $divider-color;

    p {
      @include font-size(16, $medium, 24);
      color: rgba($font-color-black-secondary, 0.3);
    }

    div {
      display: flex;
      gap: 10px;
    }
  }

  &_bank_transfer {
    padding-bottom: 10px;

    border-bottom: 1px solid $divider-color;
    @include flex-justify-align(space-between);

    p {
      @include font-size(16, $medium, 24);
      color: rgba($font-color-black-secondary, 0.3);
    }

    div {
      @include font-size(16, $regular, 24);
      color: rgba($font-color-black-secondary, 0.8);
      display: flex;
      justify-content: flex-end;
      text-align: right;
      gap: 10px;
    }

    @media (max-width: 768px) {
      div {
        @include font-size(14, $regular, 24);
      }
    }
  }

  &_other {
    @include flex-justify-align(space-between);

    p {
      @include font-size(16, $medium, 24);
      color: rgba($font-color-black-secondary, 0.3);
    }

    div {
      p {
        @include font-size(14, $regular, 24);
        color: rgba($font-color-black-secondary, 0.6);
      }

      @include flex-justify-align(center);
      gap: 10px;
    }
  }

  @media (max-width: 768px) {
    padding: 25px 20px;
  }
}
