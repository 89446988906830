@import "styles/vars";
@import "styles/mixins";

.price {
  border-top: 1px solid $divider_color;
  padding: 20px;
  width: 100%;
  display: flex;
  position: sticky;
  bottom: 0;
  gap: 10px;
  justify-content: space-between;
  color: $grey;
  flex-direction: column;
  background-color: rgba(247, 248, 253, 1);

  div {
    @include flex-justify-align(space-between);
    color: rgba($font-color-black-secondary, 0.7);
    h3 {
      @include font-size(16, $regular, 24);
    }
    span {
      @include font-size(16, $regular, 24);
    }
    &:first-child {
      color: $font-color-black-secondary;
      h3 {
        @include font-size(16, $regular, 24);
      }
      span {
        @include font-size(16, $bold, 24);
        margin-left: 5px;
      }
    }
    &:last-child {
      border-top: 1px solid $divider_color;
      color: $purple;
      padding-top: 16px;
      h3 {
        @include font-size(16, $demibold, 24);
      }
      span {
        @include font-size(16, $bold, 24);
        margin-left: 5px;
      }
    }
  }

  @media (max-width: 1150px) {
    margin-left: 0px;
  }

  @media (max-width: 768px) {
    margin-left: 0px;
    padding: 20px 10px;
  }

  @media (max-width: 450px) {
    position: static;
    padding: 20px 22px;
    width: 100%;
  }
}

.price_discount_value {
  font-weight: 500 !important;
}

.taxes_included_label {
  font-weight: 300 !important;
}
