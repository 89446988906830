@import "styles/vars";
@import "styles/mixins";

.modal {
  width: 460px;
  min-width: 460px;

  @media (max-width: 450px) {
    min-width: calc(100% - 30px);
    width: calc(100% - 30px);
  }
}

.container {
  padding: 56px 45px 82px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    @include font-size(18, $medium, 23);
    color: $font-color-black-secondary;
    margin-bottom: 32px;
  }

  .description {
    @include font-size(14, $regular, 18);
    color: $violet;
    text-align: center;
    margin-bottom: 32px;
  }

  .container_buttons {
    display: flex;
    gap: 13px;

    .cancel_btn {
      @include white-btn();
      padding: 7px 12px 9px;
      width: auto;
      height: auto;
      border: 1px solid rgba(136, 136, 136, 1);
      color: rgba(136, 136, 136, 1);

      @include font-size(14, $medium, 18);
    }

    .accept_btn {
      @include purple-btn();
      padding: 7px 29px;
      width: auto;
      height: auto;

      @include font-size(14, $bold, 20);
    }
  }

  @media (max-width: 450px) {
    padding: 52px 35px 78px;

    .title {
      @include font-size(14, $medium, 18);
      margin-bottom: 10px;
    }

    .description {
      @include font-size(13, $regular, 16);
    }
  }
}
