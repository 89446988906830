@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.phone__input {
  display: flex;
  align-items: center;
  position: relative;

  border-radius: 4px;

  padding: 10px 20px;
  transition: all 0.3s;
  z-index: 99;

  &_dropdown_btn {
    @include reset-button;
    background-color: rgba(239, 239, 243);
    padding: 26px 32px;
    border-radius: 4px;
    width: 300px;
    display: flex;
    margin-right: 0;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    box-shadow: 2px 8px 20px rgba(203, 203, 203, 0.35);
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      label {
        @include font-size(16, $bold, 21);
        color: $purple;
        font-family: "Visuelt Pro";
        text-align: start;
        margin: 0;
      }
      span {
        @include font-size(24, $bold, 30);
        font-family: "Visuelt Pro";
        text-align: start;
        color: black;
      }
    }
  }
  &_dropdown_icon {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 0px 0px 13px 13px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &_input_container {
    position: relative;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  &_input {
    border: none;
    outline: none;
    width: 60%;
    background-color: rgba(239, 239, 243, 1);
    width: 860px;
    border-radius: 4px;
    padding: 50px 35px 19px;
    font-family: "Visuelt Pro";
    box-shadow: 2px 8px 20px rgba(203, 203, 203, 0.35);
    @include font-size(24, $regular, 31);
    color: $font-color-black-secondary;
    &:focus ~ .phone__input_floating_label,
    &:not(:focus):valid ~ .phone__input_floating_label {
      @include font-size(20, $thin, 26);
      top: 10px;
      bottom: 10px;
      left: 35px;
      opacity: 1;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 450px) {
      @include font-size(18, $regular, 23);
      padding: 28px 20px 9px;
      &:focus ~ .phone__input_floating_label,
      &:not(:focus):valid ~ .phone__input_floating_label {
        @include font-size(14, $thin, 18);
        top: 5px;
        left: 20px;
      }
    }
  }
  &_input:-webkit-autofill,
  &_input:-webkit-autofill:hover,
  &_input:-webkit-autofill:focus,
  &_input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: rgba(91, 107, 130, 1) !important;
  }

  &_floating_label {
    font-family: "Visuelt Pro";
    @include font-size(24, $thin, 31);
    position: absolute;
    pointer-events: none;
    left: 35px;
    top: 35px;
    color: rgba($font-color-black-secondary, 0.6);
    transition: 0.2s ease all;
    @media (max-width: 450px) {
      @include font-size(16, $thin, 21);
      top: 20px;
      left: 20px;
    }
  }

  &_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    max-height: 250px;
    padding: 20px 25px;
    gap: 18px;
    overflow-y: auto;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    left: 0;
    list-style: none;
    border-radius: 4px;
    background-color: white;
    border: none;

    @media (max-width: 480px) {
      width: 90%;
      left: 0px;
      margin: 0px;
      height: 90vh;
      margin: 20px;
      transform: translateY(103%);
      max-height: 50vh;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 30px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(226, 209, 249);
      border-radius: 5px;
      height: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(65, 29, 117, 0.5);
    }

    &_select {
      @include flex-justify-align(center, stretch);
      animation: fade-out 0.5s ease-out;
      transition: all 0.3s ease-out;
      flex-direction: column;
      position: absolute;
      border: none;
      padding: 0;
      width: 400px;
      top: 113px;
      left: 0px;
      box-shadow: 2px 8px 20px rgba(203, 203, 203, 0.35);
      border-radius: 4px;
      background-color: white;
      z-index: 1;
    }

    &_search {
      @include flex-justify-align(stretch, center);
      border-bottom: 1px solid rgba(228, 234, 238, 1);
      margin: 28px 20px 30px;
      gap: 12px;
      position: sticky;
      left: 0;
      top: 0;
      background: #fff;

      z-index: 2;

      label {
        margin: 0;
      }

      svg {
        width: 16px;
        height: 16px;
        margin-bottom: 17px;
        path {
          fill: rgb(203, 198, 215);
        }
      }

      input {
        border: none;
        @include font-size(20, $medium, 26);
        font-family: "Visuelt Pro";
        color: $purple;
        margin-bottom: 12px;
        background-color: #fff;
        &:focus {
          outline: none;
        }
        @media (max-width: 480px) {
          @include font-size(12, $medium, 18, -0.16875);
        }
      }
    }

    &_item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.3s;

      span {
        @include font-size(24, $regular, 31);
      }

      span:first-child {
        color: $black;
      }

      span:nth-child(2) {
        color: rgba($black, 0.6);
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  @media (max-width: 450px) {
    padding: 0;
    &_dropdown_btn {
      padding: 10px 20px;
      width: 100%;
      &_wrapper {
        width: 100%;
      }
      div {
        label {
          @include font-size(14, $medium, 18);
        }
        span {
          @include font-size(18, $medium, 23);
        }
      }
    }
    &_dropdown_icon {
      padding: 0px 0px 9px 9px;
    }
    &_list {
      &_mobile {
        background: url("../../../assets/images/login-mobile.png") no-repeat
          center center fixed;
        padding: 20px;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        position: fixed;
      }
      &_select {
        background-color: transparent;
        width: 98%;
        box-shadow: none;
      }
      &_search {
        background: transparent;
        margin: 20px;

        border-bottom: 1px solid rgba(228, 234, 238, 0.3);
        input {
          @include font-size(20, $medium, 25);
          background-color: transparent;
          color: white;
        }
      }
      label {
        svg {
          path: {
            fill: rgba(228, 234, 238, 0.3);
          }
        }
      }
      &_item {
        span {
          @include font-size(16, $regular, 20);
        }
      }
    }
  }
}
