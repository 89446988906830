@import "styles/vars";
@import "styles/mixins";
.react_tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  @media (max-width: 1388px) {
    overflow: hidden;
  }

  &__tab_list {
    margin: 0 0 10px;
    padding: 0;
    border-bottom: 1px solid rgb(242, 242, 242);
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 1388px) {
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }

  &__tab {
    z-index: 1;
    display: inline-block;
    border-bottom: 2px solid transparent;
    list-style: none;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
    margin-right: 3vw;
    transition: border-bottom-color 0.3s ease;
    color: rgb(25, 28, 35);
    white-space: nowrap;
    @include font-size(16, $medium, 20);

    &__selected {
      border-bottom-color: $purple;
      color: $purple;
    }
    @media (max-width: 450px) {
      padding-bottom: 20px;
      margin-right: 22px;
      &:first-child {
        margin-left: 16px;
      }
    }
  }

  &__tab_panel {
    display: none;
    &__selected {
      svg {
        overflow: visible;
      }
      max-width: 100%;
      display: block;
      background-color: transparent;
      border-radius: 2px;
      padding: 20px 0;
    }
    @media (max-width: 1388px) {
      &__selected {
        padding: 15px 0 10px;
        margin: 0 10%;
      }
    }
    @media (max-width: 768px) {
      &__selected {
        padding: 15px 0 0;
        margin: 0 3%;
      }
    }
    @media (max-width: 450px) {
      &__selected {
        padding: 15px 0 0;
        margin: 0 10px;
      }
    }
  }
}
