@import "styles/vars";
@import "styles/mixins";

.address {
  @include flex-justify-align(space-between);
  background-color: $divider-color;
  padding: 32px 21px;
  border-radius: 4px;

  p {
    @include font-size(14, $medium, 18, -0.41);
    color: rgba($font-color-black-secondary, 0.65);

    &:first-child {
      @include font-size(12, $medium, 22);
      color: rgba($font-color-black-secondary, 1);
    }
  }

  &_button {
    @include purple-btn();
    width: 74px;
    height: 34px;
    @include font-size(14, $medium, 20);
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}