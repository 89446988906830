@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  @media (max-width: 450px) {
    header {
      padding: 17px 15px 24px;
    }
  }
}

.main {
  padding: 0 10% 0;

  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1.6fr 1fr;
  grid-auto-rows: auto;

  .title {
    grid-column: 1;
    @include font-size(24, $bold, 42);
    color: rgba($font-color-black-secondary, 0.56);
  }

  .customer_info {
    grid-column: 1;
    grid-row: 2;
  }

  .item_list {
    grid-column: 1;
    grid-row: 4 / 8;
    height: 100%;
    overflow: scroll;
    max-height: 500px;
  }

  .shipping_info {
    grid-column: 1;
    grid-row: 3;
  }

  .summary {
    grid-column: 2;
    grid-row: 1 / 4;
  }

  .payments {
    grid-column: 2;
  }

  .link_card {
    grid-column: 2;
  }

  @media (max-width: 1024px) {
    padding: 0 68px 0;
    grid-template-columns: 1.7fr 1fr;
  }

  @media (max-width: 820px) {
    grid-template-columns: 390px 1fr;
  }

  @media (max-width: 450px) {
    grid-template-columns: 1fr;

    .shipping_info {
      grid-column: 1;
      grid-row: auto;
    }

    .item_list {
      grid-column: 1;
      grid-row: auto;
      max-height: max-content;
    }

    .summary {
      grid-column: 1;
      grid-row: auto;
    }

    .payments {
      grid-column: 1;
    }

    .link_card {
      grid-column: 1;
    }

    .title {
      @include font-size(20, $bold, 42);
    }
  }

  @media (max-width: 450px) {
    padding: 0 15px 5%;

    header {
      padding: 0 15px 5%;
    }
  }
}

.error {
  @include flex-justify-align(center);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
