@import "styles/vars";
@import "styles/mixins";

.root {
  padding: 0px 5% 25px;
  margin-right: 3%;
  margin-top: 20px;

  width: 100%;
  max-height: 40vh;

  box-shadow: 0px 4px 16px $shadow;
  border-radius: 4px 4px 0px 0px;
  overflow: scroll;

  @media (max-width: 450px) {
    max-height: 100%;
    overflow: auto;
    padding: 24px 12px 41px 6px;
  }
}

.title {
  @include flex-justify-align(space-between, flex-start);
  top: 0;
  position: sticky;
  z-index: 2;

  padding: 35px 0px 7px;

  @include font-size(16, $medium, 21);
  color: $font-color-black-secondary;

  background-color: #fff;

  @media (max-width: 450px) {
    z-index: 1;
    position: initial;
    padding: 0px 0px 7px;
  }
}

.variants {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.variant {
  @include flex-justify-align(space-between, flex-start);
  &_name {
    @include font-size(16, $regular, 24);
    color: $violet;
  }
  &_sku {
    @include font-size(14, $thin, 24);
    color: $violet;
  }
  &_price {
    @include font-size(16, $medium, 24);
    color: $violet;
  }
  @media (max-width: 450px) {
    &_name {
      @include font-size(16, $medium, 24);
    }

    &_sku {
      @include font-size(12, $regular, 18);
      color: rgba($violet, 0.5);
    }
  }
}

.upgrade_btn {
  height: 30px;
  width: 88px;
  margin-bottom: 13px;

  border: none;
  background-color: $purple;
  border-radius: 21px;

  color: $white;
  @include font-size(14, $bold, 18);

  transition: all 0.5s;

  &:hover {
    opacity: 0.7;
  }
}

.no_subscription {
  &_description {
    color: $violet;
    @include font-size(16, $thin, 24);
    margin-bottom: 31px;
  }

  &_available {
    &_title {
      color: $purple;
      @include font-size(14, $regular, 24);
    }

    &_subtitle {
      color: $violet;
      @include font-size(14, $thin, 24);
    }
  }
}
