@import "styles/vars";
@import "styles/mixins";

.transaction__overview {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(221, 227, 232, 1);
  border-radius: 3px;
  &_element {
    @include flex-justify-align(space-between, center);
    &_name {
      @include font-size(14, $medium, 24);
      color: rgba($font-color-black-secondary, 0.7);
    }
    &_value {
      @include font-size(14, $regular, 24);
      color: rgba($font-color-black-secondary, 0.7);
    }
  }
  @media (max-width: 450px) {
    padding: 20px;
    margin: 0 20px;
    &_element {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &_name {
        @include font-size(12, $demibold, 20);
      }
      &_value {
        @include font-size(12, $regular, 24);
      }
    }
  }
}
