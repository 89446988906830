@import "styles/vars";
@import "styles/mixins";

.content_container {
  position: relative;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 180px) calc(100% - 1000px) repeat(2, 180px);
  grid-template-rows: 160px 168px 10px 168px;
  width: 100%;
  @media (max-width: 1388px) {
    grid-template-columns: repeat(3, 180px);
    grid-template-rows: 348px 168px 168px;
    justify-content: center;
  }
  @media (max-width: 768px) {
    margin: 0;
    ::-webkit-scrollbar {
      margin: 0;
    }
  }
  @media (max-width: 450px) {
  }
}

.chart_container {
  grid-column: 1 / 4;
  grid-row: 1 / span 2;
  @media (max-width: 1388px) {
    grid-column: 1 / 4;
    grid-row: 1;
    margin: 0 10px;
  }
  @media (max-width: 768px) {
    margin: 0 20px;
  }
}

.monthly_average {
  grid-column: 5 / 7;
  grid-row: 1;
  @media (max-width: 1388px) {
    grid-column: 1;
    grid-row: 2;
  }
}

.high {
  grid-column: 5 / 6;
  grid-row: 2;
  @media (max-width: 1388px) {
    grid-column: 2;
    grid-row: 2;
  }
}

.low {
  grid-column: 6 / 7;
  grid-row: 2;
  @media (max-width: 1388px) {
    grid-column: 3;
    grid-row: 2;
  }
}

.total {
  grid-column: 1 / 2;
  grid-row: 4;
  @media (max-width: 1388px) {
    grid-column: 1;
    grid-row: 3;
  }
}

.paid {
  grid-column: 2 / 3;
  grid-row: 4;
  @media (max-width: 1388px) {
    grid-column: 2;
    grid-row: 3;
  }
}

.unpaid {
  grid-column: 3 / 4;
  grid-row: 4;
  @media (max-width: 1388px) {
    grid-column: 3;
    grid-row: 3;
  }
}

.chart {
  position: relative;
  background-color: #fff;
  display: flex;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px rgba(204, 188, 252, 0.3);
  flex-direction: column;
  height: 100%;
  padding: 17px 21px 25px;
  &_col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_row {
    display: flex;
    &_chart {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
  &_header {
    color: #7233cc;
    @include font-size(12, $demibold, 20);

    &_subtitle {
      color: rgb(32, 39, 43);
      @include font-size(12, $regular, 20);
    }
  }
  &_years {
    @include font-size(12, $medium, 20);
    color: rgba($violet, 0.5);
    text-transform: uppercase;
    @media (max-width: 1388px) {
      @include font-size(10, $medium, 20);
      color: rgba($violet, 0.5);
    }
  }
  &_text {
    color: rgba($violet, 1);
    margin-bottom: 40px;
    @include font-size(24, $thin, 40);
    &_nodata {
      @include font-size(24, $thin, 40);
      color: $violet;
    }
  }
}

.nodata {
  position: absolute;
  width: 196px;
  height: 120px;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p:first-child {
    @include font-size(14, $demibold, 20);
    color: rgba(0, 0, 0, 0.5);
  }
  button {
    color: rgb(114, 51, 204);
    text-decoration: underline;
  }
  p:last-child {
    @include font-size(12, $medium, 20);
    color: rgba(203, 198, 215, 1);
  }
}

.lines {
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  div:nth-child(1) {
    height: 30%;
  }
  div:nth-child(2) {
    height: 80%;
  }
  div:nth-child(3) {
    margin-right: 0px;
  }
}

.line {
  width: 9.5px;
  background-color: rgba(203, 198, 215, 1);
  height: 100%;
  border-radius: 4px;
  margin-right: 12px;
}
