@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.input_wrapper {
  position: relative;
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    width: 100%;
    min-width: 100%;
  }
}

.input {
  @include input();
  max-width: 100%;
  width: 280px;
  height: 60px;
  text-align: left;
  padding: 20px 60px 20px 20px;
  outline: none;

  @include font-size(16, $regular, 21);
  color: $font-color-black-secondary;

  &:focus {
    border-color: $purple;
  }

  &::placeholder {
    color: rgba($font-color-black-secondary, 0.4);
  }

  &:disabled {
    opacity: 0.2;
  }

  @media (max-width: 450px) {
    width: 100%;
    min-width: 100%;
  }
}
