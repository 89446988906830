@import "styles/vars";
@import "styles/mixins";

.overview {
  display: flex;
  flex-direction: column;
  padding: 30px 100px;
  gap: 10px;

  &_item {
    @include flex-justify-align(space-between, center);

    &_name {
      @include font-size(16, $regular, 24);
      color: $violet;
    }

    &_value {
      @include font-size(16, $thin, 24);
      color: $violet;
    }
  }

  @media (max-width: 1024px) {
    padding: 30px 12px;
    margin-left: 10px;
  }

  @media (max-width: 450px) {
    padding: 30px 12px;
    margin-left: 0px;
  }
}

.view_btn {
  @include reset-button();
  color: $purple;
  @include font-size(16, $medium, 24);

  transition: all 0.5s;

  &:not(:disabled):hover {
    opacity: 0.5;
  }

  &:disabled {
    background: rgba(216, 216, 216, 1);
    color: rgba($violet, 0.5);
    opacity: 0.6;
  }
}
