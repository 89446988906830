@import "styles/vars";
@import "styles/mixins";

.text_btn {
  @include reset-button;
  @include font-size(14, $demibold, 24);

  display: block;

  color: $purple;

  transition: opacity 0.3s;

  text-align: left;
  margin-right: 3px;

  &:hover {
    opacity: 0.5;
  }

  &:last-child {
    margin-right: initial;
  }

  &:disabled:not(:hover) {
    opacity: 0.1;
  }

  @media (max-width: 772px) {
    @include font-size(12, $demibold, 20);
  }
  @media (max-width: 450px) {
    @include font-size(12, $demibold, 20);
  }
}
