@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.info_modal {
  min-width: 720px;
  min-height: max-content;
  min-height: 842px;
  height: 92%;

  @media (max-width: 720px) {
    min-width: 90%;
  }

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  height: 100%;
}

.subtitle {
  @include font-size(18, $bold, 23);
  color: rgba($font-color-black-secondary, 0.65);
  margin-bottom: 20px;

  @media (max-width: 450px) {
    margin-bottom: 0px;
    @include font-size(16, $regular, 20);
  }
}

.customer_info_modal {
  display: flex;
  flex-direction: column;
  padding: 38px 28px 20px 20px;

  &_header {
    display: flex;
    justify-content: flex-end;
    .close_icon {
      cursor: pointer;
      align-self: flex-end;
      background-color: $font-color-black-secondary;
      width: 24px;
      height: 24px;
      @include flex-justify-align(center);
      border-radius: 50%;
      padding: 4px;
      fill: $white;
    }

    .left_arrow_icon {
      display: none;
    }
  }

  @media (max-width: 450px) {
    &_header {
      justify-content: flex-start;

      .close_icon {
        display: none;
      }

      .left_arrow_icon {
        display: flex;
      }
    }
  }

  .customer_info_modal_header_address {
    @extend .customer_info_modal_header;

    @media (max-width: 450px) {
      justify-content: flex-end;
      .close_icon {
        display: flex;
      }

      .left_arrow_icon {
        display: none;
      }
    }
  }
}

.customer_info_main {
  padding: 20px;
  padding-bottom: 0px;
  &_title {
    @include font-size(38, $bold, 49);
    color: $font-color-black-secondary;
    margin-bottom: 30px;
  }

  .form_name {
    &_label {
      @include font-size(14, $medium, 18);
      color: rgba($font-color-black-secondary, 0.4);
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    &_inputs {
      @include flex-justify-align(space-between);
    }
  }

  .form_phone__input {
    &_label {
      @include font-size(14, $medium, 18);
      color: rgba($font-color-black-secondary, 0.4);
      text-transform: uppercase;
      margin-bottom: 2px;
    }
  }

  .form_address {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &_label {
      @include font-size(14, $medium, 18);
      color: rgba($font-color-black-secondary, 0.4);
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    .input {
      @include input();
      max-width: 100%;
      width: 100%;
      height: 60px;
      text-align: left;
      padding: 20px;
      outline: none;

      @include font-size(16, $regular, 21);
      color: $font-color-black-secondary;

      &:focus {
        border-color: $purple;
      }

      &::placeholder {
        color: rgba($font-color-black-secondary, 0.4);
      }
    }
  }

  .form_email {
    &_label {
      @include font-size(14, $medium, 18);
      color: rgba($font-color-black-secondary, 0.4);
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    &_input_wrapper {
      @include flex-justify-align(space-between);

      input {
        @include input();
        max-width: 280px;
        width: 280px;
        height: 60px;
        text-align: left;
        padding: 20px;

        @include font-size(16, $regular, 21);
        color: $font-color-black-secondary;
        outline: none;

        &:focus {
          border-color: $purple;
        }

        &::placeholder {
          color: rgba($font-color-black-secondary, 0.4);
        }
      }

      .form_email_right {
        max-width: 280px;
        @include font-size(14, $thin, 18);
        color: rgba($font-color-black-secondary, 0.6);
      }
    }
  }

  @media (max-width: 450px) {
    &_title {
      @include font-size(20, $bold, 42);
      color: rgba($font-color-black-secondary, 0.65);
    }

    .form_name {
      &_inputs {
        @include flex-justify-align(flex-start, flex-start);
        flex-direction: column;
        gap: 4px;

        input {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .form_email {
      &_input_wrapper {
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        gap: 10px;

        div {
          width: 100%;
          input {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.error {
  margin-top: 5px;
  @include font-size(14, $thin, 18);
  color: #bf1650;

  &::before {
    color: #bf1650;
    display: inline;
    content: "⚠ ";
  }
}

.address_input_wrapper {
  position: relative;
}

.form_info {
  display: none;

  &_active {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.address_inputs_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .name_input {
    @include input();
    max-width: 100%;
    width: 100%;
    height: 60px;
    text-align: left;
    padding: 20px;
    outline: none;

    @include font-size(16, $regular, 21);
    color: $font-color-black-secondary;

    &:nth-child(2) {
      margin-bottom: 10px;
    }

    &_error {
      @extend .name_input;
      border: 1px solid #bf1650;
    }

    &:focus {
      border-color: $purple;
    }

    &::placeholder {
      color: rgba($font-color-black-secondary, 0.4);
    }

    @media (max-width: 450px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 4px;
    }
  }
}

.address_city_postcode_wrapper {
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 20px;
  }
}

.email_and_phone {
  position: relative;
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 15px;
  }
}

.address {
  width: 240px !important;

  @media (max-width: 450px) {
    width: 100% !important;
  }
}

.form_buttons {
  padding: 28px 20px 26px;

  background-color: #efefef;

  width: 100%;
  position: sticky;
  @include flex-justify-align(space-between);

  &_submit {
    @include purple-btn();
    width: 152px;
    height: 45px;

    &:disabled {
      color: $white;
      background: linear-gradient(55.52deg, #7233cc -5.2%, #6b59ed 65.07%);
      opacity: 0.2;
    }
  }

  &_cancel {
    height: 45px;
    width: 152px;

    padding: 10px;

    background-color: $white;
    border: 1px solid $purple;
    border-radius: 4px;
    text-align: center;
    @include flex-justify-align(center);
    color: $purple;
    @include font-size(16, $medium, 20);

    transition: all 0.5s;

    &:hover {
      opacity: 0.4;
    }
  }

  @media (max-width: 768px) {
    @include flex-justify-align(center);
    &_submit {
      width: 260px;
    }

    &_cancel {
      display: none;
    }
  }
}
