@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.layout,
.layout * {
  font-family: "Visuelt Pro", "Poppins", sans-serif;
}

.layout {
  height: 100%;
  min-height: 84vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 3% 22% 0;

  &_content {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: calc(100vh - 90px);
    gap: 40px;
    padding: 30px 60px 30px 60px;
    width: 100%;
    background-color: $white;
    position: relative;
    @media (max-width: 768px) {
      padding: 30px 20px 30px 20px;
    }
  }

  @media (max-width: 1024px) {
    margin: 3% 10px 0;
  }

  @media (max-width: 450px) {
    height: 100%;
    flex-direction: column;
    &_content {
      gap: 20px;
      padding: 0px;
      overflow: auto;
      height: 100%;
      padding-bottom: 15px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100vh;
  padding: 0;
  width: 65%;
  background-color: $white;
  position: relative;
  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  @include flex-justify-align(space-between, center);
  bottom: 0px;
  background-color: $background-color;
  left: 0;
  padding: 20px 60px;
  height: 100px;

  @media (max-width: 768px) {
    padding: 20px 20px;
  }

  @media (max-width: 450px) {
    margin-top: 15px;
    height: 150px;
    padding: 20px;
    position: static;
    flex-direction: column;
  }
}

.unpaid_invoice {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &_title {
    @include font-size(14, $regular, 20);
    color: $black;
  }

  &_total {
    @include font-size(24, $medium, 20);
    color: $black;
  }

  @media (max-width: 768px) {
    &_title {
      max-width: 134px;
      @include font-size(12, $regular, 20);
    }
  }

  @media (max-width: 450px) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    &_title {
      @include font-size(12, $regular, 14);
      max-width: 134px;
    }

    &_total {
      @include font-size(20, $medium, 20);
    }
  }
}

.btn {
  width: 250px;
  height: 60px;
  background-color: $purple;
  border: none;
  @include font-size(16, $demibold, 20, 1.33);
  color: $white;
  text-transform: uppercase;
  border-radius: 4px;
  @media (max-width: 768px) {
    width: 170px;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}
