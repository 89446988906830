@import "styles/vars";
@import "styles/mixins";

.list {
  margin: 0 5% 5%;
  box-shadow: 0px 4px 16px $shadow;
  border-radius: 4px;
  padding: 3px 63px 20px;

  @media (max-width: 1024px) {
    padding: 3px 0px 20px;
    margin: 0 0 5%;
  }

  @media (max-width: 772px) {
    margin: 10px;
    padding: 0;
  }
}

.table_header {
  position: relative;

  display: grid;
  grid-template-columns: 1.5fr 0.9fr 0.9fr 0.9fr 0.9fr 1.5fr;
  align-items: center;
  gap: 15px;
  height: 60px;
  padding: 20px 31px;
  background-color: #fff;
  border-bottom: 1px solid rgba(226, 209, 249, 0.3);

  button {
    display: flex;
    justify-content: flex-start;

    p {
      padding: 0;
    }
  }

  @include font-size(16, $medium, 21);
  color: $font-color-black-secondary;

  &_no_items {
    @extend .table_header;
    opacity: 0.5;
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }

  @media (max-width: 768px) {
    @include font-size(14, $regular, 20);
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 0px;
  }

  @media (max-width: 450px) {
    display: none;
  }
}

.more_btn {
  padding-top: 25px;

  @media (max-width: 1024px) {
    padding-left: 32px;
  }

  @media (max-width: 450px) {
    padding: 0;
    margin-top: 10px;

    @include flex-justify-align(center, center);
  }
}

.loading {
  @include flex-justify-align(center, center);
  width: 130px;
  height: 130px;

  svg {
    width: 50px !important;
    height: 50px !important;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}

.sort_mob {
  @include flex-justify-align(space-between);
  margin: 20px 0;

  &_label {
    @include font-size(16, $medium, 18);
    color: rgba($violet, 0.5);
  }
}