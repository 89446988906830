@import "styles/vars";
@import "styles/mixins";

.content {
  display: flex;
  flex-direction: column;
  width: 560px;
  padding: 30px 46px;

  &_header {
    margin: 10px 0;
    display: flex;
    align-items: center;
    color: $violet;
    @include font-size(16, $thin, 40);
    margin-bottom: 2em;
  }

  @media (max-width: 1024px) {
    &_header {
      margin: 0;
      @include font-size(11, $thin, 40);
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    padding: 20px 10px 0;
  }
}

.close_icon {
  align-self: flex-end;
  margin-bottom: 26px;
  transition: all 0.5s;

  &:hover:not(:disabled) {
    opacity: 0.8;
  }

  @media (max-width: 450px) {
    align-self: flex-start;
    border: 2px solid $purple;
    border-radius: 50%;
    padding: 8px;
    color: $purple;
    margin-bottom: 47px;
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
}

.title {
  @include font-size(24, $medium, 30);
  color: $violet;
  margin-bottom: 20px;

  @media (max-width: 450px) {
    @include font-size(16, $medium, 21);
    color: $font-color-black-secondary;
    margin-bottom: 5px;
  }
}

.name {
  @include font-size(16, $bold, 24);
  color: $font-color-black-secondary;
  margin-bottom: 22px;

  @media (max-width: 450px) {
    color: $font-color-black-secondary;
    @include font-size(16, $bold, 24);
    padding-bottom: 18px;
    border-bottom: 1px solid $divider-color-secondary;
    margin-bottom: 0;
  }
}

.email {
  @include flex-justify-align(space-between);
  margin-bottom: 32px;
  &_label {
    @include font-size(14, $medium, 24);
    color: $font-color-black-secondary;
  }

  &_input {
    width: 240px;
    height: 60px;
    padding: 0 20px;
    text-align: right;
    @include font-size(16, $regular, 24);
    border: 1px solid rgba(221, 227, 232, 1);
    border-radius: 4px;
    text-align: right;
    color: $font-color-black-secondary;
  }

  @media (max-width: 450px) {
    @include flex-justify-align(space-between, flex-start);
    flex-direction: column;
    margin-top: 18px;
    margin-bottom: 0px;

    &_label {
      color: rgba($font-color-black-secondary, 0.4);
      text-transform: uppercase;
    }

    &_input {
      width: 70%;
      text-align: left;
    }
  }
}

.message {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &_label {
    @include font-size(14, $medium, 24);
    color: $font-color-black-secondary;
  }

  &_input {
    padding: 15px;
    height: 195px;
    @include font-size(14, $regular, 18);
    border: 1px solid rgba(221, 227, 232, 1);
    border-radius: 4px;
    resize: none;
    margin-bottom: 32px;
  }

  @media (max-width: 450px) {
    &_input {
      height: 223px;
      margin-bottom: 8px;
    }
  }
}

.send_reminder {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &_form {
    display: flex;
    flex-direction: column;
  }

  &_footer {
    @include flex-justify-align(space-between, stretch);
    flex-direction: column;
  }

  &_button {
    align-self: center;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px 55px;
    border: none;
    border-radius: 4px;
    @include font-size(18, $medium, 18);
    color: #fff;
    background: $background-button;
    transition: all 0.5s;

    &_wrapper {
      align-self: center;
    }

    &:hover:not(:disabled) {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.2;
    }
    align-self: center;
    text-transform: uppercase;
  }

  @media (max-width: 450px) {
    gap: 5px;
    padding: 0;

    &_button {
      width: 100%;
      @include font-size(16, $medium, 20);

      &_wrapper {
        width: 100%;
        background-color: $background-color;
        padding: 19px 36px;
        box-shadow: 0px 4px 16px $shadow;
      }
    }

    &_info {
      box-shadow: 0px 4px 16px $shadow;
      border-radius: 4px 4px 0px 0px;
      padding: 23px 9px 30px;
    }

    &_footer {
      box-shadow: 0px 4px 16px $shadow;
      border-radius: 4px 4px 0px 0px;
      padding: 23px 9px 20px;
    }
  }
}
