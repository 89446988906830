@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.refund {
  &_content {
    padding: 0 10%;

    h2 {
      @include font-size(24, $bold, 42);
      color: rgba($font-color-black-secondary, 0.52);

      margin-bottom: 20px;
    }

    h3 {
      color: rgba($font-color-black-secondary, 0.6);
      @include font-size(14, $medium, 18);

      text-transform: uppercase;
      margin-bottom: 8px;
    }

    &_description {
      color: rgba($font-color-black-secondary, 0.6);
      @include font-size(16, $regular, 20);
    }

    @media (max-width: 570px) {
      h2 {
        margin-bottom: 30px;
        padding-bottom: 13px;
        border-bottom: 1px solid rgba($light-blue, 0.4);
      }
    }
  }
}

.shippingName {
  color: $purple;
  @include font-size(14, $bold, 20);
}
