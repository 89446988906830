@import "styles/vars";
@import "styles/mixins";

.select {
  box-shadow: 0px 4px 16px 0px rgba(204, 188, 252, 0.3);
  padding: 13px 5px 17px 20px;
  border-radius: 4px;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  @media (max-width: 1388px) {
    padding: 13px 5px 18px 20px;
  }

  @media (max-width: 450px) {
    padding: 20px 14px;
  }
}

.title {
  @include font-size(12, $demibold, 20);
  color: rgba($violet, 0.5);
  text-transform: uppercase;
  margin-bottom: 5px;
  text-align: left;
  @media (max-width: 1388px) {
    @include font-size(12, $medium, 15, 1);
    margin-bottom: 5px;
  }
}

.subtitle {
  color: $violet;
  text-align: left;
  margin-bottom: 10px;
  @include font-size(16, $regular, 20);
  &_nodata {
    color: $violet;
  }
  @media (max-width: 1388px) {
    @include font-size(14, $medium, 18);
  }
}

.details_select {
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: 1388px) {
    margin-bottom: 0px;
    text-align: left;
  }
  @media (max-width: 450px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.value {
  color: $purple;
  @include font-size(16, $bold, 21);
  margin-bottom: 10px;
  @media (max-width: 1388px) {
    @include font-size(16, $demibold, 21);
  }
}

.right_side {
  display: flex;
  flex-direction: column;
}

.percents_container {
  &_growth {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 17px;
    div {
      background-color: #caf1c3;
      border-radius: 100%;
      height: 15px;
      width: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
        width: 10px;
        height: 10px;
        transform: rotate(180deg);
        path {
          fill: #51c03e;
          stroke: #51c03e;
        }
      }
    }
    .percents {
      @include font-size(12, $medium, 14);
      color: rgba(81, 192, 62, 1);
      margin-bottom: 0px;
      &_zero {
        @include font-size(10, $demibold, 20);
        margin-bottom: 0px;
        color: rgb(203, 198, 215);
      }
      @media (max-width: 1388px) {
        @include font-size(12, $medium, 15);
        margin-top: 0px;
        &_zero {
          @include font-size(12, $medium, 15);
        }
      }
    }
  }
  &_decline {
    @extend .percents_container_growth;
    div {
      background-color: rgba(247, 191, 188, 1);
      svg {
        transform: rotate(0deg);
        path {
          fill: $red;
          stroke: $red;
        }
      }
    }
    .percents {
      color: $red;
    }
  }
  @media (max-width: 1388px) {
    &_growth {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 450px) {
    &_growth {
      margin: 0;
      align-self: flex-end;
    }
  }
}

.nodata {
  @include font-size(12, $medium, 20);
  color: rgb(203, 198, 215);
  margin-bottom: 12px;
  @media (max-width: 1388px) {
    @include font-size(12, $medium, 15);
    margin-bottom: 23px;
    color: rgba(203, 198, 215, 0.5);
  }
  @media (max-width: 450px) {
    margin-bottom: 0px;
    @include font-size(12, $medium, 20);
  }
}

.unit {
  font-weight: 300;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  svg {
    width: 50px !important;
    height: 50px !important;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
}
