@import "styles/vars";
@import "styles/mixins";

.sidebar {
  width: 35%;
  background-color: rgba(247, 248, 253, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 20px;
  padding-top: 60px;
  &_title {
    @include font-size(24, $bold, 40, 1);
    padding: 0px 60px;
    border-bottom: 1px solid rgba(228, 234, 238, 1);
  }
  &_price {
    position: fixed;
    bottom: 24px;
    display: flex;
    flex-direction: column;
  }
  &_items {
    padding: 0px 60px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    gap: 30px;
    overflow: auto;
  }
  @media (max-width: 1150px) {
    padding-top: 60px;
    &_title {
      padding: 0px 20px;
    }
    &_items {
      padding: 0px 20px;
    }
  }

  @media (max-width: 768px) {
    padding-top: 20px;
    &_title {
      padding: 0px 10px;
    }
    &_items {
      padding: 0px 10px;
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    padding: 20px 20px;
    margin: 0 4px;
    &_title {
      @include font-size(16, $bold, 40, 0.66);
      padding: 0px;
    }
    &_items {
      gap: 20px;
      height: 100%;
    }
    &_price {
      position: static;
      bottom: 24px;
      display: flex;
      flex-direction: column;
    }
  }
}
