@import "styles/vars";
@import "styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .main_col {
    display: flex;
    justify-content: space-between;

    color: $violet;
    @include font-size(16, $demibold, 21);

    .title {
      @include font-size(16, $demibold, 21);
    }

    @media (max-width: 450px) {
      @include font-size(14, $demibold, 20);
      .title {
        @include font-size(14, $demibold, 20);
      }
    }
  }
  .sub_col {
    display: flex;
    justify-content: space-between;
    color: $violet;
    @include font-size(16, $regular, 21);

    .title {
      @include font-size(16, $regular, 21);
    }

    @media (max-width: 450px) {
      padding: 0;
      @include font-size(14, $regular, 20);
      .title {
        @include font-size(14, $medium, 20);
      }
    }
  }

  .total {
    display: flex;
    color: $purple;
    @include font-size(16, $bold, 20);
    color: $purple;
    justify-content: space-between;
    .title {
      @include font-size(16, $bold, 20);
    }
    @media (max-width: 450px) {
      @include font-size(14, $bold, 20);
      .title {
        @include font-size(14, $bold, 20);
      }
    }
  }
  @media (min-width: 1388px) {
  }
  @media (max-width: 450px) {
    margin-left: 0;
  }
}

.year {
  align-self: flex-start;
  @include font-size(12, $medium, 20, 1);
  color: rgba($violet, 0.5);
  width: 100%;
  text-align: left;
}

.box {
  padding: 25px 21px;
  box-shadow: 0px 4px 16px 0px rgba(204, 188, 252, 0.15);
  border-radius: 4px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  &_total {
    @extend .box;
    padding: 15px 21px;
  }
}

.root_title {
  @include font-size(24, $thin, 31);
}
