@import "styles/vars";
@import "styles/mixins";

.footer {
  @include flex-justify-align(space-between, stretch);
  flex-direction: column;
  gap: 20px;
  padding: 25px 5% 25px 10%;
  box-shadow: 0px 4px 16px 0px $shadow;
  margin-right: 5%;
  background-color: $background-color;
  &_buttons {
    @include flex-justify-align(space-between);
  }
  &_remaining_balance {
    @include flex-justify-align(space-between);
    gap: 10px;
    &_label {
      @include font-size(14, $regular, 20);
      color: $font-color-black-secondary;
    }
    &_value {
      @include font-size(24, $medium, 20);
      color: $font-color-black-secondary;
    }
    &_button {
      border: none;
      border-radius: 10px;
      border: 1px solid $purple;
      width: 250px;
      height: 60px;
      text-transform: uppercase;
      background: transparent;
      color: $purple;
      @include font-size(18, $medium, 20);
      transition: all 0.5s ease-in-out;
      &:hover:not(:disabled) {
        opacity: 0.8;
      }
      &:disabled {
        background: rgba(216, 216, 216, 1);
        color: rgba($violet, 0.5);
      }
    }
  }
  &_record_payment_button {
    border: none;
    border-radius: 4px;
    border: 1px solid $purple;
    width: 250px;
    height: 60px;
    text-transform: uppercase;
    background: $background-button;
    color: #fff;
    @include font-size(18, $medium, 20);
    transition: all 0.5s ease-in-out;
    &:hover:not(:disabled) {
      opacity: 0.8;
    }
    &:disabled {
      border: none;
      background: rgba(216, 216, 216, 1);
      color: rgba($violet, 0.5);
    }
  }
  &_paid {
    @extend .footer;
    flex-direction: row;
    .footer_remaining_balance {
      flex-direction: column;
      gap: 10px;
      justify-content: flex-start;
      align-items: flex-start;
      &_button {
        display: none;
      }
      &_value {
        @include font-size(24, $medium, 20);
        color: rgba($violet, 0.5);
      }
      @media (max-width: 450px) {
        flex-direction: row;
        @include flex-justify-align(space-between);
      }
    }
  }
  &_pending {
    @extend .footer;
    flex-direction: row;
    .footer {
      &_remaining_balance {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        &_button {
          @include flex-justify-align(center);
        }
        @media (max-width: 450px) {
          flex-direction: row;
          @include flex-justify-align(space-between);
        }
      }
      &_record_payment_button {
        display: none;
      }
    }
  }
  @media (max-width: 1024px) {
    margin-right: 46px;
    padding: 25px 5% 25px 25px;
  }
  @media (max-width: 768px) {
    margin-right: 12px;
    padding: 20px 25px;
    &_remaining_balance {
      &_button {
        width: 160px;
        @include font-size(16, $medium, 20);
      }
    }
    &_record_payment_button {
      @include font-size(16, $medium, 20);
      width: 160px;
    }
  }
  @media (max-width: 450px) {
    padding: 26px 16px;
    margin: 0px;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
    &_buttons {
      flex-direction: column;
      gap: 10px;
    }
    &_record_payment_button {
      width: 100%;
    }
    &_remaining_balance {
      justify-content: space-between;
      &_value {
        @include font-size(18, $medium, 20);
      }
      &_button {
        width: 100%;
      }
    }
  }
}
