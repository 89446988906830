@import "styles/vars";
@import "styles/mixins";

.table_content {
  display: flex;
  flex-direction: column;

  &_item {
    display: grid;
    grid-template-columns: 128px 109px 111px 72px 120px 140px 1fr;
    grid-template-columns: repeat(3, minmax(10%, 1fr)) 72px repeat(
        3,
        minmax(10%, 1fr)
      );
    align-items: start;
    gap: 10px;

    background-color: #fff;
    padding: 20px 31px;
    border-bottom: 1px solid rgba(226, 209, 249, 0.3);

    @include font-size(16, $regular, 20);
    color: $violet;

    margin-bottom: 5px;

    text-align: left;

    &:hover {
      color: $purple;
    }

    p:nth-child(n + 5) {
      text-align: right;
      width: calc(100% - 20px);
    }

    @media (max-width: 1024px) {
      grid-template-columns: 90px 100px 100px 70px 120px 100px 1fr;
      gap: 10px;
      padding: 15px 20px;
    }

    @media (max-width: 768px) {
      p:nth-child(5) {
        text-align: center;
      }
      text-align: flex-start;
      @include font-size(14, $regular, 20);
      grid-template-columns: 74px 70px 80px 65px 110px 90px 1fr;
      gap: 0px;
      gap: 0px;
      padding: 8px 0px;
    }
  }
}

.item_mb {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 16px 0px $shadow;
  @include rounded-box-list-second(8px, 16px);

  &_header {
    @include flex-justify-align(space-between);
    margin-bottom: 12px;

    &_date {
      @include font-size(14, $regular, 18);
      color: rgba($violet, 0.5);
    }

    &_type {
      @include font-size(14, $bold, 18);
      color: rgba($violet, 0.5);
    }
  }

  &_name {
    @include font-size(16, $medium, 20);
    color: $violet;
  }

  &_source {
    margin-bottom: 9px;

    @include font-size(12, $regular, 18);
    color: rgba($violet, 0.5);
  }

  &_value {
    margin-bottom: 6px;

    @include font-size(14, $regular, 18);
    color: $violet;
  }

  &_bottom {
    @include flex-justify-align(stretch, stretch);
    flex-direction: column;

    @include font-size(14, $regular, 18);
    color: rgba($violet, 0.5);

    &_first_row {
      @include flex-justify-align(space-between);
      margin-bottom: 2px;
    }

    &_second_row {
      @include flex-justify-align(space-between);
    }
  }
}
