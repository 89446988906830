@import "styles/vars";
@import "styles/mixins";

.sort_mob {
  @include flex-justify-align(space-between);
  margin: 20px;
  &_label {
    @include font-size(12, $medium, 18);
    color: rgba($violet, 0.5);
  }
}
