@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 27px 20px 28px 20px;
  box-shadow: 0px 4px 16px $shadow;
  background-color: $background-color-secondary;
  margin: 0px -10px;

  @media (max-width: 450px) {
    margin: 0px -15px 0 -20px;
  }
}

.title {
  @include font-size(14, $medium, 20, -0.04);
  color: rgba($font-color-black-secondary, 0.65);
}

.info {
  display: flex;
  flex-direction: column;
  gap: 12px;

  div {
    @include flex-justify-align(space-between);
    p {
      &:first-child {
        @include font-size(12, $regular, 13, 0.066);
        color: $font-color-gray-secondary;
      }

      &:last-child {
        @include font-size(12, $medium, 13, 0.07);
        color: $font-color-black-secondary;
      }
    }

    a {
      display: flex;
      gap: 6px;

      @include font-size(12, $medium, 13, 0.066);
      color: $purple;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      p {
        &:last-child {
          @include font-size(10, $regular, 13, 0.07);
          color: $font-color-black-secondary;
        }

        &:first-child,
        &:only-child {
          @include font-size(12, $medium, 13, 0.07);
          color: $font-color-black-secondary;
        }
      }
    }
  }
}

.pickup {
  @extend .root;
  @include flex-justify-align(space-between);
  flex-direction: row;

  p {
    &:first-child {
      @extend .title;
      padding-bottom: 6px;
    }
  }

  .qr {
    @include flex-justify-align(space-between);
    gap: 10px;

    p {
      @include font-size(12, $regular, 13, 0.07);
      color: $font-color-gray-secondary;
      max-width: 99px;
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  button {
    @include reset-button();
    text-align: right;
    color: $purple;

    @include font-size(12, $regular, 13, 0.07);
  }

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 20px;
    @include flex-justify-align(flex-start, flex-start);

    button {
      text-align: left;
    }
  }
}

.pickup_accepted {
  @extend .root;

  &_bottom {
    @include flex-justify-align(space-between);

    &_status {
      @include font-size(12, $regular, 13, 0.07);
    }

    &_date {
      p {
        @include font-size(12, $medium, 13, 0.07);
        color: $font-color-black-secondary;

        &:last-child {
          @include font-size(10, $regular, 13, 0.07);
        }
      }
    }
  }
}
