@import "styles/vars";
@import "styles/mixins";

.error {
  display: block;
  text-align: center;
  margin-top: 20%;
}

.content {
  height: 100%;

  margin: 0 3% 30px;
  @include flex-justify-align(center, center);

  @media (max-width: 768px) {
    margin: 0;
  }

  @media (max-width: 450px) {
    height: 100%;
    min-height: 180px;
  }
}

.loading_wrapper {
  overflow: hidden;
  padding: 5%;
}
