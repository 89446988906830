@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.search_tags {
  z-index: 2;
  top: 75px;
  width: 100%;
  position: absolute;
  padding: 25px 76px;
  background: #f2f2f2;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);

  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;

  &_active {
    @extend .search_tags;
    visibility: visible;
    opacity: 1;
  }

  h3 {
    margin-bottom: 10px;
    @include font-size(14, $medium, 18);
  }

  &_list {
    display: flex;
    gap: 20px;
  }

  @media (max-width: 768px) {
    padding: 25px 27px;
    &_list {
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  @media (max-width: 450px) {
    padding: 12px 8px;
    top: 46px;

    &_list {
      gap: 10px;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.search_tag {
  @include flex-justify-align(center, center);
  @include purple-btn;
  @include text-truncate;
  padding: 10px;
  gap: 6px;
  width: auto;
  height: auto;
  min-width: 98px;

  background: $font-color-black-secondary;

  @include font-size(14, $medium, 20);

  &:not(:disabled):hover {
    opacity: 1;
    background-color: $purple;
  }

  svg {
    max-width: 16px;
    max-height: 16px;
  }
}
