/* font-weights */
$black: 900;
$extra-bold: 800;
$bold: 700;
$demibold: 600;
$medium: 500;
$regular: 400;
$thin: 300;

/* font colors */
$white: rgba(255, 255, 255, 1);

$font-main-color: rgba(46, 46, 46, 1);
$font-color-black: rgba(32, 39, 43, 1);
$font-color-black-secondary: rgba(25, 28, 35, 1);
$font-color-black-third: rgba(21, 21, 21, 1);
$black: rgba(27, 33, 37, 1);

$light-blue: rgba(144, 149, 166, 1);

$font-color-gray: rgba(135, 135, 135, 1);
$font-color-gray-secondary: rgba(102, 112, 133, 1);
$color-grey-secondary: rgba(155, 164, 176, 1);
$font-color-gray-third: rgba(115, 115, 115, 1);
$grey: rgba(120, 137, 149, 1);

$font-color-purple: rgba(114, 51, 204, 1);
$purple: rgba(107, 89, 237, 1);

$violet: rgba(107, 105, 116, 1);
$light-violet: rgba(104, 49, 243, 1);

$divider-color-secondary: rgba(242, 242, 242, 1);
$divider-color: rgba(228, 234, 238, 1);

$light-ultramarine: rgba(212, 217, 221, 1);

$background-color: rgba(239, 239, 239, 1);
$background-color-secondary: rgba(247, 248, 253, 1);

$red: rgba(246, 74, 63, 1);
$light-red: rgba(246, 47, 94, 1);
$light-red-secondary: rgba(232, 74, 95, 1);

$shadow: rgba(204, 188, 252, 0.15);
$background-button: linear-gradient(55.52deg, #7233cc -5.2%, #6b59ed 65.07%);
