@import "styles/vars";
@import "styles/mixins";

.shop_items {
  margin-top: 80px;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 60px);
  -webkit-overflow-scrolling: touch;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-auto-rows: auto;
  gap: 11px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(290px, 290px));
    gap: 41px;
  }

  @media (max-width: 765px) {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    gap: 20px;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(1, minmax(150px, 100%));
    gap: 20px;
    overflow: hidden;
    padding: 0 0 100px 0;
    height: 100%;
  }
}

.loading {
  @include flex-justify-align(center, center);
  position: absolute;
  left: 0;
  top: 20px;

  width: 100%;
  height: 100%;

  div {
    width: 100px;
    height: 100px;
  }

  svg {
    width: 100px !important;
    height: 100px !important;
  }

  @media (max-width: 450px) {
    width: 100%;
    top: 40px;
  }
}

.loading_product {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 130px;
  div {
    width: auto;
    height: auto;
  }
  svg {
    width: 50px !important;
    height: 50px !important;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
}
