@import "styles/vars";
@import "styles/mixins";

.search {
  position: relative;
  display: block;
  text-align: center;
  width: 575px;

  svg {
    position: absolute;
    top: 14px;
    left: 20px;
    font-weight: bold;
    font-style: normal;
  }

  input {
    z-index: 1;

    width: 100%;
    height: 48px;
    border: none;
    border: 1px solid rgba(144, 149, 166, 0.5);
    border-radius: 40px;
    padding: 20px 60px;
    @include font-size(14, $regular, 18, -0.24);

    &:focus {
      outline-color: #d6d6d6;
    }

    &::placeholder {
      color: rgba(27, 21, 61, 0.5);
    }
  }

  .close_icon {
    display: none;
  }

  .back_icon {
    display: none;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}

.search_active {
  @extend .search;

  width: 100%;

  border: none;

  @include flex-justify-align(center, center);

  input {
    margin-left: 60px;
  }

  .search_icon {
    display: none;
  }

  .close_icon {
    display: block;
  }

  .back_icon {
    display: block;
    transition: all 0.5s;
    cursor: pointer;
  }

  .close_icon {
    position: absolute;
    left: calc(100% - 40px);
    z-index: 3;
    width: 20px;
    height: 20px;
    padding: 4px;
    color: rgba(203, 198, 215, 1);

    background-color: $purple;
    border-radius: 50%;

    transition: all 0.5s;

    &_active {
      @extend .close_icon;
      cursor: pointer;
      color: $white;
    }
  }
}

.search_wrapper_active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  z-index: 5;

  padding: 40px 60px;

  .header {
    padding-bottom: 70px;
  }

  input {
    padding: 20px 60px 20px 20px;
  }

  @media (max-width: 450px) {
    padding: 15px;

    input {
      padding: 20px 60px 20px 10px;
    }

    .header {
      display: none;
    }
  }
}

.shop_items {
  padding-top: 28px;
  margin-left: 60px;
  overflow: scroll;
  height: calc(100% - 120px);

  @media (max-width: 450px) {
    margin-left: 0px;
    height: calc(100% - 40px);
  }
}

.not_found {
  height: 100%;
  @include flex-justify-align(center, center);
  flex-direction: column;
  @include font-size(14, $regular, 18);
  color: rgba(27, 21, 61, 0.55);

  svg {
    opacity: 0.2;
    width: 80px;
    height: 80px;

    margin-bottom: 20px;
  }
}

.loading_product {
  margin-top: 20px;
}
