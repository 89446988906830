@import "styles/vars";
@import "styles/mixins";

.card_form_modal {
  min-width: 720px;

  @media (max-width: 450px) {
    min-width: 100%;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
}

.title {
  @include flex-justify-align(space-between);
  margin: 0 30px 20px;

  p {
    @include font-size(38, $bold, 48);
    color: $font-color-black-secondary;
  }

  div {
    display: flex;
    gap: 8px;
  }

  @media (max-width: 450px) {
    margin: 0 15px 20px;

    p {
      @include font-size(20, $bold, 42);
      color: rgba($font-color-black-secondary, 0.65);
    }

    div {
      display: none;
    }
  }
}

.inputs {
  margin: 0 15px 20px;

  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: 450px) {
    gap: 20px;
  }
}

.stripe__cardform_form {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  &_group {
    @include flex-justify-align(space-between);
  }

  @media (max-width: 450px) {
    &_group {
      flex-direction: column;
      label {
        width: 100%;
      }
    }
  }
}

.stripe__cardform_input {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  margin: 0;

  span {
    @include font-size(14, $medium, 18);
    color: rgba($font-color-black-secondary, 0.4);
    text-transform: uppercase;
  }

  input {
    padding: 20px;
    height: 60px;
    outline: none;

    @include font-size(16, $regular, 20);
    color: $font-color-black-secondary;

    border: 1px solid rgba(221, 227, 232, 1);
    border-radius: 3px;

    &:focus {
      border-color: $purple;
    }

    &::placeholder {
      color: rgba($font-color-black-secondary, 0.4);
    }
  }

  small {
    @include flex-justify-align(space-between);
    color: rgba(232, 74, 95, 1);
  }

  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
}

.stripe__cardform_form_disabled {
  margin-top: 20px;
  opacity: 0.5;
}

.stripe__cardform_footer {
  padding: 28px 20px 26px;

  background-color: #efefef;

  width: 100%;
  position: sticky;
  @include flex-justify-align(space-between);
  &_left {
    display: flex;
    align-items: center;
    gap: 7px;
    div {
      display: flex;
      flex-direction: column;

      img {
        width: 42.22px;
        height: 20px;
      }

      span {
        @include font-size(11, $regular, 16);
        color: $font-color-black-secondary;
        max-width: 338px;
      }
    }
  }

  &:only-child {
    flex-grow: 10;
  }

  button {
    @include reset-button;
    @include purple-btn;
    width: 152px;
    height: 45px;

    &:disabled {
      color: $white;
      background: linear-gradient(55.52deg, #7233cc -5.2%, #6b59ed 65.07%);
      opacity: 0.2;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    background-color: transparent;
    gap: 20px;

    &_left {
      flex-direction: column;
      align-items: flex-start;
    }

    button {
      width: 100%;
    }
  }
}

.stripe__input {
  @include font-size(16, $regular, 24);
  padding: 20px;
  border: 1px solid rgba(221, 227, 232, 1);
  border-radius: 4px;
  &:focus {
    border-color: $purple;
  }
}

.county_select {
  @media (max-width: 1024px) {
    width: 280px;
  }

  @media (max-width: 450px) {
    width: 100%;

    label {
      @include font-size(14, $medium, 20);
    }
  }
}
