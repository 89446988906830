@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root,
.root * {
  font-family: "Visuelt Pro", "Poppins", sans-serif;
}

.root {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/login.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* IE */
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/main_bg_2.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/main_bg_2.jpg', sizingMethod='scale')";
  filter: saturate(140%);

  &_container {
    height: 100%;
    padding: 60px 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (max-width: 1100px) {
    &_container {
      padding: 60px 8%;
    }
  }

  @media (max-width: 772px) {
    &_container {
      padding: 60px 9%;
    }
  }
  @media (max-width: 450px) {
    background: url("../../assets/images/login-mobile.png") no-repeat center
      center fixed;
    &_container {
      padding: 0px 20px;
      justify-content: flex-start;
      gap: 63px;
    }
  }
}

.modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  &_show {
    @extend .modal;
    display: block;
  }
  &_hide {
    display: none;
  }
  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    background: white;
    min-height: 30%;
    width: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 40px 60px 60px 52px;
    @include font-size(13, $demibold, 20);
    @media (max-width: 1100px) {
      width: 100%;
      padding: 20px 13px;
    }
    &_title {
      @include font-size(24, $thin, 40);
      text-align: center;
      color: #4a4a4a;
      @media (max-width: 1100px) {
        @include font-size(14, $bold, 20);
      }
    }
    &_text {
      margin-top: 20px;
      margin-bottom: 20px;
      @include font-size(14, $regular, 20);
      color: rgb(32, 39, 43);
      text-align: center;
      @media (max-width: 1100px) {
        @include font-size(12, $regular, 20);
        padding: 10px 0px;
      }
    }
    button {
      margin-top: 40px;
      text-align: center;
      padding: 13px 60px;
      border: none;
      border-radius: 2px;
      background-color: $font-color-purple;
      color: #fff;
      text-transform: uppercase;
      @include font-size(16, $regular, 23);
      @media (max-width: 1100px) {
        margin: 20px 0;
      }
    }
  }
}

.overlay {
  margin: 0 auto;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  &_error {
    box-shadow: 0px 0px 50px #f24750;
  }

  @media (max-width: 450px) {
    padding: 0;
  }
}

.login {
  width: 100%;

  &_logo {
    width: 79px;
    height: 20px;
    margin-bottom: 41px;
  }

  &_divider {
    display: none;
  }

  h1 {
    font-family: "Visuelt Pro";
    @include font-size(50, $bold, 78);
    color: white;
  }
  @media (max-width: 450px) {
    h1 {
      @include font-size(24, $bold, 42);
    }
  }
}

.login_phone {
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  &_input {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    flex-direction: column;
    border: none;
    padding: 0;
    &:focus-within {
      border: none;
    }
  }

  &_btn {
    @include font-size(24, $bold, 30);
    font-family: "Visuelt Pro";
    @include reset-button;

    width: 300px;
    height: 100px;
    background: $background-button;
    border-radius: 4px;
    color: #fff;

    transition: all 0.3s;

    &:disabled {
      opacity: 0.1;
      color: rgba(#fff, 0.2);
    }

    &:hover:not(:disabled) {
      opacity: 0.8;
    }

    @media (max-width: 450px) {
      width: 100%;
      height: auto;
      padding: 17px 0 17px 0;
      @include font-size(20, $bold, 26);
    }
  }
}

.get_app {
  color: #5b6b82;
  @include font-size(12, $regular, 40, -0.2);
  margin-top: 50px;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  button {
    @include font-size(12, $regular, 16, -0.2);
    transition: 0.3s all;
    color: $font-color-purple;
    border-radius: 24px;
    border: 1px solid $font-color-purple;
    background-color: #fff;
    padding: 12px 15px;
    svg {
      margin-right: 5px;
    }
  }

  button:hover {
    color: rgba(114, 51, 204, 0.5);
    svg {
      path {
        stroke: rgba(114, 51, 204, 0.5);
      }
    }
    border: 1px solid rgba(114, 51, 204, 0.5);
  }

  @media (max-width: 772px) {
    @include font-size(12, $regular, 36);
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    button {
      display: block;
      line-height: 10px;
      width: 50%;
    }
  }
}

.world_languages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_change {
    width: 120px;
    padding-right: 20px;
    background: transparent;
    border: "none";
    font-family: "Visuelt Pro";
    @include font-size(20, $bold, 20);
  }
  svg {
    margin-right: 0px;
  }
}

.back {
  color: white;
  align-self: flex-start;
  display: flex;
  align-items: center;
  text-align: center;
  button {
    border: none;
    font-family: "Visuelt Pro";
    display: flex;
    align-items: center;
    @include font-size(20, $medium, 20);
    background-color: transparent;
    color: white;
    transition: all 0.5s ease-out;
    svg {
      margin-right: 12px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  @media (max-width: 772px) {
    margin-top: 29px;
  }
}

.root_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &_title {
    @include font-size(24, $regular, 16);
    color: #fff;
    text-align: center;
    svg {
      width: 77px;
      height: 21px;
    }
  }
  &_btn {
    @include reset-button;
    width: 162px;
    height: 40px;
    background-image: $background-button;
    border-radius: 6px;
    color: white;
    font-family: "Visuelt Pro";
    @include font-size(18, $bold, 22);
    transition: all 0.3s ease-in-out;
    &:hover:not(:disabled) {
      opacity: 0.8;
    }
  }
  @media (max-width: 450px) {
    padding: 20px;
  }
}

.hamburger {
  outline: none;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0;

  &_lines {
    display: block;
    width: 18px;
    height: 2px;
    background-color: white;
    transition: all 0.3s linear;
  }

  &_active {
    span:nth-child(1) {
      transform: translateY(7px) rotate(45deg);
      height: 3px;
    }

    span:nth-child(2) {
      opacity: 0;
      visibility: hidden;
    }

    span:nth-child(3) {
      height: 3px;
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
