@import "styles/vars";
@import "styles/mixins";

$black: $font-main-color;
$grey: rgba(255, 255, 255, 0.5);
$red: rgba(232, 74, 95, 1);
$grey-secondary: rgba(25, 28, 35, 0.6);

.item_description {
  display: flex;
  justify-content: space-between;
  h3 {
    margin-right: 11px;
    color: $black;
    @include font-size(14, $medium, 30);
    flex: 1;
    div {
      @include font-size(12, $thin, 20);
    }
  }
  @media (max-width: 768px) {
    h3 {
      @include font-size(13, $medium, 20);
    }
  }
}

.item_quantity {
  width: 50px;
  opacity: 0.6;
  height: 36px;
  align-self: flex-start;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 100px;
  background-color: $grey;
  color: rgba($font-color-black-secondary, 0.7);
  text-align: center;
  margin: 10px 18px 10px 10px;
  @include font-size(16, $bold, 24);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 32px;
    margin: 0px 10px 10px 10px;
    @include font-size(13, $bold, 24);
  }
}

.item_price {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  span:first-child {
    color: $font-color-black-secondary;
    @include font-size(16, $bold, 20, 0.69);
  }
  &_btn {
    background-color: $purple;
    margin-top: 15px;
    border-radius: 24px;
    width: 120px;
    border: none;
    color: white;
    @include font-size(12, $bold, 18);
    padding: 7px 16px;
    transition: all 0.3s ease-in-out;
    &:disabled {
      background-color: rgba($purple, 0.1);
    }

    &:hover:not(:disabled) {
      background-color: rgba($purple, 0.7);
    }
  }
  @media (max-width: 768px) {
    span:first-child {
      @include font-size(14, $bold, 20, 0.6);
    }
  }
}

.item_avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  width: 84px;
  height: 84px;
  background-color: #fff;
  border-radius: 2px;
  span {
    @include font-size(28, $regular, 36, -0.5px);
    color: rgba(104, 49, 243, 1);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_btn {
    @include reset-button;
    @include font-size(12, $regular, 11);

    width: 92px;
    height: 24px;

    color: #fff;
    position: absolute;
    bottom: -10px;

    background-color: rgba(91, 107, 130, 1);
    border-radius: 12px;

    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
  @media (max-width: 768px) {
    width: 78px;
    height: 78px;
    &_btn {
      width: 78px;
      height: 78px;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
}

.item_taxes {
  margin-top: 10px;
  margin-right: 18px;
  .item_tax {
    display: flex;
    justify-content: space-between;
    color: $black;
    @include font-size(14, $thin, 20);
  }
  @media (max-width: 768px) {
    .item_tax {
      @include font-size(13, $thin, 20);
    }
  }
}

.item_cancel_button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  @include reset-button;
  @include font-size(12, $demibold, 20);
  color: $red;
  transition: all 0.3s ease-out;
  padding-right: 5%;
  &:hover {
    opacity: 0.3;
  }
  @media (max-width: 564px) {
    padding-right: 0%;
  }
  @media (max-width: 570px) {
    @include font-size(12, $demibold, 20);
    margin: 0 5%;
    padding-right: 0%;
  }
}

.item_price_btn_edit {
  @extend .item_price_btn;
  background-color: rgba($font-color-black-secondary, 0.7);
  width: 61px;
  &:hover {
    background-color: rgba($font-color-black-secondary, 0.5);
  }
}
