@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  @media (max-width: 450px) {
    header {
      padding: 17px 15px 24px;
    }
  }
}

.main {
  padding: 0 10% 0;

  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;

  .product_list {
    grid-column: 1;
    grid-row: 1 / 5;
  }

  .summary {
    grid-column: 2;
  }

  .payments {
    grid-column: 2;
  }

  .link_card {
    grid-column: 2;
  }

  @media (max-width: 820px) {
    grid-template-columns: 390px 1fr;
  }

  @media (max-width: 450px) {
    grid-template-columns: 1fr;

    .product_list {
      grid-column: 1;
      grid-row: auto;
    }

    .summary {
      grid-column: 1;
    }

    .payments {
      grid-column: 1;
    }

    .link_card {
      grid-column: 1;
    }
  }

  @media (max-width: 450px) {
    padding: 0 15px 5%;

    header {
      padding: 0 15px 5%;
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include font-size(24, $bold, 40, 1);
  color: rgba(46, 46, 46, 0.25);
  height: calc(100% - 128px);
  svg {
    margin-bottom: 40px;
  }
  @media (max-width: 570px) {
    @include font-size(16, $bold, 40, 1);
  }
}

.loading {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  flex-direction: column;
  align-content: center;
  div {
    display: flex;
    p {
      color: #fff;
      @include font-size(14, $demibold, 60, 1);
      text-align: center;
      margin: 1px;
    }
    @media (max-width: 820px) {
      flex-direction: column;
      p {
        @include font-size(14, $demibold, 30, 1);
      }
    }
  }
}

.modal {
  @include flex-justify-align(center);
  flex-direction: column;
  padding: 40px 60px;

  h3 {
    @include font-size(24, $medium, 30);
    color: $font-color-black-secondary;
    padding-bottom: 20px;
  }

  p {
    @include font-size(16, $regular, 20);
    color: $violet;
    max-width: 440px;
    text-align: center;
    padding-bottom: 40px;
  }

  button {
    @include purple-btn();
    @include font-size(16, $medium, 20);
  }

  @media (max-width: 1024px) {
    p {
      @include font-size(14, $regular, 20);
    }

    button {
      @include purple-btn();
      @include font-size(14, $medium, 20);
      text-transform: uppercase;
    }
  }

  @media (max-width: 450px) {
    padding: 40px 10px;
    h3 {
      @include font-size(16, $demibold, 22);
    }

    p {
      @include font-size(13, $regular, 20);
    }

    button {
      @include font-size(16, $medium, 20);
      text-transform: none;
    }
  }
}
