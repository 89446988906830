@import "styles/vars";
@import "styles/mixins";

$paid_color: #3f505a;
$hover_chart_background: #7233cc;

.graph_wrapper {
  position: absolute;
  width: calc(100% - 42px);
  bottom: 25px;
  line {
    opacity: 0;
  }

  @media (max-width: 450px) {
    width: calc(100% - 56px);
    height: 139px;
    bottom: 0px;
    top: 97px;
  }
}

.tooltip {
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: -151px;
  left: calc(100% - 250px);
  width: 250px;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid rgba(255, 207, 38, 1);
  border-radius: 4px;
  &_month {
    display: flex;
    align-items: center;
    @include font-size(14, $regular, 32);
    color: rgba(105, 106, 114, 1);
  }
  &_container {
    display: flex;
    padding-right: inherit;
    flex-direction: column;
    align-items: flex-start;

    &_value {
      color: rgba(0, 211, 200, 1);
      @include font-size(14, $bold, 18);
    }

    &_name {
      color: $violet;
      @include font-size(10, $medium, 12);
    }
  }

  @media (max-width: 450px) {
    top: 150px;
    width: 100%;
    left: 0;
  }
}
