@import "styles/vars";
@import "styles/mixins";

.social_card {
  padding: 25px 5% 25px 10%;
  margin-right: 5%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  box-shadow: 2px 8px 20px rgba(203, 203, 203, 0.35);
  .wrap_share {
    color: rgba($font-color-black-secondary, 0.3);
    @include font-size(20, $thin, 26);
  }
  .wrap_social {
    display: flex;
    justify-content: space-between;
    .wrap_icon_container {
      color: $purple;
      @include font-size(16, $medium, 24);
      width: 100%;
      @include flex-justify-align(space-between, center);

      a {
        svg {
          width: 32px;
          height: 24px;
          path {
            fill: $purple;
          }
        }
      }
    }
  }

  &_link {
    width: 100%;
    @include flex-justify-align(space-between, center);
    p:nth-child(1) {
      @include font-size(16, $regular, 24);
      color: $violet;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 85%;
    }
    p:nth-child(2) {
      @include font-size(16, $medium, 24);
      color: $purple;
    }
  }
  @media (max-width: 1024px) {
    padding: 25px 5% 25px 25px;
  }
}
