@import "styles/vars";
@import "styles/mixins";

.switch {
  &_wrapper {
    cursor: pointer;
    user-select: none;

    width: 65px;
    height: 26px;

    border-radius: 40px;
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.5);

    position: relative;
  }

  position: absolute;
  top: 3px;
  background-color: rgba(226, 226, 234, 1);
  cursor: pointer;
  @include flex-justify-align(center);
  border-radius: 40px;
  width: 34px;
  height: 20px;
  min-width: 46px;
  left: 4px;
  min-width: unset;
  box-sizing: border-box;
  transition: all 0.3s ease;
  svg {
    height: 10px;
    width: 10px;
    path {
      fill: rgba(0, 0, 0, 1);
    }
  }
}

.switch_active {
  @extend .switch;
  background-color: rgba(255, 199, 38, 1);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
  left: calc(100% - 38px);
  svg {
    height: 100%;
    width: 100%;
    top: 2px;
    path {
      fill: $white;
    }
  }
}
