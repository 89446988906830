@mixin font-size(
  $font-size,
  $font-weight,
  $line-height: normal,
  $letter-spacing: normal
) {
  /* ===== Usage =====
  p {
    @include font-size(12, 700, 18, 12);
    // returns
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5; // 18 / 12
    letter-spacing: 12px;
  }
*/
  font-size: $font-size * 1px;
  font-weight: $font-weight;

  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: $line-height + px;
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: $letter-spacing + px;
  }
}

@mixin reset-button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
}

@mixin reset-ul {
  list-style-type: none;
  padding: 0;
}

@mixin flex-justify-align($justify, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin rounded-box($offsetX, $offsetY) {
  background-color: #fff;
  border-radius: 4px;
  padding: $offsetX;
  margin-top: 1px;
  padding-top: $offsetY;
  padding-bottom: $offsetY;
  margin-bottom: $offsetY;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin rounded-box-list($offsetX, $offsetY) {
  background-color: #fff;
  border-radius: 5px;
  padding: $offsetX;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: $offsetY;
  padding-bottom: $offsetY;
}

@mixin rounded-box-list-second($offsetX, $offsetY) {
  background-color: #fff;
  padding: $offsetX;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: $offsetY;
  padding-bottom: $offsetY;
}

@mixin rounded-box-shadow($offsetX, $offsetY) {
  background: $white;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;
  padding: $offsetX;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: $offsetY;
  padding-bottom: $offsetY;
}

@mixin purple-btn {
  @include reset-button();
  width: 250px;
  height: 60px;
  border-radius: 4px;

  background: $background-button;
  color: $white;
  @include font-size(18, $medium, 20);

  transition: all 0.5s;

  &:not(:disabled):hover {
    opacity: 0.5;
  }

  &:disabled {
    background: rgba(216, 216, 216, 1);
    color: rgba($violet, 0.5);
    opacity: 0.6;
  }
}

@mixin white-btn {
  @include reset-button();
  width: 250px;
  height: 60px;
  padding: 10px;
  border-radius: 4px;

  background-color: $white;
  border: 1px solid $purple;
  border-radius: 4px;
  text-align: center;
  @include flex-justify-align(center);
  color: $purple;
  @include font-size(16, $medium, 20);

  transition: all 0.5s;

  &:not(:disabled):hover {
    opacity: 0.4;
  }

  &:disabled {
    background: rgba(216, 216, 216, 1);
    color: rgba($violet, 0.5);
    opacity: 0.6;
  }
}

@mixin input {
  background: $white;
  border: 1px solid rgba(221, 227, 232, 1);
  border-radius: 3px;
  @include font-size(16, $regular, 24);
  height: 60px;
  max-width: 240px;
  text-align: right;
  padding: 0 10px;
}

@mixin black-close-icon {
  background-color: $font-color-black-secondary;
  width: 24px;
  height: 24px;
  @include flex-justify-align(center);
  border-radius: 50%;
  padding: 4px;
  fill: $white;
  padding: 5px;
}

@mixin triangle($direction: up, $width: 12px, $color: red) {
  width: 0;
  height: 0;
  border: $width solid transparent;

  @if ($direction ==left) {
    border-right-color: $color;
  } @else if($direction ==right) {
    border-left-color: $color;
  } @else if($direction ==down) {
    border-top-color: $color;
  } @else {
    border-bottom-color: $color;
  }
}

@mixin hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
