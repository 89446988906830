@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    color: $font-color-black-secondary;
    @include font-size(20, $bold, 26);
  }

  .table_items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    max-height: 750px;

    @media (max-width: 450px) {
      max-height: max-content;
      overflow-y: auto;
    }
  }
}
