@import "styles/vars";
@import "styles/mixins";

.react_tabs {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;

  &__tab_list {
    margin: 0 0 10px 20px;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
    justify-content: center;
    padding-left: 0px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1024px) {
      justify-content: center;
      margin: 0;
      gap: 35px;
      margin-bottom: 20px;
    }

    @media (max-width: 768px) {
      display: flex;
      margin: 0 0 10px 0;
      justify-content: center;
      gap: 35px;
    }

    @media (max-width: 450px) {
      justify-content: unset;
      margin: 0 0 10px 0;
    }
  }

  &__tab {
    z-index: 1;
    display: inline-block;
    border-bottom: 2px solid transparent;
    list-style: none;
    padding: 0 0 18px;
    cursor: pointer;
    margin-right: 24px;
    border-radius: 1.5px;
    transition: border-bottom-color 0.3s ease;
    color: $font-color-black-secondary;
    white-space: nowrap;
    @include font-size(14, $medium, 21);
    &__selected {
      color: $purple;
      border-bottom-color: $purple;
      border-radius: 0;
    }

    @media (max-width: 1024px) {
      margin: 0;
      padding-bottom: 20px;
      @include font-size(14, $medium, 18);
    }

    @media (max-width: 772px) {
      margin: 0;
      padding-bottom: 20px;
      @include font-size(14, $medium, 18);
    }

    @media (max-width: 450px) {
      padding: 0 0 20px;
      margin-left: 0;
      &:nth-child(1) {
        margin-left: 23px;
      }
    }
  }

  &__tab_panel {
    display: none;
    &__selected {
      svg {
        overflow: visible;
      }
      width: 100%;
      display: block;
      border-radius: 2px;
    }
  }
}
