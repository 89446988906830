@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.modal {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $font-color-black;

  &_show {
    @extend .modal;
    display: block;
  }
  &_hide {
    display: none;
  }
}

.loading {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  flex-direction: column;
  align-content: center;

  div {
    width: 175px;
    height: 175px;
  }

  h2 {
    width: 391px;
    margin-top: 30px;
    overflow: hidden;

    @include font-size(14, $demibold, 24);
    color: rgba(255, 255, 255, 1);
    text-align: center;
    white-space: nowrap;

    animation: extend 5s linear infinite;
  }

  @media (max-width: 450px) {
    width: 100%;
    h2 {
      width: 100%;
    }
  }
}
