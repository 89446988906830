@import "styles/vars";
@import "styles/mixins";

.navbar {
  padding: 0 20px;
  background-color: $font-color-black-secondary;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 120px;
  border: 0.5px solid rgba(151, 151, 151, 0.5);
  &_title {
    @include font-size(24, $demibold, 36);
    color: white;
  }
  &_right_side {
    display: flex;
    align-items: center;
  }
  &_logo {
    width: 79px;
    height: 20px;
  }

  .options_btn {
    @include reset-button;

    margin-left: auto;

    svg {
      width: 20px;
      height: 6px;
    }
  }

  @media (max-width: 772px) {
    height: 60px;
    border: none;
    justify-content: space-between;

    padding: 0 20px;

    &_title {
      @include font-size(24, $bold, 42);
      color: #fff;
    }
    &_title_open {
      @include font-size(14, $medium, 24);
      color: #fff;
    }
  }
}

.hamburger {
  outline: none;
  background: transparent;
  border: none;

  padding: 0;

  margin-right: 20px;

  span {
    display: block;
    width: 22px;
    height: 2px;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 1);
    transition: all 0.3s linear;

    &:last-child {
      margin-bottom: initial;
    }
  }

  &_active {
    span {
      display: block;
      width: 22px;
      height: 2px;
      margin-bottom: 5px;
      background-color: $purple;
      transition: all 0.3s linear;

      &:last-child {
        margin-bottom: initial;
      }
    }
  }

  &_submenu {
    span:nth-child(1) {
      height: 3px;
      width: 12px;
    }

    span:nth-child(2) {
      height: 3px;
    }

    span:nth-child(3) {
      height: 3px;
      width: 12px;
    }
  }
}

.cancel_btn {
  @include reset-button;
  @include font-size(18, $medium);
  @include flex-justify-align(space-between, center);

  margin-left: auto;

  color: rgba(232, 74, 95, 1);

  transition: 0.3s;

  svg {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 772px) {
    @include font-size(12, $medium);

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.change_language {
  width: 130px;
  padding-right: 20px;
  @include font-size(14, $medium, 20);
  @media (max-width: 772px) {
    width: 90px;
    @include font-size(12, $medium, 20);
  }
}
