@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

$font-black: rgba(21, 21, 21, 1);
$font-gray: rgba(115, 115, 115, 1);

.customer_info {
  @include flex-justify-align(space-between, flex-start);
  margin: 40px 0;

  &_cancelled {
    @extend .customer_info;
    opacity: 0.5;
  }

  &_left {
    @include flex-justify-align(flex-start, flex-start);
    flex-direction: column;
    p {
      @include font-size(8, $medium, 10);
      color: $font-gray;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }

    div {
      p {
        @include font-size(10, $regular, 14);
        color: $font-black;
        text-transform: initial;
        padding-bottom: 0;
      }
    }

    @media (max-width: 450px) {
      div {
        @include flex-justify-align(flex-start, flex-start);
        flex-direction: column;
        width: 50%;
      }
    }
  }

  &_right {
    @extend .customer_info_left;
    @include flex-justify-align(flex-end, flex-end);
    flex-direction: column;
    div {
      @include flex-justify-align(flex-end, flex-end);
      flex-direction: column;
    }

    @media (max-width: 450px) {
      div {
        @include flex-justify-align(flex-end, flex-start);
        width: 50%;
      }
    }
  }

  &_name {
    font-weight: $medium !important;
  }

  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 0;
    &_left {
      @include flex-justify-align(space-between);
      flex-direction: row;
      width: 100%;
      p {
        padding-bottom: 0;
      }
    }

    &_right {
      @include flex-justify-align(space-between);
      flex-direction: row;
    }
  }
}
