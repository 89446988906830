@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 20px;

  animation: fade-out 0.3s ease-out;

  &_title {
    @include font-size(16, $medium, 24);
    color: rgba(27, 21, 61, 1);
  }

  &_variations_list {
    display: flex;
    overflow-x: scroll;
    gap: 20px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &_price {
    padding-top: 20px;
    @include font-size(24, $medium, 19);
    color: $purple;
  }

  @media (max-width: 570px) {
    &_title {
      order: 2;
    }

    &_price {
      order: 1;
      padding-top: 0px;
      padding-bottom: 15px;
    }

    &_variations_list {
      order: 3;
      overflow-x: scroll;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}
