@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.footer {
  position: fixed;
  bottom: 0;

  width: 100%;

  padding: 24px 5%;
  background-color: rgba(242, 245, 247, 1);
  display: flex;
  flex-direction: column;
  gap: 40px;

  &_top {
    @include flex-justify-align(space-between, center);
  }

  &_center {
    @include flex-justify-align(space-between, center);
  }

  .year {
    @include font-size(10, $regular, 12);
    color: rgba($font-color-black-secondary, 0.8);
    opacity: 0.6;
  }

  &_shipping {
    p {
      @include font-size(10, $regular, 12);
      color: rgba($font-color-black-secondary, 0.8);
      opacity: 0.6;
      text-transform: uppercase;
    }

    &_link {
      @include font-size(12, $regular, 15);
      text-decoration-line: underline;
      color: $purple;

      &:hover {
        @include font-size(12, $regular, 15);
        text-decoration-line: underline;
        color: $purple;
      }
    }
  }

  &_help {
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 138px;

    p {
      @include font-size(10, $regular, 12);
      color: rgba($font-color-black-secondary, 0.8);
      opacity: 0.6;
    }

    a {
      @include flex-justify-align(flex-start, center);
      gap: 4px;

      @include font-size(12, $medium, 15);
      color: $purple;
    }
  }

  @media (max-width: 1024px) {
    padding: 17px 5% 16px;
    gap: 30px;
  }

  @media (max-width: 450px) {
    position: initial;

    &_top {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
    &_center {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .year {
      @include flex-justify-align(center, center);
    }
  }
}
