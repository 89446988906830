@import "styles/vars";
@import "styles/mixins";

.modal,
.modal * {
  font-family: "Visuelt Pro", "Poppins", sans-serif;
}

.modal {
  z-index: 999;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 28px 20px;
  background-color: $light-red;

  @include flex-justify-align(center);
  transition: all 1s ease-in-out;
  transition: 0.5s;

  .wrapper {
    @include flex-justify-align(space-between);
    width: 100%;

    &_active {
      @extend .wrapper;
    }
  }

  p {
    @include font-size(14, $medium, 22);
    color: $white;
    max-width: 451px;
  }

  .close {
    svg {
      width: 16px;
      height: 16px;
      color: $white;
    }
  }

  &_show {
    @extend .modal;
    opacity: 1;
  }

  &_hide {
    @extend .modal;
    pointer-events: none;
    opacity: 0;
  }

  @media (max-width: 450px) {
    .wrapper {
      @include flex-justify-align(space-between);
      flex-direction: column-reverse;
      gap: 20px;
      .close {
        align-self: flex-end;
      }
    }
  }
}

.modal_two {
  @extend .modal;

  &_show {
    @extend .modal;
    padding: 28px 20px 26px;
    background: $background-button;
    bottom: initial;
    top: 0;
    opacity: 1;

    p {
      @include font-size(18, $bold, 22);
    }
  }

  &_hide {
    @extend .modal;
    pointer-events: none;
    opacity: 0;
  }
}

.wrapper_two {
  @include flex-justify-align(space-between);
  width: 100%;

  .subtitle_btn {
    @include reset-button;

    color: $white;
    text-decoration: underline;
    @include font-size(14, $medium, 26);
  }

  &_active {
    @extend .wrapper_two;
  }
}
