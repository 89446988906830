@import "styles/vars";
@import "styles/mixins";

.more_btn {
  @include reset-button;
  @include font-size(14, $bold, 20);
  @include flex-justify-align(center);
  padding-top: 2px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  background: $background-button;
  color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  width: 90px;
  height: 40px;
  text-align: center;
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
}
