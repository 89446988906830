@import "styles/vars";
@import "styles/mixins";

.sidebar {
  width: 400px;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 100px;
  flex-direction: column;
  display: flex;
  padding: 15px 30px;
  background-color: rgba(247, 248, 253, 1);

  &_title {
    padding: 10px 0;
    border-bottom: 1px solid $divider-color;
    margin-bottom: 20px;

    @include font-size(18, $bold, 36, 1);
    color: $font-color-black-secondary;
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 17px;

    &_item {
      @include flex-justify-align(space-between, center);

      &_name {
        @include font-size(16, $regular, 24);
        color: $violet;
      }

      &_value {
        @include font-size(16, $regular, 24);
        color: $violet;
      }
    }
  }

  &_button {
    @include purple-btn();
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 280px;
    padding: 27px 12px;
  }

  @media (max-width: 450px) {
    position: initial;
    width: calc(100% - 40px);
    height: 100%;
    padding: 20px 0px;
    margin: 0 20px;
    border-top: 1px solid $divider-color;

    &_title {
      display: none;
      @include font-size(16, $bold, 40, 0.66);
      padding: 0px;
    }

    &_items {
      gap: 20px;
      height: 100%;
    }
    .price {
      display: none;
    }

    &_button {
      width: 250px;
      align-self: center;
      justify-content: center;
    }

    &_list {
      &_item {
        &_name {
          @include font-size(16, $regular, 24);
          color: $violet;
        }

        &_value {
          @include font-size(16, $thin, 24);
          color: $violet;
        }
      }
    }
  }
}

.sidebar_wrapper {
  background-color: rgba(247, 248, 253, 1);
  padding: 27px 33px 16px 12px;
  @include flex-justify-align(space-between, stretch);
  flex-direction: column;
}

.accordion_sidebar {
  background-color: rgba(247, 248, 253, 1);
  padding: 0;
  text-transform: capitalize;
}
