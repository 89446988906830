@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.profile_card {
  @include flex-justify-align(space-between, center);
  padding: 34px 100px 30px;

  &_left {
    display: flex;
    flex-direction: column;

    &_name {
      @include font-size(16, $medium, 20);
      color: $font-color-black-secondary;
      margin-bottom: 5px;
    }

    &_address {
      @include font-size(14, $regular, 22);
      color: $violet;
    }

    &_phone {
      @include font-size(14, $regular, 22);
      color: $violet;
    }

    &_email {
      @include font-size(14, $regular, 22);
      color: $violet;
    }
  }

  &_right {
    width: 90px;
    height: 40px;
    background-color: $font-color-black-secondary;
    @include flex-justify-align(center, center);

    @include font-size(14, $bold, 20);
    border-radius: 4px;
    color: $white;
  }

  @media (max-width: 1024px) {
    padding: 25px 12px 30px 20px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 23px 0px 20px;
    border-bottom: 1px solid #e4eaee;
    margin: 0 9px;
  }
}
