@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.container {
  background-color: #fff;
  position: fixed;
  display: flex;
  flex-direction: column;
  animation: fade-out 0.6s ease-out;
  top: 50%;
  left: 50%;
  width: 50%;
  min-height: 70%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  @media (max-width: 1024px) {
    width: 800px;
    min-height: 700px;
  }

  @media (max-width: 772px) {
    width: 720px;
    min-height: 700px;
  }

  @media (max-width: 570px) {
    width: calc(100% + 13px);
    height: 100%;
    padding: 1px;
    min-height: auto;
    top: 50%;
    border-radius: 0px;
  }
}

.title {
  &_wrapper {
    @include flex-justify-align(space-between, center);
    @include font-size(14, $medium, 20);
    padding: 20px 20px 30px;
  }

  @include font-size(20, $bold, 24, -0.24);
  color: #1b153d;

  @media (max-width: 450px) {
    &_wrapper {
      flex-direction: column-reverse;
      @include flex-justify-align(flex-start, flex-start);
      gap: 10px;
    }

    color: rgba(25, 28, 35, 0.65);
  }
}

.product_stock {
  &_count {
    padding: 2px 8px;

    background: rgba(240, 99, 90, 0.2);
    border-radius: 10px;

    @include font-size(8, $bold, 13, 0.007);
    color: #f0635a;
  }
}

.add_to_bag {
  @include purple-btn;
  @include font-size(16, $medium, 20);
  align-self: center;
  height: 45px;
  width: calc(100% - 40px);

  &:disabled {
    background: $background-button;
    color: $white;
    opacity: 0.2;
  }
}