@import "styles/vars";
@import "styles/mixins";

.item_description {
  @include flex-justify-align(space-between);
  gap: 1em;
  h3 {
    color: $black;
    @include font-size(16, $regular, 24);
    flex: 1;
  }
}

.variant {
  color: rgba($font-color-black-secondary, 0.7);
  @include font-size(12, $regular, 20);
}

.item_quantity {
  width: 46px;
  opacity: 0.6;
  height: 36px;
  align-self: flex-start;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 100px;
  background-color: $grey;
  color: rgba($font-color-black-secondary, 0.7);
  text-align: center;
  @include font-size(16, $bold, 24);
  @include flex-justify-align(center);
}

.item_price {
  @include flex-justify-align(space-between);
  span:first-child {
    color: $font-color-black-secondary;
    @include font-size(16, $bold, 20, 0.69);
  }
}

.item_avatar {
  position: relative;
  @include flex-justify-align(center);

  width: 84px;
  height: 84px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid $purple;
  span {
    @include font-size(28, $regular, 35, -0.5);
    color: $purple;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_btn {
    @include reset-button;
    @include font-size(12, $regular, 11);

    width: 92px;
    height: 24px;

    color: #fff;
    position: absolute;
    bottom: -10px;

    background-color: rgba(91, 107, 130, 1);
    border-radius: 12px;

    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
  @media (max-width: 768px) {
    width: 40px;
    span {
      @include font-size(20, $regular, 35, -0.5);
    }
    height: 40px;
    &_btn {
      width: 78px;
      height: 78px;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.item_taxes {
  .item_tax {
    @include flex-justify-align(space-between, flex-start);
    color: $black;
    @include font-size(16, $thin, 20);
    &_amount {
      @include font-size(14, $thin, 20);
    }
  }
}

.item_cancel_button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  @include reset-button;
  @include font-size(12, $demibold, 20);
  color: $red;
  transition: all 0.3s ease-out;
  padding-right: 5%;
  &:hover {
    opacity: 0.3;
  }
  @media (max-width: 564px) {
    padding-right: 0%;
  }
  @media (max-width: 570px) {
    @include font-size(12, $demibold, 20);
    margin: 0 5%;
    padding-right: 0%;
  }
}

.pending_vendor {
  color: rgba(46, 46, 46, 0.5);
  @include font-size(16, $regular, 21, 0.68);
}
