@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.menu {
  width: 100%;
  &_button {
    @include reset-button;
    font-family: "Visuelt Pro";
    @include font-size(30, $bold, 42);
    color: white;
    padding-bottom: 18px;
    width: 100%;
    border-bottom: 1px solid rgba(white, 0.6);
    text-align: left;
    margin-bottom: 20px;
  }
  &_accordion {
    background-color: transparent;
    margin-bottom: 20px;
    color: white;
  }
}

.accordion_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;

  h3 {
    @include font-size(30, $bold, 42);
    color: white;
  }
  svg {
    path {
      fill: white;
    }
  }
}

.accordion_content {
  @include font-size(18, $regular, 32);
  font-family: "Visuelt Pro";
  color: white;
  width: 100%;
  max-height: 0px;

  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.open {
  width: 100%;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icon {
  width: 12px;
  height: 6px;
  transition: 0.5s;
  color: $font-color-purple;
}

.icon_open {
  @extend .icon;
  transform: rotate(180deg);
}

.lang_icon {
  transform: rotate(270deg);
  margin-left: 20px;
  width: 14px;
  height: 6px;
  path {
    fill: white;
  }
}

.lang {
  display: flex;
  align-items: center;
}
