@import "styles/vars";
@import "styles/mixins";

.overview {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_activity {
    box-shadow: 0px 4px 16px 0px rgba(204, 188, 252, 0.15);
    padding: 62px 99px 62px 105px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    div {
      display: flex;
      justify-content: space-between;
      p:first-child {
        color: $violet;
        @include font-size(16, $regular, 24);
      }
      .value {
        color: $violet;
        @include font-size(16, $thin, 24);
        span {
          margin-left: 2px;
          @include font-size(16, $regular, 24);
        }
      }
    }
  }

  @media (max-width: 1150px) {
    &_activity {
      padding: 29px 12px 29px 15px;
      margin-bottom: 6px;
      margin-left: 10px;
    }
  }

  @media (max-width: 450px) {
    padding: 0px;
    &_activity {
      padding: 31px 22px 36px;
      margin: 0px;
      div {
        p:first-child {
          color: $violet;
          @include font-size(16, $regular, 18);
        }
        p:last-child {
          color: $violet;
          @include font-size(16, $thin, 24);
        }
      }
    }
  }
}

.send_reminder_btn {
  @include reset-button();
  color: $purple;
  @include font-size(16, $thin, 24);
  margin-left: 4px;

  transition: all 0.5s;

  &:not(:disabled):hover {
    opacity: 0.5;
  }

  &:disabled {
    background: rgba(216, 216, 216, 1);
    color: rgba($violet, 0.5);
    opacity: 0.6;
  }
}

.view_btn {
  @include reset-button();
  color: $purple;
  @include font-size(16, $thin, 24);
  margin-left: 4px;

  transition: all 0.5s;

  &:not(:disabled):hover {
    opacity: 0.5;
  }

  &:disabled {
    background: rgba(216, 216, 216, 1);
    color: rgba($violet, 0.5);
    opacity: 0.6;
  }
}
