@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/animations';

.payments__accepted {
    &_container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
            @include font-size(10, $regular, 12);
            color: rgba($font-color-black-secondary, 0.8);
            opacity: 0.6;
        }
    }

    &_list {
        @include flex-justify-align(flex-start, center);
        gap: 5px;
        flex-wrap: wrap;
        max-width: 181px;
        opacity: 0.4;
    }
}