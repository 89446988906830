@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.search_wrapper {
  position: relative;
  z-index: 2;
}

.search {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  background-color: #fff;
  padding: 0px 76px;
  @include flex-justify-align(stretch, center);
  gap: 20px;

  margin-bottom: 66px;

  @media (max-width: 768px) {
    margin-bottom: 36px;
    padding: 0px 20px;
    input {
      @include font-size(16, $regular, 32);
    }
  }

  @media (max-width: 450px) {
    margin-bottom: 14px;
    padding: 0px 12px;

    input {
      padding: 0px 10px;
      @include font-size(14, $regular, 32);
    }
  }
}

.search_active {
  @extend .search;
  @include flex-justify-align(center, center);

  .search_input_wrapper {
    padding: 27px 0px 27px 26px;

    .search_icon {
      top: 35%;
      left: 0px;
      path {
        fill: $purple;
      }
    }

    input {
      border: none;
      width: 100%;
      z-index: 1;
      @include font-size(16, $regular, 20);
    }

    .close_icon {
      width: 20px;
      height: 20px;
      padding: 4px;
      color: rgba(203, 198, 215, 1);

      transition: all 0.5s;

      &_active {
        @extend .close_icon;
        cursor: pointer;
        color: $purple;
      }
    }
  }

  @media (max-width: 450px) {
    .search_input_wrapper {
      padding: 12px 0px 14px 20px;

      &_active {
        padding: 12px 0px 14px 0px;
      }

      .search_icon {
        top: 25%;
      }
    }
  }
}

.search_active_tags {
  @extend .search;
  @include flex-justify-align(stretch, center);
  gap: 20px;

  .search_input_wrapper {
    .search_icon {
      position: absolute;
      top: 27px;
      left: 0;
    }
  }

  @media (max-width: 450px) {
    .search_input_wrapper {
      .search_icon {
        top: 25%;
      }
    }
  }
}

.select_tag {
  width: auto;
  height: auto;
  @include flex-justify-align(space-between, center);

  background: $background-button;
  border-radius: 4px;
  padding: 10px;
  gap: 7px;

  @include font-size(14, $medium, 20);
  color: $white;
  white-space: nowrap;

  &_close_icon {
    cursor: pointer;
    width: 11px;
    height: 11px;
    path {
      fill: rgba(203, 198, 215, 1);
    }
  }

  @media (max-width: 450px) {
    max-width: 160px;
    &_wrapper {
      position: absolute;
      top: 46px;
      left: 0;
      width: 100%;
      height: 60px;

      box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
      background-color: rgba(225, 225, 225, 1);

      @include flex-justify-align(flex-start, center);
      padding-left: 12px;
    }
  }
}

.search_input_wrapper {
  @include flex-justify-align(space-between, center);
  position: relative;
  padding: 27px 98px 27px 45px;
  width: 100%;

  .search_icon {
    position: absolute;
    top: 27px;
    right: 0;
    left: auto;
  }

  input {
    border: none;
    width: 100%;
    z-index: 1;
    @include font-size(16, $regular, 20);

    &:focus {
      outline: none;
    }

    &:not(:placeholder-shown) + .search_icon {
      display: none;
      padding: 0;
    }
  }

  .close_icon {
    top: 27px;
    right: 77px;
  }

  &_active {
    @extend .search_input_wrapper;
  }

  @media (max-width: 768px) {
    padding: 27px 98px 27px 0px;
  }

  @media (max-width: 450px) {
    padding: 12px 20px 14px 14px;

    input {
      padding: 0;
    }

    .search_icon {
      top: 15px;
    }
  }
}

.search_result {
  z-index: 2;
  top: 75px;
  width: 100%;
  position: absolute;
  padding: 20px 76px;
  background: #f2f2f2;
  transition: all 0.5s;
  visibility: hidden;
  overflow-y: hidden;
  opacity: 0;

  &_active {
    @extend .search_result;
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 450px) {
    top: 106px;
    padding: 10px 0px;
  }
}

.loading_wrapper {
  @include flex-justify-align(flex-start, center);
  gap: 20px;

  span {
    @include font-size(16, $regular, 20);
    color: rgba(140, 136, 150, 1);
  }

  div {
    height: 20px;
    width: 20px;
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }

  @media (max-width: 450px) {
    margin-left: 10px;
  }
}

.no_items {
  @include font-size(14, $medium, 18);
  color: rgba(25, 28, 35, 0.6);
  margin-left: 10px;
}
