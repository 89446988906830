@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.share__shop {
  &_container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
      @include font-size(10, $regular, 12);
      color: rgba($font-color-black-secondary, 0.8);
      opacity: 0.6;
    }
  }

  @include flex-justify-align(flex-start, center);
  gap: 4px;
  flex-wrap: wrap;

  &_text {
    display: flex;
    flex-direction: column;
    p {
      @include font-size(10, $regular, 12);
      color: rgba($font-color-black-secondary, 0.8);
      opacity: 0.6;
    }

    .share__shop_btn {
      @include reset-button();
      @include font-size(12, $regular, 15);
      text-decoration-line: underline;
      color: $purple;
    }
  }
}
