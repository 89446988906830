@import "styles/vars";
@import "styles/mixins";

.avatar {
  @include flex-justify-align(center, center);
  height: 80px;
  width: 80px;
  overflow: hidden;

  @include font-size(50, $extra-bold, 80);
  color: rgba($violet, 0.6);
  margin-bottom: 8px;
  border-radius: 4px;

  @media (max-width: 570px) {
    height: 70px;
    @include font-size(40, $extra-bold, 120);
  }
}

.img {
  picture {
    display: block;

    width: 80px;
    height: 80px;

    background-color: $white;
    border-radius: 4px;

    overflow: hidden;
    margin-bottom: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 570px) {
    picture {
      height: 70px;
    }
  }
}
