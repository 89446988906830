@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.summary {
  @include flex-justify-align(space-between, flex-start);

  @media (max-width: 450px) {
    @include flex-justify-align(space-between, flex-start);
    flex-direction: column;
    gap: 6px;
  }
}

.summary_status {
  padding: 3px 6px 2px 5px;
  margin-left: -5px;

  background-color: rgba(244, 247, 254, 1);
  border-radius: 4px;

  @include font-size(12, $medium, 15);
  letter-spacing: -0.02em;
  color: $font-color-black-secondary;

  &_active {
    @extend .summary_status;
    color: $purple;
  }

  &_completed {
    @extend .summary_status;
    color: rgba($font-color-black-secondary, 0.5);
  }
}

.summary_date {
  @include font-size(12, $medium, 15);
  color: rgba(0, 0, 0, 1);
}

.right {
  @include flex-justify-align(space-between, flex-end);
  flex-direction: column;
  gap: 6px;

  @media (max-width: 768px) {
    @include flex-justify-align(space-between, flex-end);
  }

  @media (max-width: 450px) {
    @include flex-justify-align(space-between, flex-start);
    gap: 0px;
  }
}

.summary_payment_due {
  span {
    @include font-size(12, $regular, 15);
    color: rgba(0, 0, 0, 1);

    &:first-child {
      @extend .summary_date;
    }
  }
}
