@import "styles/vars";
@import "styles/mixins";

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table_header {
  @include font-size(10, $demibold, 20);
  color: $violet;
  color: $font-main-color;
  display: grid;
  margin-top: 20px;
  padding-left: 20px;
  grid-template-columns: 15.8% 15.8% 15.8% 15.8% 15.8% auto;
  grid-gap: 10px;
  border-bottom: 1px solid rgb(242, 242, 242);
}

.table_content {
  display: flex;
  flex-direction: column;
  &_item {
    @include reset-button;
    @include rounded-box-list-second(1.5rem, 16px);
    border-bottom: 1px solid rgb(242, 242, 242);
    display: grid;
    grid-template-columns: 15.8% 15.8% 15.8% 15.8% 15.8% auto;
    grid-gap: 10px;
    color: $violet;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: $purple;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      text-align: center;
    }
    @media (max-width: 772px) {
      grid-template-columns: 21% 16% 17% 10% 13% auto;
      @include font-size(14, $regular, 18);
    }
  }
}

.item_mb {
  @include reset-button;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 16px $shadow;
  @include rounded-box-list-second(10px, 14px);
  margin: 0px 10px;
  gap: 10px;
  &_header {
    width: 100%;
    @include flex-justify-align(space-between);
    &_id {
      @include flex-justify-align(space-between, flex-start);
      flex-direction: column;
      &_label {
        @include font-size(12, $regular, 18);
        color: rgba($violet, 0.5);
      }
      &_value {
        @include font-size(12, $thin, 18);
        color: $violet;
      }
    }
    &_date {
      @include flex-justify-align(space-between, flex-end);
      flex-direction: column;
      &_label {
        @include font-size(12, $regular, 18);
        color: rgba($violet, 0.5);
      }
      &_value {
        @include font-size(12, $thin, 18);
        color: $violet;
      }
    }
  }

  &_name {
    width: 100%;
    @include flex-justify-align(space-between, flex-start);
    flex-direction: column;
    color: $violet;
    &_name {
      @include font-size(14, $medium, 18);
    }
    &_value {
      @include font-size(14, $regular, 18);
    }
  }
  &_value {
    @include font-size(12, $regular, 20);
    color: $violet;
  }
  &_bottom {
    width: 100%;
    @include flex-justify-align(space-between);
    @include font-size(12, $medium, 18);
    color: rgba($violet, 0.5);
  }
}
