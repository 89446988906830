@import 'styles/vars';
@import 'styles/mixins';

.root {
  @include reset-button;
  @include font-size(18, $medium);
  @include flex-justify-align(initial, center);
  color: rgba(65, 29, 117, 1);

  transition: 0.3s;

  &_icon {
    margin-right: 5px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 772px) {
    @include font-size(14, $medium);

    &_icon {
      margin-right: 20px;
    }
  }
}
