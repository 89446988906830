@import "styles/vars";
@import "styles/mixins";

.card {
  @include rounded-box-shadow(12px, 35px);
  @include flex-justify-align(center, flex-start);
  flex-direction: column;
  padding-bottom: 44px;
  gap: 11px;
  max-height: 250px;

  width: 250px;
  @media (max-width: 1024px) {
    width: 220px;
    height: 250px;
  }

  @media (max-width: 768px) {
    width: 180px;
    height: 250px;
  }

  @media (max-width: 450px) {
    width: 140px;
    height: 200px;
    padding: 33px 2px 20px;
  }
}

.title {
  align-self: center;

  color: $purple;
  text-transform: uppercase;
  @include font-size(12, $medium, 20, 1);
}

.progress_list {
  @include flex-justify-align(flex-start, stretch);
  gap: 12px;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;

  &_item {
    display: grid;
    grid-template-columns: repeat(3, minmax(30px, 1fr));
    gap: 10px;
    align-items: center;
    text-align: center;

    .progress_bar {
      position: relative;
      height: 6px;
      border-radius: 4.75px;
      background: transparent;
      box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
      span {
        display: block;
        position: relative;
        height: 100%;
        border-radius: 4.75px;

        transition: all 1.5s;

        background-color: $purple;
      }
    }

    @media (max-width: 1024px) {
      text-align: left;
      grid-template-columns: 0.5fr 0.7fr 0.5fr;
    }
  }
}

.title_progress {
  margin-top: 4px;

  @include font-size(12, $regular, 18);
  color: $violet;

  text-align: right;
  @include text-truncate();

  @media (max-width: 1024px) {
    @include font-size(12, $regular, 15);
  }
}

.percent {
  margin-top: 4px;
  @include font-size(14, $regular, 18);
  color: $font-color-black-secondary;

  @media (max-width: 1024px) {
    @include font-size(12, $regular, 15);
  }
}

.nodata {
  margin-top: 40%;
  align-self: center;
  @include font-size(10, $regular, 20);
  color: rgba(203, 198, 215, 0.5);
}
