@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  height: 100%;
}

.content {
  padding: 0px 20px;
}

.header {
  padding: 38px 28px 49px;

  svg:nth-child(1) {
    @include black-close-icon;
  }

  svg:nth-child(2) {
    display: none;
  }

  @media (max-width: 450px) {
    padding: 21px 19px 24px;

    @include flex-justify-align(flex-start);

    svg:nth-child(1) {
      display: none;
    }

    svg:nth-child(2) {
      display: block;

      path {
        fill: rgba(0, 0, 0, 1);
      }
    }
  }
}

.title {
  @include font-size(38, $bold, 48);
  color: $font-color-black-secondary;
  padding: 0 20px;
  margin-bottom: 48px;

  @media (max-width: 450px) {
    @include font-size(20, $bold, 42);
    margin-bottom: 20px;
  }
}

.subtitle {
  @include font-size(18, $bold, 23);
  color: rgba($font-color-black-secondary, 0.65);
  margin-bottom: 20px;

  @media (max-width: 450px) {
    @include font-size(16, $regular, 20);
  }
}

.input {
  @include input();
  max-width: 100%;
  width: 280px;
  height: 60px;
  text-align: left;
  padding: 20px 60px 20px 20px;
  outline: none;

  @include font-size(16, $regular, 21);
  color: $font-color-black-secondary;

  &:focus {
    border-color: $purple;
  }

  &::placeholder {
    color: rgba($font-color-black-secondary, 0.4);
  }

  &:disabled {
    opacity: 0.2;
  }

  @media (max-width: 450px) {
    width: 100%;
    min-width: 100%;
  }
}

.footer {
  padding: 28px 20px 26px;

  background-color: #efefef;

  width: 100%;
  position: sticky;
  @include flex-justify-align(space-between);

  &_left {
    display: flex;
    align-items: center;
    gap: 7px;

    p {
      max-width: 338px;
      margin-left: 7px;
      @include font-size(12, $regular, 15);
      color: rgba($font-color-black-secondary, 0.65);
    }

    div {
      display: flex;
      flex-direction: column;

      img {
        width: 42.22px;
        height: 20px;
      }

      span {
        @include font-size(11, $regular, 16);
        color: $font-color-black-secondary;
        max-width: 338px;
      }
    }
  }

  &:only-child {
    flex-grow: 10;
  }

  button:nth-child(1) {
    height: 45px;
    width: 152px;

    padding: 10px;

    background-color: $white;
    border: 1px solid $purple;
    border-radius: 4px;
    text-align: center;
    @include flex-justify-align(center);
    color: $purple;
    @include font-size(16, $medium, 20);

    transition: all 0.5s;

    &:hover {
      opacity: 0.4;
    }
  }

  button:nth-child(2) {
    @include reset-button;
    @include purple-btn;
    @include font-size(16, $medium, 20);
    width: 210px;
    height: 45px;

    &:disabled {
      color: $white;
      background: linear-gradient(55.52deg, #7233cc -5.2%, #6b59ed 65.07%);
      opacity: 0.2;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column-reverse;
    background-color: transparent;
    gap: 20px;

    &_left {
      flex-direction: column;
      align-items: flex-start;
    }

    button {
      width: 100%;

      &:nth-child(1),
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
}

.inputs {
  @include flex-justify-align(space-between, stretch);
  flex-direction: column;
  gap: 10px;

  border-bottom: 1px solid $divider-color;
  margin-bottom: 30px;
  padding-bottom: 26px;
}

.form {
  height: 79%;

  @include flex-justify-align(space-between, stretch);
  flex-direction: column;

  &_hide {
    display: none;
  }
}

.fullName {
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 15px;
  }
}

.email_and_phone {
  position: relative;
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 15px;
  }
}

.address {
  width: 240px;

  @media (max-width: 450px) {
    width: 100%;
  }
}

.sign_in {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &_title {
    @include font-size(16, $medium, 24);
    color: rgba($font-color-black-secondary, 0.56);
  }

  &_subtitle {
    @include font-size(14, $bold, 24);
    color: rgba($font-color-black-secondary, 0.56);

    button {
      @include reset-button();
      @include font-size(14, $bold, 24);
      color: $purple;
    }
  }
}

.input_wrapper {
  position: relative;
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 15px;
  }
}