@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  border-bottom: 1px solid rgba(242, 242, 242, 1);
  background-color: rgba(255, 255, 255, 1);
  padding: 30px 5%;
  width: 100%;
  z-index: 2;
  &_logo {
    @include flex-justify-align(center, center);
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
    line-height: 1.25;
    border-radius: 100%;
    text-align: center;
    background-color: $black;
    color: $white;
    font-size: x-large;
    img {
      width: 44px;
      height: 44px;
      object-fit: cover;
    }
  }
  @media (max-width: 1024px) {
    padding: 30px 50px 30px 20px;
  }
  @media (max-width: 450px) {
    border: none;
  }
}

.transaction {
  height: 100vh;
  @media (max-width: 450px) {
    padding: 0px 10px 22px;
    height: 100%;
  }
}

.closeIcon {
  width: 40px;
  height: 40px;
}

.wrapper {
  display: flex;
  background-color: $white;
  height: 100%;
  padding-top: 100px;
  @media (max-width: 450px) {
    height: 100%;
    flex-direction: column;
  }
}

.content {
  padding: 34px 0px;
  flex: 1;
  @include flex-justify-align(space-between, stretch);
  flex-direction: column;
  gap: 50px;
  overflow: auto;
  @media (max-width: 1024px) {
    gap: 30px;
    justify-content: flex-start;
  }
}

.accordion_sidebar {
  background-color: rgba(247, 248, 253, 1);
  padding: 27px 22px 0px;
}

.notification {
  .content {
    .header {
      @include font-size(24, $bold, 40, 1);
    }
  }
}
