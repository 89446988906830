@import "styles/vars";
@import "styles/mixins";

.header {
  background-color: $white;
  display: flex;
  flex-direction: column;
}


.hamburger {
  outline: none;
  background: transparent;
  border: none;

  padding: 0;

  margin-right: 20px;

  span {
    display: block;
    width: 22px;
    height: 2px;
    margin-bottom: 5px;
    background-color: $purple;
    transition: all 0.3s linear;

    &:last-child {
      margin-bottom: initial;
    }
  }

  &_active {
    span:nth-child(1) {
      transform: translateY(7px) rotate(45deg);
      height: 3px;
    }

    span:nth-child(2) {
      opacity: 0;
      visibility: hidden;
    }

    span:nth-child(3) {
      height: 3px;
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

.secure_payment {
  display: flex;
  align-items: center;

  p {
    @include font-size(12, $demibold, 20);
    color: $violet;
  }

  svg {
    margin-right: 8px;
  }
}

.navbar {
  display: flex;
  list-style: none;
  justify-content: space-between;
  width: 30%;

  a {
    transition: all 0.4s ease-out;
    color: $violet;
    @include font-size(12, $demibold, 20);

    &:hover {
      color: $violet;
      opacity: 0.7;
    }
  }
}

.nav_bar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 50px 5%;
  background-color: $white;

  @media (max-width: 768px) {
    padding: 20px 0px 50px 9%;
  }

  @media (max-width: 765px) {
    padding: 20px 0px 20px 2%;
  }

  @media (max-width: 450px) {
    .logo {
      display: none;
    }

    padding: 0;
    padding-top: 14px;
  }
}

.search {
  position: relative;
  display: block;
  text-align: center;
  z-index: 1;

  svg {
    position: absolute;
    top: 10px;
    left: calc(100% - 40px);
    font-weight: bold;
    font-style: normal;
  }

  input {
    border: none;
    border: 1px solid #d6d6d6;
    background: rgba(243, 244, 247, 0.5);
    padding: 4px 20px;
    line-height: 30px;
    padding-left: 30px;
    border-radius: 4px;
    width: 100%;
    z-index: 1;

    &:focus {
      outline-color: #d6d6d6;
    }
  }

  @media (max-width: 772px) {
    position: relative;
    background-color: #fff;
    padding-top: 20px;
    width: 100%;
    top: 60px;

    svg {
      position: absolute;
      top: 40%;
      left: 90%;
      width: 16px;
      height: 16px;
      font-weight: bold;
      font-style: normal;
    }

    input {
      border: none;
      line-height: 30px;
      padding: 0;
      padding-left: 30px;
      border-radius: 4px;
      box-shadow: none;
      width: 100%;
      @include font-size(12, $regular, 40);
      z-index: 1;

      &:focus {
        outline-color: #d6d6d6;
      }
    }

    margin: 0 0 20px;
  }
}

.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5% 30px;

  @media (max-width: 768px) {
    padding: 0 9% 30px;
  }

  @media (max-width: 765px) {
    padding: 0 2% 20px;
  }

  @media (max-width: 450px) {
    position: fixed;
    background-color: $white;
    width: 100%;
    top: 0;
    z-index: 3;
    padding: 10px 24px 30px 2%;
  }
}

.hamburger {
  outline: none;
  background: transparent;
  border: none;

  padding: 0;

  margin-right: 20px;

  span {
    display: block;
    width: 22px;
    height: 2px;
    gap: 5px;
    background-color: $font-color-black-secondary;
    transition: all 0.3s linear;

    &:nth-child(2) {
      width: 12px;
    }
  }

  &_active {
    span:nth-child(1) {
      transform: translateY(7px) rotate(45deg);
      height: 3px;
    }

    span:nth-child(2) {
      opacity: 0;
      visibility: hidden;
    }

    span:nth-child(3) {
      height: 3px;
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}