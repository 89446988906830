@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.bottom {
  display: flex;
  position: absolute;
  justify-content: space-between;
  bottom: 0;
  padding: 30px 20px;
  width: 100%;
  left: 0px;
  background-color: $font-color-black-secondary;
  border-top: 1px solid rgba(228, 234, 238, 0.2);
  div:first-child {
    @include font-size(14, $demibold, 20);
    color: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
    svg {
      margin-top: 2px;
      width: 40px;
      height: 22px;
    }
  }
  div:last-child {
    margin-top: 4px;
    @include font-size(12, $regular, 16);
    color: #fff;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
}
