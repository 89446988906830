@import "styles/vars";
@import "styles/mixins";

.btn {
  padding: 20px;

  width: 184px;
  height: 45px;

  @include flex-justify-align(center);

  transition: all 0.5s;
  outline: none;
  border: none;

  &:hover {
    opacity: 0.3;
  }

  &:disabled {
    opacity: 0.5;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}

.modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  &_show {
    @extend .modal;
    display: block;
  }
  &_hide {
    display: none;
  }
}

.wrapper {
  background-color: #fff;
  overflow: hidden;

  animation: fade-out 0.3s ease-out;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 79%;
  height: max-content;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 40px;
  padding-bottom: 0px;
  @media (max-width: 1024px) {
    width: 79%;
    height: max-content;
  }
  @media (max-width: 768px) {
    width: 94%;
    height: max-content;
  }

  @media (max-width: 570px) {
    width: 100%;
    height: 100.1%;
    padding: 0px;
    top: 49.9%;
    border-radius: 0px;
  }
}
.close_icon {
  background-color: #6b59ed;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  margin-bottom: 27px;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
}

.content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-content: center;
  height: calc(100% - 100px);
  &_header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    h2 {
      @include font-size(24, $bold, 40);
      color: $font-color-black-secondary;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(229, 229, 229, 1);
      margin-bottom: 40px;
    }
    @media (max-width: 768px) {
      flex-direction: row-reverse;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      h2 {
        @include font-size(14, $bold, 20);
        color: $font-color-black-secondary;
        padding-bottom: 0px;
        border-bottom: none;
        margin-bottom: 0px;
      }
      padding: 20px;
      border-bottom: 1px solid rgba(229, 229, 229, 1);
      margin-bottom: 40px;
    }
  }
  input {
    @include font-size(14, $regular, 24);
    padding: 20px;
    border: 1px solid rgba(221, 227, 232, 1);
    border-radius: 4px;
    &:focus {
      border-color: $purple;
    }
  }
}

.paypal {
  @extend .btn;

  @include flex-justify-align(center);
  background-color: $white;
  border: 1px solid rgba(0, 60, 139, 1);
  border-radius: 4px;

  img {
    width: 75%;
  }
}

.error {
  margin-top: 5px;
  @include font-size(14, $thin, 18);
  color: #bf1650;

  &::before {
    color: #bf1650;
    display: inline;
    content: "⚠ ";
  }
}
