@import "styles/vars";
@import "styles/mixins";

.table_content {
  display: flex;
  flex-direction: column;

  &_item {
    display: grid;
    grid-template-columns: repeat(7, minmax(100px, 1fr));
    align-items: start;
    gap: 15px;

    background-color: $white;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(226, 209, 249, 0.3);

    @include font-size(16, $regular, 20);
    color: $violet;

    margin-bottom: 5px;

    text-align: left;

    &:hover {
      color: $purple;
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(7, minmax(100px, 1fr));
      gap: 10px;
    }

    @media (max-width: 768px) {
      @include font-size(14, $regular, 20);
      grid-template-columns: 67px repeat(6, minmax(80px, 1fr));
      gap: 0px;
      padding: 8px 16px;
    }
  }
}

.item_mb {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 16px 0px $shadow;
  @include rounded-box-list-second(8px, 16px);

  &_header {
    @include flex-justify-align(space-between);
    margin-bottom: 12px;

    &_date {
      @include font-size(14, $regular, 18);
      color: rgba($violet, 0.5);
    }

    &_status {
      @include font-size(14, $bold, 18);
      color: rgba($violet, 0.5);
    }
  }

  &_name {
    @include font-size(16, $medium, 20);
    color: $violet;
  }

  &_source {
    margin-bottom: 9px;

    @include font-size(12, $regular, 18);
    color: rgba($violet, 0.5);
  }

  &_value {
    margin-bottom: 6px;

    @include font-size(14, $regular, 18);
    color: $violet;
  }

  &_bottom {
    @include flex-justify-align(stretch, stretch);
    flex-direction: column;

    @include font-size(14, $regular, 18);
    color: rgba($violet, 0.5);

    &_first_row {
      @include flex-justify-align(space-between);
      margin-bottom: 2px;
    }

    &_second_row {
      @include flex-justify-align(space-between);
    }
  }
}
