@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.eye_btn {
  @include reset-button();
  position: absolute;
  right: 20px;

  &:disabled {
    opacity: 0.1;
  }

  &_active {
    @extend .eye_btn;
  }

  transition: all 0.5s;
}
