@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.dashboard,
.dashboard * {
  font-family: "Visuelt Pro", "Poppins", sans-serif;
}

.dashboard {
  position: relative;
  height: 100vh;
  overflow: hidden;

  &__wrapper {
    height: 100%;
    display: flex;
  }

  &__content {
    position: relative;
    padding: 0px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: scroll;
    scrollbar-width: none;
  }

  &__content_lockscroll {
    overflow-y: hidden;
  }

  @media (max-width: 772px) {
    &__content {
      padding: 0px 0px 0px;
    }
  }

  @media (max-width: 560px) {
    height: 100%;

    &__content {
      overflow: initial;
    }

    &__footer {
      text-align: center;
    }
  }
}

.backToTheTopButton {
  position: fixed;
  width: 100%;
  top: 100px;
  z-index: 1;
  @include flex-justify-align(flex-end, center);
  padding: 20px;
  background-color: $white;

  p {
    @include font-size(10, $regular, 20);
    color: $font-color-purple;
    margin-right: 10px;
  }

  svg {
    margin-right: 20px;
  }
}
