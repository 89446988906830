@import "styles/vars";
@import "styles/mixins";

.content {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 450px) {
    width: 90vw;
    padding: 30px 20px;
  }
}

.close_icon {
  align-self: flex-end;
  margin-bottom: 26px;
  transition: all 0.5s;
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

.payment_method {
  @include flex-justify-align(space-between);
  &_label {
    @include font-size(14, $medium, 24);
    color: $font-color-black-secondary;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    @include flex-justify-align(space-between, flex-start);
    gap: 6px;
  }
}

.amount_pay {
  @include flex-justify-align(space-between);
  &_label {
    @include font-size(14, $medium, 24);
    color: $font-color-black-secondary;
  }
  &_input {
    @include font-size(16, $regular, 24);
    width: 160px;
    height: 60px;
    border: 1px solid rgba(221, 227, 232, 1);
    border-radius: 4px;
    text-align: right;
    padding: 0px 20px;
    color: $font-color-black-secondary;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    @include flex-justify-align(space-between, flex-start);
    gap: 6px;
    &_input {
      width: 100%;
      text-align: left;
    }
  }
}

.next_schelude {
  @include flex-justify-align(space-between);
  color: $font-color-black-secondary;
  &_label {
    @include font-size(14, $medium, 24);
  }
  &_value {
    @include font-size(16, $bold, 24);
  }
  &_disabled {
    @extend .next_schelude;
    color: rgba($font-color-black-secondary, 0.5);
  }
  @media (max-width: 450px) {
    flex-direction: column;
    @include flex-justify-align(space-between, flex-start);
    gap: 6px;
  }
}

.total_balance_remaining {
  @include flex-justify-align(space-between);
  &_label {
    @include font-size(16, $regular, 20);
    color: $font-color-black-secondary;
  }
  &_value {
    @include font-size(16, $bold, 24);
    color: $font-color-black-secondary;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    @include flex-justify-align(space-between, flex-start);
    gap: 6px;
  }
}

.title {
  @include font-size(24, $medium, 31);
  color: $violet;
  @media (max-width: 450px) {
    @include font-size(16, $medium, 20);
    color: $font-color-black-secondary;
  }
}

.record_payment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &_button {
    padding: 20px 48px;
    border: none;
    border-radius: 4px;
    @include font-size(18, $medium, 20);
    color: #fff;
    background: $background-button;
    transition: all 0.5s;
    &:hover:not(:disabled) {
      opacity: 0.8;
    }
    &:disabled {
      opacity: 0.2;
    }
    align-self: center;
    text-transform: uppercase;
    margin-top: 103px;
  }
  @media (max-width: 450px) {
    &_button {
      margin-top: 0px;
      width: 100%;
      @include font-size(16, $medium, 20);
    }
  }
}
