@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.setting_box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  padding: 19px 33px;
  box-shadow: 2px 8px 20px rgba(203, 203, 203, 0.35);
  border-radius: 4px;
  width: 100%;
  &_label {
    color: rgba($font-color-black-secondary, 0.3);
    @include font-size(20, $thin, 26);
  }
  &_name {
    color: rgba($font-color-black-secondary, 0.3);
    @include font-size(24, $regular, 30);
    text-transform: capitalize;
  }
  @media (max-width: 450px) {
    padding: 10px 20px;
    gap: 2px;
    &_label {
      @include font-size(14, $thin, 18);
    }
    &_name {
      @include font-size(16, $regular, 21);
    }
  }
}

.profile__details {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  @media (max-width: 450px) {
    gap: 14px;
  }
}
