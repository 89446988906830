@import "styles/vars";
@import "styles/mixins";

.footer {
  padding: 28px 20px 26px;

  background-color: #efefef;

  width: 100%;
  position: sticky;
  @include flex-justify-align(space-between);
  &_left {
    display: flex;
    align-items: center;
    gap: 7px;

    p {
      max-width: 338px;
      margin-left: 7px;
      @include font-size(12, $regular, 15);
      color: rgba($font-color-black-secondary, 0.65);
    }

    div {
      display: flex;
      flex-direction: column;

      img {
        width: 42.22px;
        height: 20px;
      }

      span {
        @include font-size(11, $regular, 16);
        color: $font-color-black-secondary;
        max-width: 338px;
      }
    }
  }

  &:only-child {
    flex-grow: 10;
  }

  button {
    @include reset-button;
    @include purple-btn;
    width: 152px;
    height: 45px;

    &:disabled {
      color: $white;
      background: linear-gradient(55.52deg, #7233cc -5.2%, #6b59ed 65.07%);
      opacity: 0.2;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    background-color: transparent;
    gap: 20px;

    &_left {
      flex-direction: column;
      align-items: flex-start;
    }

    button {
      width: 100%;
    }
  }
}

.footer_without_button {
  @include flex-justify-align(center);
  background-color: rgba(252, 252, 252);

  padding: 8px;
  span {
    @include font-size(11, $regular, 24);
    color: $font-color-black-secondary;
  }

  &_disabled {
    svg {
      g {
        path {
          fill: $purple;
        }
      }
    }
  }
}
