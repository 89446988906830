@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.variant {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  &_name {
    @include font-size(16, $regular, 24);
    color: $violet;
  }
  &_sku {
    @include font-size(14, $thin, 24);
    color: $violet;
  }
  &_stock {
    text-align: flex-start;
    @include font-size(16, $medium, 24);
    &_label {
      color: $violet;
    }
    &_value {
      color: $font-color-black-secondary;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 0.7fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 14px 0 12px;
    border-bottom: 1px solid $divider-color;
    &_name {
      @include font-size(16, $medium, 24);
    }

    &_sku {
      margin-bottom: 10px;

      @include font-size(12, $regular, 20);
      color: rgba($violet, 0.5);
    }

    &_stock {
      @include flex-justify-align(space-between, center);
      margin-bottom: 10px;

      &_label {
        @include font-size(14, $regular, 24);
      }

      &_value {
        color: $font-color-black-secondary;
      }
    }
  }
}

.more_stock {
  @include flex-justify-align(space-between, center);
  color: $purple;
  p {
    transition: all 0.5s;
    @include font-size(16, $medium, 24);
  }

  svg {
    transition: all 0.5s;

    width: 10px;
    height: 10px;
    path {
      fill: $purple;
    }
  }

  @media (max-width: 450px) {
    gap: 7px;
  }
}

.more_stock_active {
  @extend .more_stock;
  svg {
    transform: rotate(180deg);
  }
}

.inventory_quantity {
  position: absolute;
  opacity: 0;
  padding-top: 12px;

  &_name {
    @include font-size(16, $thin, 24);
    color: $violet;

    &_low {
      @extend .inventory_quantity_name;
      color: rgba(255, 58, 41, 1);
      &::before {
        content: "*";
      }
    }
  }

  @media (max-width: 450px) {
    &_name {
      @include font-size(14, $regular, 18);
      color: $violet;
    }
  }
}

.inventory_quantity_active {
  @extend .inventory_quantity;
  transition: all 0.3s ease-in;
  position: initial;
  opacity: 1;
}

.inventory__sales_channel {
  position: absolute;
  opacity: 0;
  padding-top: 12px;

  &_name {
    color: $violet;
    @include font-size(16, $thin, 24);
    &_low {
      @extend .inventory_quantity_name;
      color: rgba(255, 58, 41, 1);
    }
  }
  @media (max-width: 450px) {
    &_name {
      @include font-size(14, $regular, 18);
      color: rgba($violet, 0.5);
    }
  }
}

.inventory__sales_channel_active {
  @extend .inventory__sales_channel;
  transition: all 0.3s ease-in;
  position: initial;
  opacity: 1;

  @media (max-width: 450px) {
    @include flex-justify-align(space-between, center);
    padding-left: 10px;
  }
}

.location {
  display: flex;
  flex-direction: column;
  &_label {
    @include font-size(16, $thin, 24);
    color: $violet;
  }

  @media (max-width: 450px) {
    @include flex-justify-align(space-between, center);
    flex-direction: row;
  }
}
