@import "styles/vars";
@import "styles/mixins";

.modal,
.modal * {
  font-family: "Visuelt Pro", "Poppins", sans-serif;
}

.modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($font-color-black, 0.8);

  .close {
    display: none;
    position: absolute;
    cursor: pointer;
    top: -66px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    left: calc(100% - 40px);
    &:hover {
      opacity: 0.5;
    }
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: rgba(32, 39, 43, 0.8);
      }
    }
    @media (max-width: 575px) {
      display: flex;
      width: 20px;
      height: 20px;
      top: -30px;
      svg {
        width: 9px;
        height: 8px;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    background: white;
    min-width: 560px;
    max-height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 32px 64px rgba(140, 136, 150, 0.3);
    border-radius: 4px;
    @media (max-width: 850px) {
      min-width: 80%;
      max-height: 90%;
    }
    @media (max-width: 450px) {
      min-width: 280px;
    }
  }

  .content {
    padding: 40px 60px;
    @include flex-justify-align(center, center);
    overflow: auto;
    flex-direction: column;
    &_close_btn {
      align-self: flex-end;
    }
    &_header {
      margin-top: 30px;
      display: flex;
      align-items: center;
      color: $violet;
      @include font-size(24, $regular, 40, 1);
      @media (max-width: 450px) {
        margin-top: 0px;
        @include font-size(14, $bold, 24, 1px);
      }
    }
    .payment_pay_btn {
      @include font-size(16, $demibold, 20, 1.33);
      @include flex-justify-align(center, center);
      background: $background-button;
      color: white;
      border: none;

      border-radius: 4px;
      text-transform: uppercase;
      transition: all 0.5s ease-out;
      width: 250px;
      height: 60px;
      &:disabled {
        outline: none;
        background-color: rgba($purple, 0.2);
      }
      &:hover:not(:disabled) {
        background-color: rgba($purple, 0.5);
      }
    }
  }
  @media (max-width: 772px) {
    padding: 20px 20px 40px 20px;
    .payment_pay_btn {
      @include font-size(14, $demibold, 20, 1.17);
    }
  }
  @media (max-width: 450px) {
    &_wrapper {
      border-radius: 8px;
    }
    &_content {
      padding: 20px;
    }
  }
}

.subtitle {
  padding: 20px 0;
  color: $violet;
  text-align: center;
  @include font-size(16, $regular, 21);
  margin-bottom: 36px;
  @media (max-width: 450px) {
    @include font-size(12, $medium, 24);
    padding: 0 0 12px;
    margin-bottom: 0;
  }
}

.modal_second {
  @extend .modal;
  .content {
    &_header {
      @include font-size(24, $medium, 40, 1);
    }
  }
  .payment_pay_btn {
    @include font-size(16, $bold, 20, 1.33);
  }
  @media (max-width: 450px) {
    .wrapper {
      padding: 0px;
      min-width: 95%;
      width: 95%;
    }
    .content {
      gap: 20px;
      padding: 50px 20px;
      &_header {
        @include font-size(24, $medium, 31);
      }
      .subtitle {
        @include font-size(16, $medium, 24);
      }
    }
    .payment_pay_btn {
      @include font-size(16, $bold, 20, 1.33);
    }
  }
}
