@import "styles/vars";
@import "styles/mixins";

.sidebar {
  width: 35%;
  background-color: rgba(247, 248, 253, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 20px;
  padding: 15px 30px 15px;
  &_title {
    @include font-size(18, $bold, 40, 1);
    padding-bottom: 10px;
    color: $font-color-black-secondary;
    border-bottom: 1px solid $divider-color;
  }
  &_price {
    position: fixed;
    bottom: 24px;
    display: flex;
    flex-direction: column;
  }

  &_items {
    display: flex;
    flex-direction: column;
    height: 100vh;
    gap: 10px;
    overflow: scroll;
  }

  @media (max-width: 1150px) {
    padding-top: 35px;
    width: 40%;
    &_title {
      padding: 0px 20px;
    }
    &_items {
      padding: 0px 20px;
    }
  }

  @media (max-width: 770px) {
    padding: 10px 27px 10px;
    &_title {
      padding: 0px;
    }
    &_items {
      padding: 0px;
    }
  }

  @media (max-width: 450px) {
    width: calc(100% - 40px);
    height: 100%;
    padding: 20px 0px;
    margin: 0 20px;
    border-top: 1px solid $divider-color;
    &_title {
      display: none;
      @include font-size(16, $bold, 40, 0.66);
      padding: 0px;
    }
    &_items {
      gap: 20px;
      height: 100%;
    }
    &_price {
      position: static;
      bottom: 24px;
      display: flex;
      flex-direction: column;
    }
  }
}
