@import "styles/vars";
@import "styles/mixins";

.mobile__table_item {
  padding: 18px 5px 19px 8px;
  margin: 20px 0 0;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;
  display: block;

  background-color: $white;

  position: relative;

  &_title {
    display: flex;
    justify-content: space-between;

    text-align: left;

    @include font-size(16, $medium, 20);
    color: $violet;
  }

  &_subtitle {
    margin-bottom: 10px;

    @include font-size(12, $regular, 18);
    color: rgba($violet, 0.5);
  }
}

.price {
  margin-bottom: 10px;

  @include font-size(14, $regular, 18);
  color: $violet;
}

.mobile__table_item_info {
  @include flex-justify-align(space-between, center);
  margin-bottom: 2px;

  @include font-size(14, $regular, 18);
  color: rgba($violet, 0.5);
}