@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.bag {
  padding: 10px 10px 10px 23px;
  background-color: $font-main-color;
  width: 218px;
  @include flex-justify-align(space-between, center);
  border: none;
  border-radius: 80px 0px 0px 80px;

  color: #fff;
  @include font-size(14, $demibold, 20);

  svg {
    opacity: 0.4;
  }

  p {
    @include font-size(20, $bold, 20, -0.41);
    color: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 450px) {
    position: fixed;
    bottom: 0;

    width: 100%;

    border-radius: 8px 8px 0px 0px;
  }
}

.bag_active {
  @extend .bag;
  background: $background-button;

  svg {
    opacity: 1;
  }

  p {
    @include font-size(20, $bold, 20, -0.41);
    color: rgba(255, 255, 255, 1);
  }
}
