@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  width: 100%;
  position: relative;
  height: 100%;
  @media (max-width: 1076px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
}

.main {
  overflow: auto;
  height: calc(100% - 30px);
  padding: 60px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  @media (max-width: 768px) {
    padding: 0px;
    height: auto;
    overflow-x: hidden;
  }
}
