@import "styles/vars";
@import "styles/mixins";

.table {
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;
  padding: 3px 63px 20px;

  @media (max-width: 1376px) {
    padding: 3px 0px 20px;
  }

  @media (max-width: 1024px) {
    padding: 3px 0px 20px;
    margin: 0 0 5%;
  }

  @media (max-width: 772px) {
    padding: 3px 0px 20px;
    margin: 0 0 5%;
  }

  @media (max-width: 772px) {
    margin: 10px;
    padding: 0;
  }
}

.table_header {
  display: grid;
  grid-template-columns: repeat(7, minmax(100px, 1fr));
  position: relative;
  height: 60px;
  background-color: $white;
  gap: 15px;
  align-items: center;
  padding: 20px 31px;
  border-bottom: 1px solid rgba(226, 209, 249, 0.3);

  button {
    display: flex;
    justify-content: flex-start;
    p {
      padding: 0;
    }
  }

  &_nodata {
    @extend .table_header;
    border: none;
    opacity: 0.5;
  }

  @include font-size(16, $medium, 21);
  color: $font-color-black-secondary;
  &_no_items {
    @extend .table_header;
    opacity: 0.5;
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(7, minmax(100px, 1fr));
    gap: 10px;
  }

  @media (max-width: 768px) {
    @include font-size(14, $regular, 20);
    grid-template-columns: 67px repeat(6, minmax(80px, 1fr));
    gap: 0px;
    padding: 32px 16px 13px;
  }

  @media (max-width: 450px) {
    display: none;
  }
}

.table_content {
  display: flex;
  flex-direction: column;
  &_item_mb {
    @include font-size(12, $regular, 20);
    @include rounded-box(1.25rem, 1.25rem);
    color: $font-color-gray;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    :first-child {
      @include flex-justify-align(space-between);
      flex-direction: row;

      :first-child {
        @include font-size(12, $medium, 20);
        color: $purple;
      }
    }

    :nth-child(3) {
      @include font-size(12, $medium, 20);
      color: rgba(32, 39, 43, 1);
    }

    @media (max-width: 772px) {
      margin-bottom: 18px;
    }
  }

  @media (max-width: 450px) {
    gap: 20px;
  }
}

.more_btn {
  margin: 1rem;
  align-self: flex-start;
  @media (max-width: 772px) {
    align-self: center;
  }
}

.nodata {
  @include flex-justify-align(center);
  height: 30vh;
  color: $violet;
  @include font-size(16, $thin, 24);
}

.loading {
  @include flex-justify-align(center);

  width: 100%;
  height: 130px;
  div {
    width: auto;
    height: auto;
  }
  svg {
    width: 50px !important;
    height: 50px !important;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
}

.sort_mob {
  @include flex-justify-align(space-between);
  margin: 20px 0;
  &_label {
    @include font-size(16, $medium, 18);
    color: rgba($violet, 0.5);
  }
}
