@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.shipping {
  overflow: hidden;

  &_content {
    padding: 0 10%;
    overflow: scroll;

    div:last-child {
      border: none;
    }

    h2 {
      @include font-size(24, $bold, 42);
      color: rgba($font-color-black-secondary, 0.52);
    }

    ul {
      @include reset-ul();
      column-count: 3;

      svg {
        margin-right: 20px;
      }

      li {
        @include font-size(14, $medium, 20);
        color: $violet;
      }
    }

    h3 {
      @include font-size(14, $medium, 18);
      color: rgba($font-color-black-secondary, 0.6);
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    &_description {
      color: $font-color-black-secondary;
      @include font-size(14, $medium, 20);
      margin-bottom: 21px;

      span {
        @include font-size(14, $bold, 20);
        text-transform: uppercase;
      }
    }

    &_nodata {
      color: rgba(215, 214, 217, 1);
      @include font-size(14, $medium, 20);
      margin-bottom: 40px;
    }

    @media (max-width: 570px) {
      h2 {
        margin-bottom: 30px;
        color: rgba($font-color-black-secondary, 0.52);
        padding-bottom: 13px;
        border-bottom: 1px solid rgba($light-blue, 0.5);
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 21px;
        li {
          @include font-size(14, $medium, 20);
        }
      }

      h3 {
        @include font-size(14, $demibold, 20);
      }

      &_description {
        @include font-size(14, $medium, 20);
      }
    }
  }
}

.shippingName {
  color: $purple;
  @include font-size(14, $bold, 20);
}

.shipping_item_wrapper {
  padding: 20px 0 20px;
  border-bottom: 1px solid rgba(144, 149, 166, 0.4);

  @media (max-width: 450px) {
    padding: 0 0 40px;
    border-bottom: 0;
  }
}

.delivery_list {
  column-count: 1 !important;
  gap: 10px;

  li {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  div {
    .delivery_title {
      @include font-size(14, $medium, 18);
      color: rgba($font-color-black-secondary, 0.52);
      margin-bottom: 0 !important;
    }
  }

  .delivery_areas {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 450px) {
    .delivery_areas {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(102px, 1fr));
      grid-template-rows: repeat(auto-fill, 20px);
      gap: 20px;

      svg {
        margin-right: 10px;
      }
    }
  }
}

.local__delivery_list {
  column-count: auto !important;

  li {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  div {
    &:first-child {
      @include font-size(14, $medium, 18);
      color: rgba($font-color-black-secondary, 0.52);
      margin-bottom: 0 !important;
      padding: 0;
    }

    &:last-child {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;

      svg {
        margin-right: 0 !important;
      }
    }
  }
}
