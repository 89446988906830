@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";









.inputs {
  position: relative;
  @include flex-justify-align(space-between, flex-start);
  margin-bottom: 20px;

  &_wrapper {
    display: flex;
    position: relative;
    @include flex-justify-align(space-between);
    gap: 13px;

    input:placeholder-shown+button {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 10px;

    &_wrapper {
      width: 100%;
    }
  }
}

.input {
  @include input();
  max-width: 100%;
  min-width: 280px;
  width: 100%;
  height: 60px;
  text-align: left;
  padding: 20px 60px 20px 20px;
  outline: none;

  @include font-size(16, $regular, 21);
  color: $font-color-black-secondary;

  &:focus {
    border-color: $purple;
  }

  &::placeholder {
    color: rgba($font-color-black-secondary, 0.4);
  }

  &:disabled {
    opacity: 0.2;
  }

  @media (max-width: 450px) {
    width: 100%;
    min-width: 100%;
  }
}

.show_password {
  @include reset-button();
  position: absolute;
  right: 20px;

  &:disabled {
    opacity: 0.2;
  }

  &_active {
    @extend .show_password;
    opacity: 0.2;
  }

  transition: all 0.5s;
}

.wrapper {
  @include flex-justify-align(space-between, flex-start);
}


.success {
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  opacity: 0;

  transition: all 0.5s;

  &_active {
    opacity: 1;
  }
}

.forgot_password {
  @include reset-button();

  @include font-size(16, $regular, 24);
  color: $purple;
  text-decoration-line: underline;

  &:disabled {
    opacity: 0.2;
  }
}

.wrapper {
  border-bottom: 1px solid $divider-color;
  margin-bottom: 31px;
  padding-bottom: 92px;

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 20px;

    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}

.sign_up {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &_title {
    @include font-size(16, $medium, 24);
    color: rgba($font-color-black-secondary, 0.56);
  }

  &_subtitle {
    @include font-size(14, $bold, 24);
    color: rgba($font-color-black-secondary, 0.56);

    button {
      @include reset-button();
      @include font-size(14, $bold, 24);
      color: $purple;
    }
  }
}


.spinner_wrapper {
  position: absolute;
  left: calc(100% + 15px);

  @media (max-width: 450px) {
    left: initial;
    right: 15px;
  }
}