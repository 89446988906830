@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  position: absolute;
  height: 258px;
  width: 480px;
  bottom: 67px;
  z-index: 999;

  background-color: $white;
  box-shadow: 2px 8px 20px $shadow;
  border-radius: 4px;

  animation: rotateMenu 0.3s ease-in-out;

  overflow: scroll;

  &::-webkit-scrollbar-thumb {
    background: $font-color-black-secondary;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &_notfound_text {
    @include flex-justify-align(center);
    height: calc(100% - 30px);

    @include font-size(16, $demibold, 20);
    color: $font-color-black-secondary;
  }

  &_place {
    padding: 5px 25px;
    @include flex-justify-align(space-between);
    background-color: transparent;
    color: $font-color-black-secondary;

    h3 {
      @include font-size(16, $regular, 20);
      width: 90%;
      text-align: left;
      color: $font-color-black-secondary;

      span {
        @include font-size(16, $demibold, 20);
        color: $purple;
      }

      span:last-child {
        color: rgba(120, 137, 149, 1);
      }
    }

    &:hover {
      background-color: $purple;
      h3 {
        color: $white;

        span {
          color: $white;
        }
      }
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    &_place {
      padding: 5px 10px;
    }
  }
}

@keyframes rotateMenu {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

.add__manually {
  @include flex-justify-align(space-between);
  padding: 24px 25px 17px;
  margin-bottom: 25px;
  position: sticky;
  background-color: white;
  top: 0px;

  border-bottom: 1px solid $divider_color;

  p {
    @include font-size(14, $medium, 18);
    color: $light-ultramarine;
  }

  button {
    @include reset-button();

    border: 1px solid $purple;
    border-radius: 110px;
    padding: 7px 11px;

    @include font-size(12, $bold, 15);
    color: $purple;

    transition: all 0.5s;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 450px) {
    padding: 24px 10px 17px;
    p {
      @include font-size(12, $regular, 15);
    }
  }
}
