@import "styles/vars";
@import "styles/mixins";

.title {
  @include flex-justify-align(space-between);
  margin-bottom: 8px;

  &_name {
    @include font-size(20, $bold, 36);
    color: rgba(0, 0, 0, 1);
  }

  &_number {
    @include font-size(20, $regular, 36);
    color: rgba(136, 136, 136, 1);
  }
}
