@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.shop__info {
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  gap: 17px;
  border-bottom: 1px solid rgba($light-blue, 0.4);

  &_title {
    @include font-size(24, $bold, 42);
    color: rgba($font-color-black-secondary, 0.56);
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_item {
      @include font-size(18, $medium, 32);
      color: $font-color-black-secondary;

      &:hover {
        color: $font-color-black-secondary;
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 450px) {
    border: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    &_title {
      display: none;
    }
  }
}