@import "styles/vars";
@import "styles/mixins";

.container {
  @include flex-justify-align(space-between);
  padding: 17px 20px;
  background: $background-color-secondary;
  box-shadow: 0px 4px 16px $shadow;

  &_title_wrapper {
    display: flex;
    flex-direction: column;
  }

  &_title {
    @include font-size(14, $medium, 20);
    color: rgba($font-color-black-secondary, 0.65);
  }

  &_text {
    @include font-size(12, $regular, 15);
    color: rgba(136, 136, 136, 1);
  }

  &_phone_number_wrapper {
    display: flex;
  }

  &_phone_number {
    display: flex;
    gap: 10px;
    input {
      outline: none;
      width: 200px;
      padding: 7px 12px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      color: $font-color-black-secondary;

      @include font-size(12, $regular, 15);
    }
    button {
      outline: none;
      background-color: rgba($font-color-black-secondary, 0.65);

      border: none;
      border-radius: 4px;

      width: 99px;
      height: 30px;
      transition: background-color 0.6s ease;

      @include font-size(12, $bold, 15);
      color: $white;

      &:hover {
        background-color: $white;
        color: rgba(0, 0, 0, 1);
        border: 1px solid $white;
        &:active {
          background-color: rgba(0, 0, 0, 1);
          color: $white;
          border: 1px solid $white;
        }
      }
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    margin: 0 -10px;
    padding: 13px;

    &_title_wrapper {
      width: 100%;
      margin-bottom: 10px;
    }

    &_phone_number_wrapper {
      width: 100%;
    }

    &_phone_number {
      flex-direction: column;
      width: 100%;

      input {
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }
}
