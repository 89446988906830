@import "styles/vars";
@import "styles/mixins";

.item {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.item_description {
  @include flex-justify-align(space-between);
  gap: 1em;

  h3 {
    color: $black;
    @include font-size(16, $regular, 24);
    flex: 1;
  }
}

.variant {
  color: rgba($font-color-black-secondary, 0.7);
  @include font-size(12, $regular, 20);
}

.item_quantity {
  width: 1.5em;
  height: 1.5em;
  align-self: flex-start;
  display: inline-flex;
  @include flex-justify-align(center);
  opacity: 0.5;

  padding: 5px;

  background-color: $violet;
  box-shadow: 0px 4px 16px $shadow;
  border: 1px solid $white;
  border-radius: 4px;

  color: $font-color-black-secondary;
  @include font-size(16, $bold);
  line-height: 1em;
}

.item_price {
  @include flex-justify-align(space-between);

  span:first-child {
    color: $font-color-black-secondary;
    @include font-size(16, $bold, 20, 0.69);
  }
}

.item_avatar {
  position: relative;
  @include flex-justify-align(center);
  width: 84px;
  height: 84px;
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $purple;

  span {
    @include font-size(28, $regular, 35, -0.5);
    color: $purple;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 900px) {
    align-self: flex-start;
    width: 40px;
    height: 40px;

    span {
      @include font-size(20, $regular, 35, -0.5);
    }
  }
}

.item_taxes {
  .item_tax {
    @include flex-justify-align(space-between, flex-start);

    color: $black;
    @include font-size(16, $thin, 20);

    &_amount {
      @include font-size(14, $thin, 20);
    }
  }
}
