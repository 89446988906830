@import "styles/vars";
@import "styles/mixins";

.sidebar {
  width: 400px;
  background-color: rgba(247, 248, 253, 1);
  display: flex;
  position: fixed;
  right: 0;
  top: 100px;
  bottom: 0;
  flex-direction: column;
  gap: 20px;
  padding: 15px 30px 15px;

  &_title {
    @include font-size(18, $bold, 40, 1);
    color: $font-color-black-secondary;
    padding-bottom: 10px;
    border-bottom: 1px solid $divider-color;
  }

  &_items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow: scroll;
  }

  @media (max-width: 1150px) {
    padding-top: 35px;
    width: 400px;
    &_title {
      padding: 0px 20px;
    }
    &_items {
      padding: 0px 20px;
    }
  }

  @media (max-width: 900px) {
    padding: 27px 12px 17px;
    width: 280px;

    &_title {
      padding: 0px;
    }

    &_items {
      padding: 0px;
    }
  }

  @media (max-width: 700px) {
    width: 220px;
    padding: 27px 5px 17px;
  }

  @media (max-width: 450px) {
    position: initial;
    width: calc(100% - 40px);
    height: 100%;
    padding: 20px 0px;
    margin: 0 20px;
    border-top: 1px solid $divider-color;
    &_title {
      display: none;
      @include font-size(16, $bold, 40, 0.66);
      padding: 0px;
    }
    &_items {
      gap: 20px;
      height: 100%;
    }
    .price {
      display: none;
    }
  }
}
