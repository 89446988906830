@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.footer {
  @include flex-justify-align(space-between);
  padding: 20px;

  background-color: $font-color-black-secondary;

  &_title {
    @include font-size(10, $medium, 13);
    color: rgba(149, 149, 149, 1);
    text-transform: uppercase;
    margin-bottom: 11px;
  }

  &_address {
    p {
      @include font-size(10, $medium, 14);
      color: $white;

      &:first-child {
        margin-bottom: 3px;
      }
    }
  }

  &_right {
    padding-top: 24px;
    @include flex-justify-align(space-between, flex-start);
    flex-direction: column;

    p,
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include font-size(10, $medium, 14);
      color: $white;

      &:nth-child(2) {
        margin-bottom: 6px;
      }
    }
  }

  @media (max-width: 450px) {
    margin: 0 -10px;
    flex-direction: column;
    @include flex-justify-align(space-between, flex-start);
    gap: 11px;

    &_right {
      @include flex-justify-align(space-between, flex-start);
      flex-direction: column;
      padding-top: 0;
      gap: 10px;
    }
  }
}

.socials {
  display: flex;
  gap: 10px;
  a {
    width: 18px;
    height: 18px;
    svg {
      height: 18px;
    }
  }
}
