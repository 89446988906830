@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.share {
  @include flex-justify-align(space-between, center);
  gap: 10px;

  @include font-size(14, $medium, 18);
  color: rgba(144, 149, 166, 1);
}

.root {
  display: flex;
  align-items: flex-end;
  flex: 1;
}

.content {
  position: relative;
  display: flex;
  gap: 10px;
  flex: 1;
  background-color: rgba(255, 255, 255, 1);

  animation: fade-out 0.6s ease-out;

  button {
    @include reset-button;

    @include flex-justify-align(center, center);
    flex-direction: column;

    width: 30px;
    height: 30px;

    border: 1px solid rgba(151, 151, 151, 0.4);
    border-radius: 50%;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.copy {
  @include flex-justify-align(space-between);
  gap: 2px;

  span {
    @include font-size(14, $regular, 18);
    color: $violet;
  }

  button {
    @include reset-button;
    @include flex-justify-align(center, center);
    flex-direction: column;

    border: 1px solid rgba(151, 151, 151, 0.4);
    border-radius: 50%;

    width: 30px;
    height: 30px;

    &:disabled {
      color: rgba(232, 74, 95, 1);
    }
  }
}
