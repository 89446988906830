@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.address_input_wrapper {
  position: relative;
  width: 100%;

  input {
    @include input();
    max-width: 100%;
    width: 100%;
    height: 60px;
    text-align: left;
    padding: 20px;
    outline: none;

    @include font-size(16, $regular, 21);
    color: $font-color-black-secondary;

    &:focus {
      border-color: $purple;
    }

    &::placeholder {
      color: rgba($font-color-black-secondary, 0.4);
    }
  }

  &_active {
    width: 400px;
  }

  @media (max-width: 450px) {
    width: 100%;

    &_active {
      width: 100%;
    }
  }
}
