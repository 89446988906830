@import "styles/vars";
@import "styles/mixins";

.root {
  padding: 20px 20px 0px 20px;
  background-color: #fff;
  margin-bottom: 20px;
}

.accordion_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;

  h3 {
    @include font-size(16, $demibold, 40);
    color: $font-main-color;
  }
  svg {
    path {
      fill: $font-main-color;
    }
  }
}

.accordion_content {
  @include font-size(12, $regular, 16);
  color: $font-color-black;
  width: calc(100% + 40px);
  max-height: 0px;
  margin-left: -20px;

  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.open {
  width: calc(100% + 40px);
  margin-left: -20px;
  max-height: 1000px;
}

.icon {
  width: 12px;
  height: 6px;

  transition: 0.5s;
  color: $font-color-purple;
}

.icon_open {
  @extend .icon;
  transform: rotate(180deg);
}
