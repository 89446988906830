@import "styles/vars";
@import "styles/mixins";

.table {
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;
  padding: 26px 48px 20px 63px;

  @media (max-width: 1376px) {
    padding: 3px 0px 20px;
  }

  @media (max-width: 1024px) {
    padding: 3px 0px 20px;
    margin: 0 0 5%;
  }

  @media (max-width: 956px) {
    overflow: scroll;
  }

  @media (max-width: 772px) {
    margin: 10px;
    padding: 0;
  }
}

.table_header {
  position: relative;
  display: grid;
  grid-template-columns: 128px 109px 111px 72px 120px 140px 1fr;
  grid-template-columns: repeat(3, minmax(10%, 1fr)) 72px repeat(
      3,
      minmax(10%, 1fr)
    );
  gap: 10px;

  background-color: #fff;
  align-items: center;
  padding: 0px 31px 17px;

  border-bottom: 1px solid rgba(226, 209, 249, 0.3);
  button {
    &:nth-child(n + 5) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      padding: 0;
    }
  }

  &_nodata {
    @extend .table_header;
    border: none;
    opacity: 0.5;
  }

  @include font-size(16, $medium, 21);
  color: $font-color-black-secondary;
  &_no_items {
    @extend .table_header;
    opacity: 0.5;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 90px 100px 100px 70px 120px 100px 1fr;
    gap: 10px;
    padding: 15px 20px;
    height: auto;
  }

  @media (max-width: 768px) {
    @include font-size(14, $regular, 20);
    grid-template-columns: 74px 70px 80px 65px 110px 90px 1fr;
    button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        padding: 0;
      }
    }
    gap: 0px;
    padding: 0px;
  }

  @media (max-width: 450px) {
    display: none;
  }
}

.table_header_disabled {
  @extend .table_header;
  opacity: 0.4;
}

.table_content {
  display: flex;
  flex-direction: column;
  &_item_mb {
    @include font-size(12, $regular, 20);
    @include rounded-box(1.25rem, 1.25rem);
    color: $font-color-gray;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    :first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      :first-child {
        @include font-size(12, $medium, 20);
        color: #7233cc;
      }
    }
    :nth-child(3) {
      @include font-size(12, $medium, 20);
      color: #20272b;
    }
    @media (max-width: 772px) {
      margin-bottom: 18px;
    }
  }
  @media (max-width: 450px) {
    gap: 20px;
  }
}

.more_btn {
  margin: 1rem;
  align-self: flex-start;
  @media (max-width: 772px) {
    align-self: center;
  }
}

.nodata {
  @include flex-justify-align(center);
  height: 30vh;
  color: $violet;
  @include font-size(16, $thin, 24);
  opacity: 0.2;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 130px;
  div {
    width: auto;
    height: auto;
  }
  svg {
    width: 50px !important;
    height: 50px !important;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
}

.sort_mob {
  @include flex-justify-align(space-between);
  margin: 20px 0;
  &_label {
    @include font-size(16, $medium, 18);
    color: rgba($violet, 0.5);
  }
}
