@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.option {
  cursor: pointer;
  @include flex-justify-align(space-between);
  flex-direction: column;

  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;

  padding: 22px 47px 22px 39px;
  margin: 0px;

  appearance: none;
  -webkit-appearance: none;

  transition: all 0.1s ease-in-out;

  input {
    display: none;
  }

  .option_name {
    @include font-size(19, $regular, 40);
    color: $font-color-black-secondary;
  }

  .option_radio_button {
    @include flex-justify-align(center);
    padding: 1px;

    border: 1px solid rgba($purple, 0.8);

    border-radius: 50%;

    width: 24px;
    height: 24px;

    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 450px) {
    padding: 20px;
  }
}

.option_disabled {
  @extend .option;
  opacity: 0.6;
}

.option_selected {
  @extend .option;
  border: 1px solid rgba($purple, 0.8);

  .option_radio_button {
    @extend .option_radio_button;
    padding: 1px;

    border: 1px solid rgba($purple, 0.8);

    border-radius: 50%;
    width: 24px;
    height: 24px;
  }

  .option_check {
    transition: all 0s ease-out;
    width: 16px;
    height: 16px;

    background-color: rgba($purple, 0.8);
    border-radius: 50%;
  }
}

.option_button {
  @include flex-justify-align(space-between);
  gap: 10px;
  width: 100%;
}

.option_description {
  display: flex;
  flex-direction: column;
  gap: 9px;

  &_title {
    &_type {
      @include font-size(20, $bold, 25);
      color: rgba($font-color-black-secondary, 0.65);
    }

    &_address {
      @include font-size(14, $medium, 18);
      color: rgba($font-color-black-secondary, 0.65);
    }
  }

  &_fee {
    &_title {
      @include font-size(14, $regular, 20);
      color: rgba($font-color-black-secondary, 0.65);
    }

    &_value {
      @include font-size(16, $bold, 20);
      color: rgba($font-color-black-secondary, 0.65);
    }
  }

  @media (max-width: 450px) {
    &_title {
      display: flex;
      flex-direction: column;
      &_type {
        @include font-size(14, $bold, 18);
      }

      &_address {
        @include font-size(14, $medium, 18);
      }
    }

    &_fee {
      &_title {
        @include font-size(14, $regular, 18);
      }

      &_value {
        @include font-size(14, $bold, 18);
      }
    }
  }
}
