@import "styles/vars";
@import "styles/mixins";

// Graph

.graph_wrapper {
  position: relative;
  width: 100%;
}

.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Component

.root {
  width: 250px;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  padding-top: 33px;
  box-shadow: 0px 4px 16px 0px $shadow;
  @media (max-width: 1100px) {
    width: 220px;
  }

  @media (max-width: 768px) {
    width: 180px;
  }

  @media (max-width: 450px) {
    padding-top: 20px;
    width: 140px;
    height: 200px;
  }
}

.title {
  @include font-size(12, $medium, 15, 1);
  color: rgba($violet, 0.5);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;

  &_two {
    @extend .title;
    color: $purple;
  }

  &_no_data {
    @extend .title;
    color: rgba($violet, 0.5);
  }

  @media (max-width: 450px) {
    margin-bottom: 14px;
  }
}

.percent {
  position: absolute;
  @include font-size(24, $demibold, 20);
  width: 100px;
  height: 100px;
  @include flex-justify-align(center, center);
  color: $purple;
  left: 0px;
  top: 0px;
  @media (max-width: 450px) {
    @include font-size(18, $medium, 23);
    width: 80px;
    height: 80px;
  }
}

.value {
  @include font-size(16, $bold, 20);
  color: $purple;
  margin-top: 10px;
  @media (max-width: 450px) {
    @include font-size(14, $bold, 18);
  }
}

.nodata {
  @include font-size(14, $medium, 18);
  height: 100%;
  color: rgba($violet, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 450px) {
    margin: auto;
    @include font-size(14, $medium, 18);
    padding: 0px 20px;
    text-align: center;
  }
}

.chart_wrapper {
  position: relative;
}

.ytd {
  color: rgba($violet, 0.5);
  @include font-size(12, $medium, 15, 1);
  margin-top: 10px;
}

.subtitle {
  color: rgba($violet, 0.5);
  @include font-size(12, $medium, 15, 1);
  margin-top: 10px;
  @media (max-width: 450px) {
    margin-top: 12px;
  }
}

.second_value {
  color: rgba($violet, 0.5);
  @include font-size(16, $bold, 20);
  margin-top: 3px;

  @media (max-width: 450px) {
    margin-top: 0px;
    padding-top: 12px;
    padding-bottom: 16px;
  }
}
