@import "styles/vars";
@import "styles/mixins";

.notes {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 0 20px 47px;

  &_title {
    @include font-size(12, $medium, 14);
  }

  &_text {
    @include font-size(12, $regular, 12);
    color: rgba(136, 136, 136, 1);
  }

  @media (max-width: 450px) {
    margin: 0 0 30px;
  }
}
