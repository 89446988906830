@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  box-shadow: 0px 4px 16px $shadow;
  border-radius: 4px;

  padding: 14px 55px 20px;

  @include flex-justify-align(space-between);
  gap: 20px;

  p {
    @include font-size(18, $bold, 23);
    color: $font-color-black-secondary;
  }

  .link {
    color: $purple;
    text-decoration: underline;
    white-space: nowrap;
    @include font-size(16, $regular, 26);
  }

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 768px) {
    .link {
      @include font-size(14, $regular, 26);
    }
  }
}
