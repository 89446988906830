@import "styles/vars";
@import "styles/mixins";

.modal {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(32, 39, 43, 0.6);
  @include flex-justify-align(center);
  transition: all 1s ease-in-out;
  transition: 0.5s;

  &_show {
    @extend .modal;
    opacity: 1;
  }

  &_hide {
    @extend .modal;
    pointer-events: none;
    opacity: 0;
  }

  &_wrapper {
    cursor: auto !important;
    position: fixed;
    display: flex;
    flex-direction: column;

    background: white;
    box-shadow: 0px 32px 64px rgba(140, 136, 150, 0.3);
    border-radius: 4px;

    padding: 0;

    min-width: 40%;

    transform: scale(0.5);
    transition: all 0.5s ease-in-out;

    &_active {
      @extend .modal_wrapper;
      transform: scale(1);
      outline: 0;
    }

    &_three {
      @extend .modal_wrapper;
      min-width: auto;
      border-radius: 4px;

      &_active {
        @extend .modal_wrapper_three;
        transform: scale(1);
      }

      @media (max-width: 450px) {
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0px;
        overflow: auto;
      }
    }

    &_two {
      @extend .modal_wrapper;
      min-width: 60%;
      border-radius: 4px;
      padding: 20px 30px;

      &_active {
        @extend .modal_wrapper_two;
        transform: scale(1);
      }

      @media (max-width: 768px) {
        min-width: 70%;
      }

      @media (max-width: 450px) {
        top: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        overflow: auto;
      }
    }

    &_four {
      @extend .modal_wrapper;
      min-width: 560px;
      border-radius: 4px;
      padding: 20px 30px;

      &_active {
        @extend .modal_wrapper_four;
        transform: scale(1);
      }

      @media (max-width: 768px) {
        min-width: 70%;
      }

      @media (max-width: 450px) {
        width: calc(100% - 40px);
        padding: 0;
        overflow: auto;
      }
    }
  }
}
