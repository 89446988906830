@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.input {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  margin: 0;
  margin: 20px 20px 10px;

  span {
    @include font-size(14, $medium, 18);
    color: rgba($font-color-black-secondary, 0.4);
    text-transform: uppercase;
  }

  input {
    padding: 20px;
    height: 60px;
    outline: none;

    @include font-size(16, $regular, 20);
    color: $font-color-black-secondary;

    border: 1px solid rgba(221, 227, 232, 1);
    border-radius: 3px;

    &:focus {
      border-color: $purple;
    }

    &::placeholder {
      color: rgba($font-color-black-secondary, 0.4);
    }
  }
}

.description {
  margin: 0 20px 20px;

  @include font-size(14, $regular, 18);
  color: rgba(25, 28, 35, 0.65);
}

.bank_input {
  height: 60px;
  margin: 0 20px 20px;
  border: 1px solid rgba(221, 227, 232, 1);
  border-radius: 3px;
}
