@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  min-height: 100vh;
  @include flex-justify-align(space-between, stretch);
  flex-direction: column;
}

.header {
  padding: 80px 125px 106px;

  display: flex;
  flex-direction: column;
  gap: 88px;

  &_container {
    @include flex-justify-align(space-between, stretch);

    &_left {
      @include flex-justify-align(space-between, stretch);
      gap: 30px;
    }
  }

  @media (max-width: 1024px) {
    padding: 40px 70px 100px;
  }

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }

  @media (max-width: 450px) {
    padding: 15px 15px 10px;
    flex-direction: row;

    .avatar {
      display: none;
    }

    &_mob_title {
      @include font-size(20, $bold, 36px);
      color: rgba(0, 0, 0, 1);
    }

    &_container {
      &_left {
        flex-direction: column;
      }
    }
  }
}

.hamburger {
  outline: none;
  background: transparent;
  border: none;

  padding: 0;

  margin-right: 20px;

  span {
    display: block;
    width: 22px;
    height: 2px;
    margin-bottom: 5px;
    background-color: rgba(25, 28, 35, 1);
    transition: all 0.3s linear;

    &:nth-child(2) {
      width: 15px;
    }

    &:last-child {
      margin-bottom: initial;
    }
  }

  &_active {
    span:nth-child(1) {
      transform: translateY(7px) rotate(45deg);
      height: 3px;
    }

    span:nth-child(2) {
      opacity: 0;
      visibility: hidden;
    }

    span:nth-child(3) {
      height: 3px;
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

.sign_out {
  @include reset-button();

  @include flex-justify-align(space-between);
  gap: 8px;

  @include font-size(14, $medium, 22);
  color: $purple;

  transition: all 0.5s;

  &:hover {
    opacity: 0.5;
  }

  @media(max-width: 450px) {
    display: none;
  }
}

.main {

  padding: 0px 125px 20px;
  flex-grow: 1;

  @media (max-width: 1024px) {
    padding: 0px 70px 20px;
  }

  @media (max-width: 450px) {
    padding: 0px 15px 0px;
  }
}

.footer {
  position: relative;

  @media (max-width: 450px) {
    display: none;
  }
}