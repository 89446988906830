@import "styles/vars";
@import "styles/mixins";

.popup {
  z-index: 999;
  position: absolute;
  bottom: 117px;
  right: 80px;
  width: 522px;
  height: 236px;
  background: $purple;
  @include flex-justify-align(flex-start, flex-start);
  flex-direction: column;
  transition: all 1s ease-in-out;
  gap: 13px;
  transition: 0.5s;
  padding: 32px 24px 58px 48px;
  border-radius: 4px;

  &_hide {
    @extend .popup;
    pointer-events: none;
    opacity: 0;
  }

  &_close {
    cursor: pointer;
    align-self: flex-end;
    transition: all 0.5s;

    &:hover {
      opacity: 0.7;
    }
  }

  &_title {
    @include font-size(16, $medium, 20, -0.4);
    color: $white;
  }

  button {
    @include reset-button();
    @include flex-justify-align(space-between, center);
    @include font-size(10, $regular, 12);
    color: rgba($white, 0.5);
    gap: 4px;
  }

  &_socials {
    @include flex-justify-align(flex-start, center);
    gap: 24px;

    svg {
      path {
        fill: $white;
        opacity: 1;
      }

      g {
        opacity: 1;
      }
    }
  }

  &_input_wrapper {
    position: relative;
    width: 100%;

    input {
      border: none;
      width: 100%;
      height: 48px;
      padding: 8px 20px;
      border-radius: 4px;

      @include font-size(14, $medium, 32);
      color: rgba($font-color-black-secondary, 0.8);
    }

    svg {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 13px;
      opacity: 0.5;
    }
  }

  @media (max-width: 450px) {
    top: 0;
    left: 0;
    bottom: 0;

    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: $white;

    &_close {
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 1);

      padding: 6px;

      width: 24px;
      height: 24px;
    }

    &_title {
      @include font-size(24, $bold, 42);
      color: rgba($font-color-black-secondary, 0.65);
      width: 100%;

      padding-bottom: 13px;

      border-bottom: 1px solid $divider-color;
    }

    button {
      color: rgba($font-color-black-secondary, 0.65);
      @include font-size(12, $regular, 15);
      height: 20px;
    }

    &_socials {
      color: rgba($font-color-black-secondary, 0.65);
      margin-bottom: 20px;

      svg {
        width: 20px;
        height: 20px;
        path {
          fill: rgba($font-color-black-secondary, 0.65);
        }
      }
    }

    &_input_wrapper {
      input {
        background: #f2f5f7;
      }
    }
  }
}

.popup_copied {
  position: absolute;
  bottom: 20px;

  opacity: 0;

  transition: all 0.5s ease-in-out;

  @include font-size(16, $medium, 20, -0.4);
  color: $white;

  &_active {
    @extend .popup_copied;
    opacity: 1;
  }
}
