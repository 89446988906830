@import "styles/vars";
@import "styles/mixins";

.overview {
  padding-top: 34px;
  width: calc(100% - 460px);
  @include flex-justify-align(center, flex-start);
  flex-direction: column;

  @media (max-width: 1150px) {
    width: calc(100% - 411px);
    padding-top: 25px;
  }

  @media (max-width: 900px) {
    width: calc(100% - 292px);
  }

  @media (max-width: 700px) {
    width: calc(100% - 232px);
  }

  &_mobile {
    width: 100%;
    display: grid;
    padding: 0 10px;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    grid-gap: 1rem;
  }
}
