@import "styles/vars";
@import "styles/mixins";

.item {
  display: grid;
  grid-template-columns: 40% 10% 16% 1fr;
  gap: 20px;

  @media (max-width: 450px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;

    &:not(:nth-last-child(2)) {
      padding-bottom: 9px;
      border-bottom: 1px solid rgba(221, 221, 221, 1);
      margin-bottom: 16px;
    }
  }
}

.item_description {
  @include flex-justify-align(flex-start, flex-start);
  gap: 12px;

  .item_avatar {
    position: relative;
    @include flex-justify-align(center);
    width: 38px;
    height: 38px;

    background-color: $white;
    border-radius: 2px;
    span {
      @include font-size(28, $regular, 36, -0.5px);
      color: $purple;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 768px) {
      width: 38px;
      height: 38px;
      margin-bottom: 10px;
    }
  }

  &_name_option {
    p {
      @include font-size(12, $medium, 20, -0.41);
      color: rgba($font-color-black-secondary, 0.65);

      &:last-child {
        @include font-size(10, $regular, 14);
      }
    }

    span {
      @include font-size(10, $regular, 13, 0.066);
      color: $font-color-gray-secondary;
    }
  }

  @media (max-width: 450px) {
    @include flex-justify-align(flex-start);
    grid-column: 1/4;
  }
}

.item_quantity {
  @include flex-justify-align(center, flex-start);

  @include font-size(12, $regular, 20);
  color: rgba(0, 0, 0, 1);

  @media (max-width: 450px) {
    flex-direction: column;
    @include flex-justify-align(flex-start, flex-start);

    &_value {
      @include font-size(10, $regular, 12, 0.066);
      color: $font-color-black-secondary;
    }

    &_title {
      @include font-size(12, $medium, 15, -0.408);
      color: rgba($font-color-black-secondary, 0.65);
    }
  }
}

.item_price {
  @include flex-justify-align(center, flex-start);
  flex-direction: column;

  &_subtotal {
    @include font-size(12, $regular, 20);
    letter-spacing: 0.03em;
    color: rgba($font-color-black-secondary, 0.65);
  }

  &_taxes {
    @include flex-justify-align(center);
    flex-direction: column;

    @include font-size(10, $regular, 13);
    letter-spacing: 0.066px;
    color: $font-color-gray-secondary;

    &_tax {
      @include flex-justify-align(flex-start, flex-start);
      flex-direction: column;
      p:last-child {
        @include font-size(8, $regular, 13);
      }
    }
  }
}

.item_total {
  @include font-size(12, $medium, 20);
  letter-spacing: 0.03em;
  color: rgba($font-color-black-secondary, 0.65);
  @include flex-justify-align(flex-end, flex-start);

  @media (max-width: 450px) {
    @include flex-justify-align(flex-end, center);
  }
}

.item_mob_unit_price {
  @include flex-justify-align(flex-end, flex-start);
  flex-direction: column;
  color: $font-color-gray-secondary;

  p {
    &:first-child {
      @include font-size(12, $medium, 15, -0.408);
      color: rgba($font-color-black-secondary, 0.65);
    }

    &:last-child {
      @include font-size(10, $regular, 12, 0.07);
    }
  }
}

.item_mob_taxes {
  color: $font-color-gray-secondary;

  .item_price_taxes_tax {
    display: flex;
    flex-direction: row;
    span {
      &:first-child {
        @include font-size(8, $regular, 10);
      }

      &:last-child {
        @include font-size(8, $regular, 10);
      }
    }
  }
}

.item_mob_total {
  @extend .item_mob_unit_price;
  @include flex-justify-align(flex-start, flex-end);
}
