@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.header {
  @include flex-justify-align(space-between);
  flex-direction: row-reverse;

  padding: 38px 28px 49px;

  .close_icon {
    @include black-close-icon;
  }

  @media (max-width: 450px) {
    padding: 21px 19px 24px;

    @include flex-justify-align(flex-start);
    flex-direction: row;

    .close_icon {
      display: none;
    }

    .left_arrow_icon {
      display: block;
    }
  }
}
