@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.payment_modal {
  width: 720px;
  height: max-content;
  max-height: 95%;
  max-width: 95%;
  overflow-y: scroll;

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

.header {
  padding: 38px 28px 49px;

  svg:nth-child(1) {
    @include black-close-icon;
  }

  svg:nth-child(2) {
    display: none;
  }

  @media (max-width: 450px) {
    padding: 21px 19px 24px;

    @include flex-justify-align(flex-start);

    svg:nth-child(1) {
      display: none;
    }

    svg:nth-child(2) {
      display: block;
      path {
        fill: rgba(0, 0, 0, 1);
      }
    }
  }
}

.title {
  padding: 0 20px 10px;

  @include font-size(38, $bold, 49);
  color: $font-color-black-secondary;

  @media (max-width: 450px) {
    padding: 0 20px;

    @include font-size(20, $bold, 42);
    color: rgba($font-color-black-secondary, 0.65);
  }
}

.subtitle {
  padding: 0 20px 20px;

  @include font-size(14, $regular, 18);
  color: $grey;

  span {
    @include font-size(14, $medium, 18);
    color: $font-color-black-secondary;
  }
}

.express_card {
  padding: 36px 44px 45px;
  margin: 0 20px 10px;

  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);

  &_title {
    @include font-size(16, $bold, 20);
    color: rgba($font-color-black-secondary, 0.65);

    margin-bottom: 13px;
  }

  @media (max-width: 450px) {
    margin: 0 15px 10px;
    padding: 18px;

    &_title {
      @include font-size(14, $bold, 18);
    }
  }
}

.or {
  margin: 0 20px;
  @include font-size(14, $regular, 18);
  color: rgba(120, 137, 149, 1);

  @media (max-width: 450px) {
    margin: 0 15px;
  }
}
