@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";


.storefront {
  position: fixed;
  background-color: $white;
  height: 100vh;
  width: 100%;

  @media (max-width: 450px) {
    height: auto;
    position: initial;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.storefront_wrapper {
  display: flex;
  height: calc(100% - 447px);

  @media (max-width: 1024px) {
    height: calc(100% - 415px);
  }

  @media (max-width: 450px) {
    height: calc(100% - 140px);
    margin-top: 60px;
    overflow-y: hidden;
  }
}

.storefront__content {
  position: relative;
  width: 100%;

  &_error {
    text-align: center;
  }

  @media (max-width: 772px) {
    padding: 0 9%;
  }

  @media (max-width: 765px) {
    padding: 0 2%;
  }

  @media (max-width: 450px) {
    padding: 0 2%;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.header_two {
  header {
    padding: 26px 10% 30px;
    @include flex-justify-align(space-between, flex-start);

    svg {
      cursor: pointer;
      height: 24px;
      width: 24px;
      background-color: $font-color-black-secondary;
      border-radius: 50%;
      padding: 7px;

      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}