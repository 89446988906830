@import "styles/vars";
@import "styles/mixins";

.root {
  padding: 0px 5% 20px;
  position: relative;
  width: 100%;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px 4px 0px 0px;
  max-height: 40vh;
  margin-top: 20px;
  overflow: scroll;

  @media (max-width: 1024px) {
    max-height: 389px;
  }

  @media (max-width: 450px) {
    max-height: 40vh;
    overflow: hidden;
    padding: 24px 12px 0px 6px;
  }
}

.title {
  @include flex-justify-align(space-between, flex-start);
  top: 0;
  position: sticky;
  z-index: 2;

  padding: 35px 0px 7px;

  @include font-size(16, $medium, 21);
  color: $font-color-black-secondary;

  background-color: #fff;
  border-bottom: 1px solid $divider-color;

  @media (max-width: 450px) {
    z-index: 1;
    position: initial;
    padding: 0px 0px 7px;
  }
}

.no_subscription_title {
  @include font-size(16, $medium, 21);
  color: $font-color-black-secondary;
  padding: 24px 0 13px;
}

.variants {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
}

.upgrade_btn {
  height: 30px;
  width: 88px;
  margin-bottom: 13px;

  border: none;
  background-color: $purple;
  border-radius: 21px;

  color: $white;
  @include font-size(14, $bold, 18);

  transition: all 0.5s;

  &:hover {
    opacity: 0.7;
  }
}

.no_subscription {
  &_description {
    color: $violet;
    @include font-size(16, $thin, 24);
    margin-bottom: 31px;
  }

  &_available {
    &_title {
      color: $purple;
      @include font-size(14, $regular, 24);
    }

    &_subtitle {
      color: $violet;
      @include font-size(14, $thin, 24);
    }
  }
}

.low_stock {
  @include font-size(16, $regular, 20);
  color: rgba(255, 58, 41, 1);
}
