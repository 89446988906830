@import "styles/vars";
@import "styles/mixins";

$grey: rgba(25, 28, 35, 0.2);

.variation {
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .variation_radio_button {
    @include flex-justify-align(center, center);

    border: 1px solid $light-blue;
    border-radius: 8px;

    padding: 12px 8px;

    @include font-size(12, $medium, 14);
    color: $light-blue;
  }

  input {
    display: none;
  }
}

.variation_selected {
  @extend .variation;
  .variation_radio_button {
    @extend .variation_radio_button;
    border: 1px solid $purple;
    color: $purple;
  }
}

.variation_sold_out {
  @extend .variation;

  .variation_radio_button {
    @extend .variation_radio_button;
    border: 1px solid rgba(144, 149, 166, 0.1);
    color: rgba(144, 149, 166, 0.65);

    opacity: 0.6;
  }
}
