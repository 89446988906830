@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.summary {
  padding: 37px 54px 54px;

  background-color: $background-color-secondary;
  box-shadow: 0px 4px 16px $shadow;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  gap: 15px;

  &_title {
    @include font-size(20, $medium, 25);
    color: $font-color-black-secondary;
  }

  &_list {
    display: flex;
    flex-direction: column;

    padding-bottom: 16px;

    gap: 10px;

    border-bottom: 1px solid $divider-color;

    &_subtotal {
      @include flex-justify-align(space-between);
      p {
        &:first-child {
          @include font-size(16, $regular, 24);
          color: $font-color-black-secondary;
        }
        &:last-child {
          @include font-size(16, $medium, 24);
          color: $font-color-black-secondary;
        }
      }
    }

    &_shipping {
      @include flex-justify-align(space-between);
      p {
        &:first-child {
          @include font-size(14, $regular, 24);
          color: $font-color-black-secondary;
        }
      }
    }

    &_taxes__included {
      @include flex-justify-align(space-between);
      p {
        &:first-child {
          @include font-size(14, $regular, 24);
          color: rgba($font-color-black-secondary, 0.5);
        }

        &:last-child {
          @include font-size(14, $regular, 24);
          color: rgba($font-color-black-secondary, 0.5);
        }
      }
    }

    &_taxes__excluded {
      @include flex-justify-align(space-between);
      p {
        &:first-child {
          @include font-size(14, $regular, 24);
          color: $font-color-black-secondary;
        }

        &:last-child {
          @include font-size(14, $medium, 24);
          color: $font-color-black-secondary;
        }
      }
    }
  }

  &_total {
    @include flex-justify-align(space-between);

    p {
      &:first-child {
        @include font-size(16, $medium, 24);
        color: $purple;
      }

      &:last-child {
        @include font-size(16, $medium, 24);
        color: $purple;
      }
    }
  }

  &_buttons {
    @include flex-justify-align(space-between);
    flex-wrap: wrap;
    gap: 0.5rem;

    &_continue {
      background-color: $white;
      padding: 10px;
      border: 1px solid $purple;
      border-radius: 4px;
      text-align: center;
      justify-content: center;
      display: flex;
      min-width: 152px;
      height: 45px;
      flex-grow: 1;

      color: $purple;
      @include font-size(16, $medium, 20);

      &_icon {
        width: 20px;
        height: 20px;
        @include flex-justify-align(center);

        svg {
          width: 13px;
          height: 8px;
          transform: rotate(0deg);
          color: $purple;
          path {
            stroke: $purple;
            fill: $purple;
          }
        }
      }

      transition: all 0.5s;

      &:hover {
        opacity: 0.4;
      }
    }

    &_checkout {
      flex-grow: 1;
      height: 45px;
      background: $background-button;
      padding: 10px;
      border-radius: 4px;
      text-align: center;
      border: none;
      min-width: 152px;

      transition: all 0.5s;

      color: $white;
      @include font-size(16, $medium, 20);

      &:hover {
        opacity: 0.4;
      }

      &:not(:hover):disabled {
        opacity: 0.2;
      }
    }

    @media (max-width: 450px) {
      flex-direction: column;
      width: 100%;
      &_continue {
        width: 100%;
      }

      &_checkout {
        width: 100%;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 20px;
  }
}

.fee_value {
  @include font-size(14, $regular, 24);
  color: rgba($font-color-black-secondary, 0.3);
  white-space: nowrap;

  &_active {
    @include font-size(14, $medium, 24);
    color: $font-color-black-secondary;
  }
}
