@import "styles/vars";
@import "styles/mixins";

.root {
  @include flex-justify-align(flex-start, flex-end);
  flex-direction: column;
  margin: 20px 0;
}

.subtotal_list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: calc(50% - 40px);
  border-top: 1px solid rgba(0, 0, 0, 1);
  padding: 4px 0 14px;

  .subtotal {
    @include flex-justify-align(space-between);
    p {
      @include font-size(12, $medium, 14);
      color: rgba(0, 0, 0, 1);

      &:last-child {
        @include font-size(12, $regular, 14);
      }
    }
  }

  @media (max-width: 600px) {
    width: 60%;
  }

  @media (max-width: 450px) {
    width: 100%;
    .subtotal {
      width: 100%;
    }
  }
}

.total {
  border-top: 1px solid rgba(0, 0, 0, 1);
  padding: 4px 0;
  min-width: calc(50% - 40px);
  @include flex-justify-align(space-between);

  p {
    @include font-size(14, $medium, 14);
    color: rgba(0, 0, 0, 1);

    &:last-child {
      @include font-size(14, $medium, 14);
    }
  }

  @media (max-width: 600px) {
    width: 60%;
  }

  @media (max-width: 450px) {
    width: 100%;
    p {
      @include font-size(14, $medium, 14);

      &:last-child {
        @include font-size(14, $medium, 14);
      }
    }
  }
}
