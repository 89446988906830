@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.setting_box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  padding: 28px 34px;
  box-shadow: 2px 8px 20px rgba(203, 203, 203, 0.35);
  border-radius: 4px;
  width: 100%;
  &_name {
    color: $font-color-black-secondary;
    @include font-size(34, $regular, 44);
    text-transform: capitalize;
  }
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 2px solid $purple;
  }
  @media (max-width: 450px) {
    padding: 20px;
    gap: 2px;
    &_name {
      @include font-size(18, $regular, 23);
    }
  }
}

.languages {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  @media (max-width: 450px) {
    gap: 14px;
  }
}

.setting_box_active {
  border: 2px solid $purple;
}
