@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.modal {
  @include flex-justify-align(center);

  z-index: 200;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba($font-color-black, 0.6);

  transition: all 1s ease-in-out;
  transition: 0.5s;

  &_show {
    @extend .modal;
    opacity: 1;
  }

  &_hide {
    @extend .modal;
    pointer-events: none;
    opacity: 0;
  }

  &_wrapper {
    cursor: auto !important;
    position: fixed;
    display: flex;
    flex-direction: column;

    background: $white;
    box-shadow: 0px 32px 64px rgba(140, 136, 150, 0.3);
    border-radius: 4px;

    padding: 0;

    transform: scale(0.5);
    transition: all 0.5s ease-in-out;

    width: 720px;
    height: 842px;
    max-height: 100%;

    overflow-y: scroll;

    &_active {
      @extend .modal_wrapper;
      transform: scale(1);
      outline: none;
    }
  }

  @media (max-width: 450px) {
    &_wrapper {
      width: 100%;
      height: 100%;
      background: rgba(252, 252, 255, 1);
    }
  }
}

.header {
  padding: 38px 28px 49px;
  display: flex;
  justify-content: flex-end;

  .left_arrow_icon {
    display: none;
  }

  .close_icon {
    @include black-close-icon;
  }

  @media (max-width: 450px) {
    padding: 20px;
    justify-content: flex-start;
    .left_arrow_icon {
      display: block;
    }

    .close_icon {
      display: none;
    }
  }
}

.title {
  @include font-size(38, $bold, 49);
  color: $font-color-black-secondary;
  padding: 0 20px 30px;

  @media (max-width: 450px) {
    @include font-size(20, $bold, 42);
    color: rgba($font-color-black-secondary, 0.65);
    padding: 0 20px 10px;
  }
}

.address {
  padding: 0 20px 100px;

  &_title {
    @include font-size(20, $bold, 25);
    color: rgba($font-color-black-secondary, 0.65);
    margin-bottom: 9px;
  }

  &_value {
    @include font-size(16, $regular, 20);
    color: rgba($font-color-black-secondary, 0.65);
  }

  @media (max-width: 450px) {
    padding: 0 20px 20px;
    background-color: transparent;
    &_title {
      @include font-size(14, $bold, 18);
    }

    &_value {
      @include font-size(14, $regular, 18);
    }
  }
}

.operation_hours {
  width: 100%;
  padding: 0 20px;

  &_title {
    @include font-size(20, $bold, 25);
    color: rgba($font-color-black-secondary, 0.65);
    margin-bottom: 10px;
  }

  &_values {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &_value {
      @include flex-justify-align(space-between);

      @include font-size(20, $regular, 20);
      color: rgba($font-color-black-secondary, 0.65);
    }
  }

  @media (max-width: 450px) {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
    border-radius: 4px;
    padding: 20px;
    margin: 0 15px;
    width: auto;

    &_title {
      @include font-size(14, $bold, 18);
    }

    &_values {
      &_value {
        @include font-size(14, $regular, 18);
      }
    }
  }
}
