@import "styles/vars";
@import "styles/mixins";

.payment_terms {
  @include flex-justify-align(space-between);
  padding: 13px;
  border-top: 1px solid rgba(221, 221, 221, 1);
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  margin-bottom: 32px;

  &_left {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &_title {
      @include font-size(8, $medium, 10);
      letter-spacing: 0.08em;
      color: $font-color-gray-third;
    }

    &_text {
      @include font-size(12, $regular, 15);
      color: rgba(136, 136, 136, 1);
    }
  }

  &_right_btns {
    display: flex;
    gap: 10px;

    &_accept_btn {
      @include purple-btn();
      padding: 0 29px;
      height: 34px;
      width: auto;

      @include font-size(14, $bold, 20);
    }

    &_cancel_btn {
      @include white-btn();
      padding: 7px 13px 9px;
      height: auto;
      width: auto;
      border: 1px solid rgba(136, 136, 136, 1);
      color: rgba(136, 136, 136, 1);

      @include font-size(14, $medium, 18);
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &_title {
      @include font-size(8, $medium, 10);
      letter-spacing: 0.08em;
      color: $font-color-gray-third;
    }

    &_text {
      @include font-size(12, $regular, 15);
      color: rgba(136, 136, 136, 1);
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
}
