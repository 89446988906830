@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.checkbox {
  display: flex;
  @include font-size(18, $medium, 20);
  margin: 10px 0;
  color: $font-color-black-secondary;
  transition: all 1s ease-out;
  align-items: center;
  gap: 10px;

  &_input {
    display: none;
  }

  &_input:checked + .checkbox_label {
    border: 1px solid #e2d1f9;
    svg {
      opacity: 1;
    }
    & + .checkbox_name {
      color: $font-color-black-secondary;
    }
  }

  &_input:disabled + .checkbox_label {
    border: 1px solid #e2d1f9;
    & + .checkbox_name {
      color: rgba(25, 28, 35, 0.2);
    }
  }

  &_label {
    margin: 0;
    position: relative;
    cursor: pointer;

    width: 20px;
    height: 20px;
    border: 1px solid #e2d1f9;
    border-radius: 3px;

    transition: 0.3s;

    svg {
      width: 11px;
      height: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: rgba(65, 29, 117, 1);

      transition: 0.5s;
    }
  }
}
