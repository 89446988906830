@import "styles/vars";
@import "styles/mixins";

.orders {
  padding: 0 47px 0 72px;

  @media (max-width: 1190px) {
    padding: 0;
  }
}
