@import "styles/vars";
@import "styles/mixins";

.process_modal {
  display: flex;
  width: 560px;
  padding: 29px 46px 53px;
  flex-direction: column;

  &_header {
    margin: 10px 0;
    display: flex;
    align-items: center;
    color: $violet;
    @include font-size(16, $thin, 40);
    margin-bottom: 2em;
  }

  h2 {
    color: $violet;
    @include font-size(24, $medium, 30);
    @media (max-width: 450px) {
      padding-bottom: 18px;
      border-bottom: 1px solid $divider-color-secondary;
      margin-bottom: 0;
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    padding: 20px 20px 0;
  }
}

.close_icon {
  align-self: flex-end;
  margin-bottom: 26px;
  transition: all 0.5s;

  &:hover:not(:disabled) {
    opacity: 0.8;
  }

  @media (max-width: 450px) {
    align-self: flex-start;
    border: 2px solid $purple;
    border-radius: 50%;
    padding: 8px;
    color: $purple;
    margin-bottom: 47px;
    width: 40px;
    height: 40px;
  }
}

.content {
  @include flex-justify-align(center, space-between);
  flex-direction: column;
  gap: 20px;
}

.order {
  @include flex-justify-align(space-between, center);
  &_id {
    color: $font-color-black-secondary;
    @include font-size(16, $regular, 24);
  }

  &_price {
    color: rgba($violet, 0.5);
    @include font-size(16, $regular, 24);
  }
}

.items {
  @include flex-justify-align(space-between, center);

  &_labels {
    color: $font-color-black-secondary;
    @include font-size(16, $regular, 24);
  }

  &_value {
    color: rgba($violet, 0.5);
    @include font-size(16, $regular, 24);
  }
}

.delivery {
  @include flex-justify-align(space-between, center);

  &_label {
    color: $font-color-black-secondary;
    @include font-size(16, $regular, 24);
  }

  &_value {
    color: rgba($violet, 0.5);
    @include font-size(16, $regular, 24);
  }
}

.shipping_provider {
  @include flex-justify-align(space-between, center);

  &_label {
    color: $font-color-black-secondary;
    @include font-size(14, $medium, 24);
  }

  &_input {
    @include input();
  }

  @media (max-width: 450px) {
    flex-direction: column;
    text-transform: uppercase;
    @include flex-justify-align(space-between, stretch);
    width: 100%;

    &_label {
      color: rgba($font-color-black-secondary, 0.4);
    }

    &_input {
      @include input();
      width: 100%;
      max-width: 100%;
      text-align: left;
    }
  }
}

.tracking {
  @include flex-justify-align(space-between, center);

  &_label {
    color: $font-color-black-secondary;
    @include font-size(14, $medium, 24);
  }

  &_input {
    @include input();
  }

  @media (max-width: 450px) {
    flex-direction: column;
    width: 100%;
    @include flex-justify-align(space-between, stretch);
    text-transform: uppercase;

    &_label {
      color: rgba($font-color-black-secondary, 0.4);
    }

    &_input {
      @include input();
      width: 100%;
      max-width: 100%;
      text-align: left;
    }
  }
}

.shipped_btn {
  @include purple-btn();
  align-self: center;
  &_wrapper {
    align-self: center;
  }

  @media (max-width: 450px) {
    width: 100%;
    @include font-size(16, $medium, 20);

    &_wrapper {
      width: 100%;
      background-color: $background-color;
      padding: 19px 36px;
      box-shadow: 0px 4px 16px $shadow;
    }
  }
}

.pickup_select {
  @extend .shipping_provider;
  padding-bottom: 100px;
  &_label {
    color: $font-color-black-secondary;
    @include font-size(16, $medium, 24);
  }

  @media (max-width: 450px) {
    flex-direction: column;
    padding-bottom: 73px;
    width: 100%;
    @include flex-justify-align(space-between, stretch);
    text-transform: none;
    &_label {
      text-transform: uppercase;
      color: rgba($font-color-black-secondary, 0.4);
    }
  }
}
