@import "styles/vars";
@import "styles/mixins";
.react_tabs {
  -webkit-tap-highlight-color: transparent;
  margin: 0 47px 0 72px;
  &__tab_list {
    border-bottom: 1px solid $divider-color-secondary;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 22px;
    overflow: scroll;
    scrollbar-width: none;
    padding-left: 0px;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 1100px) {
      margin: 0 32px 20px;
    }
    @media (max-width: 1100px) {
      margin: 0 3% 20px;
    }
    @media (max-width: 679px) {
      margin: 0 3% 20px;
      justify-content: initial;
    }
  }

  &__tab {
    z-index: 1;
    display: inline-block;
    border-bottom: 2px solid transparent;
    list-style: none;
    cursor: pointer;
    transition: border-bottom-color 0.3s ease;
    color: $font-color-black-secondary;
    white-space: nowrap;
    @include font-size(16, $medium, 20);
    padding-bottom: 17px;
    &__selected {
      border-bottom-color: $purple;
      color: $purple;
    }
    @media (max-width: 450px) {
      @include font-size(14, $medium, 20);
      padding-bottom: 20px;
    }
  }

  &__tab_panel {
    display: none;
    &__selected {
      svg {
        overflow: visible;
      }
      max-width: 100%;
      display: block;
      border-radius: 2px;
    }
  }
  @media (max-width: 1100px) {
    margin: 0;
  }
}
