@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.breadcrumbs {
  a {
    outline: none;
    color: $purple;

    @include font-size(20, $medium, 36);

    &:hover {
      outline: none;
      opacity: 0.7;
    }


    &:not(:last-child):after {
      content: " / ";
      color: rgba(0, 0, 0, 1);
    }

    &[class="active"] {
      color: rgba(108, 109, 122, 1);
      @include font-size(20, $bold, 36);
      pointer-events: none;
      cursor: default;
      text-decoration: none;

      &:after {
        content: '',
      }
    }

    &[class="active"]:first-child {
      color: rgba(0, 0, 0, 1);
      @include font-size(20, $bold, 36);

      &:after {
        content: "";
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}