@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $font-color-black-secondary;

  transition: all 0.5s;

  &_open {
    @extend .triangle;
    transform: rotate(180deg);
  }
}

.countries {
  width: 280px;
  height: 60px;
  label {
    @include font-size(14, $medium, 21);
    color: rgba(25, 28, 35, 0.4);
    text-transform: uppercase;
    margin: 0;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 60px;
  }

  @media (max-width: 450px) {
    width: 100%;
    label {
      @include font-size(12, $demibold, 20);
    }
  }
}

.dropdown {
  position: relative;

  &_btn {
    @include reset-button();

    @include flex-justify-align(space-between);

    border: 1px solid rgba(221, 227, 232, 1);
    @include font-size(16, $regular, 60);
    width: 280px;
    padding: 0 20px;
    height: 60px;
    border-radius: 3px;
    background: transparent;
    color: $font-color-black-secondary;

    &_open {
      @extend .dropdown_btn;

      border: 1px solid $purple;
    }
  }

  @media (max-width: 450px) {
    &_btn {
      width: 100%;
    }
  }
}

.menu {
  z-index: 2;
  background-color: $white;
  border-radius: 4px;
  position: absolute;
  width: 400px;

  padding: 25px;
  box-shadow: 2px 8px 20px rgba(203, 203, 203, 0.35);
  border-radius: 4px;

  @media (max-width: 450px) {
    width: 100%;
  }
}

.search_icon {
  position: absolute;
  top: 27px;

  @media (max-width: 450px) {
    top: 35px;
  }
}
