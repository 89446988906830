@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.sidebar {
  position: relative;
  width: 25%;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  z-index: 5;

  padding: 0px 10px;

  background-color: $font-color-black-secondary;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  &_wrapper {
    height: calc(100% - 176px);
    overflow: auto;
    scrollbar-width: none;
  }
  &_title {
    @include font-size(12, $demibold, 20, 1);
    margin-top: 3vh;
    color: $color-grey-secondary;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  @media (max-width: 1024px) {
    &_wrapper {
      height: calc(100% - 176px);
      overflow: auto;
    }
  }
  @media (max-width: 768px) {
    width: 200px;
    padding: 0px 4px;
    &_wrapper {
      height: calc(100% - 160px);
    }
    &_title {
      margin-top: 15%;
    }
  }
}

.sidebar__menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 10px;

  &_link {
    @include font-size(14, $demibold, 20);
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    padding: 10px 20px;
    border-radius: 5px;
    gap: 20px;

    svg {
      max-width: 20px;
      max-height: 20px;
    }

    &_name {
      margin-top: 4px;
      @include font-size(14, $demibold, 20);
    }

    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &_link_active {
    @include font-size(14, $demibold, 21);
    color: rgba(255, 255, 255, 1);
    background: $background-button;
  }

  @media (max-width: 768px) {
    gap: 0px;
    &_link {
      padding: 10px;
      gap: 3px;
    }
  }
}
