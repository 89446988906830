@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.payment {
  @include flex-justify-align(space-between);
  padding: 12px 10px;
  border-top: 1px solid $divider-color;

  &_left {
    display: flex;
    flex-direction: column;
    gap: 6px;
    opacity: 0.5;

    &_title {
      @include font-size(8, $medium, 10px);
      color: rgba(115, 115, 115, 1);
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }

    &_value {
      @include font-size(14, $regular, 14);
      letter-spacing: 0.03em;
      color: rgba($font-color-black-secondary, 0.65);
    }

    &_unpaid {
      opacity: 1;

      .payment_left_value {
        color: rgba(248, 0, 49, 1);
        @include font-size(14, $medium, 14);
      }
    }
  }

  &_button {
    @include reset-button();
    width: 106px;
    height: 34px;

    background-color: $purple;
    border-radius: 4px;

    @include font-size(14, $bold, 20);
    color: $white;

    transition: all 0.3s;

    &:disabled {
      background-color: rgba(228, 234, 238, 1);

      @include font-size(14, $medium, 20);
      color: $font-color-black-secondary;
    }
  }
}

.pickup {
  @include flex-justify-align(space-between);
  padding: 9px 10px 10px;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  background-color: rgba(247, 248, 253, 1);

  p {
    &:first-child {
      @include font-size(14, $medium, 20, -0.04);
      color: rgba(25, 28, 35, 0.65);
      padding-bottom: 6px;
    }
  }

  .qr {
    @include flex-justify-align(space-between);
    gap: 10px;

    p {
      @include font-size(12, $regular, 13, 0.07);
      color: #667085;
      max-width: 99px;
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  button {
    @include reset-button();
    text-align: right;
    color: $purple;

    @include font-size(12, $regular, 13, 0.07);
  }
}

.cancelled {
  height: 77px;

  @media (max-width: 600px) {
    height: 40px;
  }
}
