@import "styles/vars";
@import "styles/mixins";

.modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($font-color-black, 0.8);
  @extend .modal;
  display: block;

  &_wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;

    background: white;
    min-width: 700px;
    max-height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    @media (max-width: 850px) {
      min-width: 80%;
      max-height: 90%;
    }
    @media (max-width: 562px) {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
    }
  }
  &_close {
    position: absolute;
    cursor: pointer;
    top: -66px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(100% - 40px);
    &:hover {
      opacity: 0.5;
    }
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: rgba(32, 39, 43, 0.8);
      }
    }
    @media (max-width: 562px) {
      top: 20px;
      background-color: $purple;
      width: 20px;
      height: 20px;
      z-index: 9999;
      svg {
        width: 8px;
        height: 8px;
        path {
          fill: #fff;
        }
      }
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 40px 50px;
    padding-bottom: 0px;
  }
  @media (max-width: 772px) {
    padding: 20px 20px 40px 20px;
  }
  @media (max-width: 562px) {
    &_wrapper {
      border-radius: 0px;
    }
    &_content {
      padding: 0px;
      height: 100vh;
      margin: 82px 0px 150px;
    }
  }
}

.title {
  @include font-size(24, $bold, 40, 1);
  color: $black;
  margin-bottom: 40px;
  @media (max-width: 562px) {
    position: fixed;
    padding: 20px;
    width: 100%;

    @include font-size(14, $bold, 21);
    color: $black;
    top: 0px;
    border-bottom: 1px solid $divider-color;
  }
}

.item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(228, 234, 238, 1);
  padding-bottom: 20px;
  &_avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    width: 84px;
    height: 84px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid rgba(247, 248, 253, 1);
    span {
      @include font-size(28, $regular, 36, -0.5px);
      color: rgba(104, 49, 243, 1);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &_btn {
      position: absolute;
      @include reset-button;
      @include font-size(12, $regular, 11);
      width: 92px;
      height: 24px;
      color: #fff;
      bottom: -10px;
      background-color: rgba(91, 107, 130, 1);
      border-radius: 12px;
      transition: 0.3s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  &_description {
    display: flex;
    justify-content: space-between;
    h3 {
      margin-right: 11px;
      color: $black;
      @include font-size(14, $medium, 24);
      flex: 1;
      div {
        @include font-size(12, $thin, 20);
      }
    }
  }
  &_quantity {
    width: 50px;
    opacity: 0.6;
    height: 36px;
    align-self: flex-start;
    border: 1px solid rgba(225, 225, 225, 1);
    border-radius: 100px;
    background-color: $grey;
    color: rgba($font-color-black-secondary, 0.7);
    text-align: center;
    margin: 10px 18px 10px 10px;
    @include font-size(16, $bold, 24);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    &_quantity {
      height: 32px;
      margin: 0px 10px 10px 10px;
      @include font-size(13, $bold, 24);
    }
    &_description {
      h3 {
        @include font-size(13, $medium, 20);
      }
    }
    &_avatar {
      width: 78px;
      height: 78px;
      &_btn {
        width: 78px;
        height: 78px;
      }
    }
  }

  @media (max-width: 562px) {
    margin: 0 20px;
    padding-bottom: 0px;
    &_description {
      display: flex;
      gap: 10px;
      padding-bottom: 40px;
      h3 {
        margin-right: 0px;
        @include font-size(13, $medium, 20);
      }
    }
    &_avatar {
      margin-right: 0px;
      border: 1px solid rgba(151, 151, 151, 1);
    }
    &_quantity {
      margin: 0;
    }
  }
}

.price_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0 0px;
  &_item {
    @include flex-justify-align(space-between, center);
    &_description {
      display: flex;
      flex-direction: column;
      &_label {
        @include font-size(14, $medium, 24);
        color: $font-color-black-secondary;
      }
      &_optional {
        @include font-size(12, $regular, 30);
        color: rgba($font-color-black-secondary, 0.6);
      }
    }
    &_input {
      @include font-size(16, $regular, 24);
      padding: 20px;
      color: $font-color-black-secondary;
      border: 1px solid rgba(221, 227, 232, 1);
      transition: all 0.3s ease-in-out;
      height: 60px;
      width: 160px;
      text-align: right;
      &::placeholder {
        color: rgba($font-color-black-secondary, 0.3);
      }
      &:focus {
        border: 1px solid $font-color-black-secondary;
      }
    }
  }

  @media (max-width: 562px) {
    padding: 20px 20px;
    &_item {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &_description {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      &_input {
        width: 100%;
        margin-top: 10px;
        text-align: left;
      }
    }
  }
}

.price_form_item_input_tax_type {
  @extend .price_form_item_input;
  width: 320px;
  @media (max-width: 562px) {
    width: 100%;
  }
}

.tax_included {
  &_container {
    width: 65px;
    height: 26px;
    border-radius: 40px;
    background-color: rgba(196, 196, 196, 0.5);
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    user-select: none;
    position: relative;
    margin: 15px 0;
  }
  position: absolute;
  top: 2px;
  background-color: $light-violet;
  cursor: pointer;
  @include flex-justify-align(center, center);
  border-radius: 40px;
  width: 34px;
  height: 20px;
  min-width: 46px;
  left: 28px;
  min-width: unset;
  box-sizing: border-box;
  transition: all 0.3s ease;
  svg {
    margin-top: 2px;
  }
}

.tax_included_check {
  @extend .tax_included;
  background-color: rgba(226, 226, 234, 1);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
  left: 2px;
}

.add_price_footer {
  position: sticky;
  width: calc(100% + 100px);
  margin: 0;
  margin-left: -50px;
  padding: 0;
  @include flex-justify-align(space-between, center);
  background-color: rgba(239, 239, 239, 1);
  padding: 30px 50px;
  height: 100px;
  &_btn {
    width: 170px;
    height: 60px;
    background-color: $purple;
    border: none;
    @include font-size(16, $demibold, 20, 1.33);
    color: white;
    text-transform: uppercase;
    border-radius: 4px;
    &:disabled {
      background-color: rgba(151, 151, 151, 0.2);
    }
  }
  &_left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &_title {
      @include font-size(12, $regular, 14);
      color: $font-color-black-secondary;
    }
    &_price {
      @include font-size(24, $medium, 20);
      color: $font-color-black-secondary;
    }
  }
  @media (max-width: 562px) {
    bottom: 0px;
    height: 150px;
    position: absolute;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    margin-left: -20px;
    align-items: stretch;
    &_left {
      flex-direction: row;
      @include flex-justify-align(space-between, center);
      &_price {
        @include font-size(20, $medium, 20);
      }
    }
    &_btn {
      width: 100%;
      height: 60px;
    }
  }
}
