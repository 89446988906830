@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;

  padding-top: 100px;

  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.accordion_sidebar {
  background-color: rgba(247, 248, 253, 1);
  padding: 27px 22px 0px;
  text-transform: capitalize;
}
