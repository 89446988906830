@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $font-color-black-secondary;

  @include flex-justify-align(center);
  flex-direction: column;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.loading {
  @include flex-justify-align(center);
  align-content: center;
  gap: 50px;
  padding-bottom: 37px;

  width: 100%;

  h2 {
    @include font-size(50, $bold, 78);
    color: $white;
  }

  div {
    width: 78px;
    height: 78px;

    svg:first-child {
      width: 74px;
      height: 82px;
      top: -3px;
      left: 6px;
    }

    svg:last-child {
      width: 76px;
      height: 78px;
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    flex-direction: column;
    gap: 15px;

    h2 {
      @include font-size(20, $bold, 25);
    }

    div {
      width: 30px;
      height: 30px;

      svg:first-child {
        width: 30px;
        height: 32px;
        top: -1px;
        left: 2px;
      }

      svg:last-child {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.process_payment {
  @include flex-justify-align(center);
  gap: 40px;

  padding: 15px 30%;

  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  p {
    @include font-size(20, $bold, 25);
    color: $white;
  }

  @media (max-width: 450px) {
    padding: 15px 30px;

    svg {
      width: 20px;
      height: 20px;
    }

    p {
      @include font-size(16, $bold, 20);
    }
  }
}

.send_order {
  @include flex-justify-align(center);
  flex-direction: column;

  padding: 15px 30%;

  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  &_top {
    @include flex-justify-align(center);
    gap: 40px;

    p {
      @include font-size(20, $bold, 25);
      color: $white;
    }
  }

  @media (max-width: 450px) {
    padding: 15px 30px;

    &_top {
      svg {
        width: 20px;
        height: 20px;
      }

      p {
        @include font-size(16, $bold, 20);
      }
    }
  }
}

.item {
  display: flex;
  gap: 30px;
  padding-left: 90px;

  .quantity {
    @include flex-justify-align(center);
    width: 24px;
    height: 24px;
    padding-top: 2px;
    background: rgba(107, 105, 116, 1);
    box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
    border-radius: 4px;

    @include font-size(14, $medium, 24);
    color: $font-color-black-secondary;
  }

  .name {
    padding-top: 2px;
    color: $white;
    @include font-size(18, $thin, 23);
  }

  @media (max-width: 450px) {
    margin-top: 20px;
    padding-left: 0px;
    width: 100%;
  }
}
