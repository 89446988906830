@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.card {
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;
  position: relative;

  margin: 0 20px 20px;
  padding: 36px 20px;

  @include flex-justify-align(space-between);

  &_left {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &_title {
      display: flex;
      flex-direction: column;
      @include font-size(16, $bold, 20);
      color: rgba($font-color-black-secondary, 0.65);

      span {
        @include font-size(14, $regular, 18);
      }

      p {
        @include font-size(11, $regular, 14);
        color: rgba(120, 137, 149, 1);
      }
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 10px;

    &_right {
      width: 100%;
    }
  }
}

.logos {
  display: flex;
  gap: 8px;
}

.stripe_text {
  height: 20px;
  img {
    width: 42.22px;
    height: 20px;
  }
  span {
    @include font-size(11, $regular, 20);
    color: $grey;
  }

  @media (max-width: 450px) {
    img {
      position: absolute;
      top: 26px;
      right: 17px;
    }
  }
}

.pay_btn {
  @include reset-button();
  @include purple-btn();
  height: 45px;
  width: 184px;

  @include font-size(16, $medium, 20);

  @media (max-width: 450px) {
    width: 100%;
  }
}
