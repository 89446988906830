@import "styles/vars";
@import "styles/mixins";

.content {
  display: flex;
  flex-direction: column;
  &_header {
    margin: 10px 0;
    display: flex;
    align-items: center;
    color: $violet;
    @include font-size(16, $thin, 40);
    margin-bottom: 2em;
  }
  ol {
    list-style-type: none;
    padding: 0;
  }
  li {
    &:first-child {
      border-top: 1px solid rgba(120, 115, 137, 0.2);
    }
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(120, 115, 137, 0.2);
    align-content: center;
    padding: 20px;
    @media (max-width: 1024px) {
      box-shadow: none;
    }
  }
  @media (max-width: 1024px) {
    &_header {
      margin: 0;
      @include font-size(11, $thin, 40);
    }
    li {
      padding: 10px 0;
    }
  }
}

.value {
  color: $font-color-black-secondary;
  @include font-size(14, $medium, 18);
  &_paid {
    @extend .value;
    color: rgba($font-color-black-secondary, 0.4);
  }
  @media (max-width: 1024px) {
    @include font-size(12, $medium, 20);
  }
}
.date {
  color: $violet;
  @include font-size(14, $regular, 20);
  @media (max-width: 1024px) {
    @include font-size(10, $medium, 20);
  }
}

.status {
  color: rgba(139, 135, 149, 1);
  @include font-size(10, $medium, 20);
  text-align: right;
  &_type {
    color: $font-color-black-secondary;
    text-align: right;
    @include font-size(12, $medium, 20);
  }
}

.btn {
  background: $background-button;
  align-self: center;
  border: none;
  margin-top: 37px;
  border-radius: 4px;
  width: max-content;
  padding: 20px 85px;
  transition: all 0.3s ease;
  color: #fff;
  @include font-size(18, $medium, 20);
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.2;
  }
  @media (max-width: 1024px) {
    margin-top: 20px;
    @include font-size(14, $medium, 20);
  }
}

.close_icon {
  align-self: flex-end;
  margin-bottom: 26px;
  transition: all 0.5s;
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
  @media (max-width: 450px) {
    align-self: flex-start;
    color: $purple;
    margin-bottom: 53px;
  }
}

.title {
  @include font-size(24, $medium, 30);
  color: $violet;
  @media (max-width: 450px) {
    @include font-size(16, $medium, 21);
    color: $font-color-black-secondary;
  }
}

.name {
  @include font-size(16, $bold, 24);
  color: $font-color-black-secondary;
  @media (max-width: 450px) {
    color: rgba($font-color-black-secondary, 0.6);
    @include font-size(16, $regular, 40);
  }
}

.amount_due {
  @include flex-justify-align(space-between);
  color: $font-color-black-secondary;
  &_label {
    @include font-size(16, $regular, 24);
  }
  &_value {
    @include font-size(16, $bold, 24);
  }
  @media (max-width: 450px) {
    flex-direction: column;
    @include flex-justify-align(space-between, flex-start);
    &_label {
      @include font-size(12, $regular, 18);
      color: rgba($violet, 0.5);
    }
    &_value {
      @include font-size(16, $bold, 24);
      color: $font-color-black-secondary;
    }
    gap: 9px;
    width: calc(100% + 20px);
    margin-left: -10px;
    padding: 0px 10px 25px;

    border-bottom: 1px solid rgba(120, 115, 137, 0.2);
  }
}

.email {
  @include flex-justify-align(space-between);
  margin-bottom: 32px;
  &_label {
    @include font-size(14, $medium, 24);
    color: $font-color-black-secondary;
  }
  &_input {
    width: 240px;
    height: 60px;
    padding: 0 20px;
    text-align: right;
    @include font-size(16, $regular, 24);
    border: 1px solid rgba(221, 227, 232, 1);
    border-radius: 4px;
    text-align: right;
    color: $font-color-black-secondary;
  }
  @media (max-width: 450px) {
    margin-top: 25px;
    flex-direction: column;
    @include flex-justify-align(space-between, flex-start);
    &_input {
      width: 100%;
      text-align: left;
    }
    margin-bottom: 30px;
  }
}

.message {
  display: flex;
  flex-direction: column;
  gap: 15px;
  &_label {
    @include font-size(14, $medium, 24);
    color: $font-color-black-secondary;
  }
  &_input {
    padding: 15px;
    height: 310px;
    @include font-size(14, $regular, 18);
    border: 1px solid rgba(221, 227, 232, 1);
    border-radius: 4px;
    resize: none;
    margin-bottom: 32px;
  }
  @media (max-width: 450px) {
    &_input {
      height: 310px;
      margin-bottom: 15px;
    }
  }
}

.send_reminder {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &_form {
    display: flex;
    flex-direction: column;
  }
  &_button {
    align-self: center;
    padding: 20px 55px;
    border: none;
    border-radius: 4px;
    @include font-size(18, $medium, 18);
    color: #fff;
    background: $background-button;
    transition: all 0.5s;
    &:hover:not(:disabled) {
      opacity: 0.8;
    }
    &:disabled {
      opacity: 0.2;
    }
    align-self: center;
    text-transform: uppercase;
  }
  @media (max-width: 450px) {
    gap: 5px;
    &_button {
      width: 100%;
      @include font-size(16, $medium, 20);
    }
  }
}
