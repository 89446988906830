@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  h1 {
    @include font-size(50, $bold, 78);
    font-family: "Visuelt Pro" !important;
    text-align: center;
    color: #fff;
  }

  &_subtitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  &_text {
    @include font-size(18, $regular, 32);
    font-family: "Visuelt Pro" !important;
    color: rgba(255, 255, 255, 0.6);
  }

  &_phone {
    @include font-size(18, $bold, 32);
    font-family: "Visuelt Pro" !important;
    color: white;
  }

  &_codeinput {
    @include font-size(16, $regular, 16, -0.225);

    outline: none;
    border-radius: 4px;
    height: 100px;
    border: 1px solid rgba(36, 47, 64, 0.5);
    width: 80px;

    padding: 10px 20px;

    background-color: transparent;
    color: rgba(91, 107, 130, 1);
    text-align: center;

    transition: all 0.3s;

    &:focus {
      border: 1px solid rgba(36, 47, 64, 1);
    }
  }

  &_codeinput_error {
    @extend .login_codeinput;
    color: rgba(232, 74, 95, 1);
    border: 1px solid rgba(232, 74, 95, 1);

    &:focus {
      border: 1px solid rgba(232, 74, 95, 1);
    }
  }

  &_btn {
    @include reset-button;
    @include font-size(15, $regular, 16, -0.28125);
    margin: 10px auto 20px auto;
    max-width: 280px;

    width: 100%;
    height: 60px;
    background-color: $font-color-purple;
    border-radius: 4px;

    color: #fff;

    transition: all 0.3s;

    &:disabled {
      background-color: rgba(36, 47, 64, 0.5);
      color: rgba(255, 255, 255, 0.5);
    }

    &:hover:not(:disabled) {
      background-color: rgba(114, 51, 204, 0.7);
    }
  }

  &_loader {
    gap: 15px;
    @include flex-justify-align(flex-start, center);
    &_spinner {
      svg {
        animation: lds-dual-ring 1s linear infinite;
      }
    }
    &_text {
      @include font-size(18, $medium, 40);
      color: #fff;
    }
  }

  &_support {
    text-align: center;
    @include flex-justify-align(space-between, center);
    align-content: center;
    width: 100%;

    &_text {
      @include font-size(18, $medium, 40);
    }

    button {
      @include reset-button;
      background-color: transparent;
      @include font-size(14, $bold, 18);
      background: $background-button;
      border-radius: 28px;
      padding: 12px 20px;
      transition: all 0.3s ease-in-out;
      color: rgba(255, 255, 255, 1);

      &:hover:not(:disabled) {
        opacity: 0.4;
      }
      &:disabled {
        opacity: 0.1;
      }
    }
  }

  @media (max-width: 660px) {
    align-items: flex-start;
    h1 {
      @include font-size(24, $bold, 42);
    }
    &_text {
      @include font-size(16, $regular, 32);
    }
    &_phone {
      @include font-size(16, $bold, 32);
    }
    &_support {
      align-items: flex-start;
      flex-direction: column;
      &_text {
        @include font-size(14, $medium, 40);
      }
      button {
        padding: 11px 35px;
      }
    }
    &_loader {
      &_text {
        @include font-size(14, $medium, 40);
        color: #fff;
      }
    }
  }
}

.react_code_input {
  display: flex !important;
  gap: 20px;
  font-family: "Visuelt Pro";

  input {
    color: $font-color-black-secondary;
    @include font-size(40, $bold, 80);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 2px 8px 20px 0px rgba(203, 203, 203, 0.35);
    text-align: center;
    outline: none;
    font-family: "Visuelt Pro";
    height: 100px;
    width: 80px;
    border-radius: 3px;
    padding: 10px 20px;
    &:disabled {
      box-shadow: 2px 8px 20px rgba(203, 203, 203, 0.35) !important;
      background-color: grey !important;
      border: none !important;
    }
    margin: 0px;
  }
  @media (max-width: 660px) {
    margin-top: -10px;
    gap: 4px;
    input {
      @include font-size(16, $bold, 32);
      width: 40px;
      height: 50px;
      padding: 10px;
      border-radius: 4px;
    }
  }
}

.msg_fail {
  background-color: rgba(246, 75, 60, 1);
  display: flex;
  transition: all 0.3s ease-in-out;
  animation: fade-out 0.5s ease-out;
  flex-direction: column;
  justify-content: space-between;
  gap: 1px;
  padding: 30px;
  border-radius: 4px;
  width: 100%;
  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_text {
      @include font-size(34, $medium, 48);
      color: #fff;
      letter-spacing: -0.035em;
    }
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: white;
      }
    }
  }
  &_text {
    @include font-size(14, $regular, 18);
    letter-spacing: 0.02em;
    color: white;
  }
  @media (max-width: 660px) {
    padding: 14px 22px;
    width: 260px;
    gap: 12px;
    &_top {
      &_text {
        @include font-size(16, $bold, 32);
        color: #fff;
      }
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.disabled_input {
  position: absolute;
  color: black;
  font-family: "Visuelt Pro" !important;
  color: rgba(25, 28, 35, 0.5) !important;
  @include font-size(40, $medium, 80);
  top: 200px;
  left: 0px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  &_letter {
    width: 80px;
    padding: 10px 27px;
    text-align: center;
  }
  @media (max-width: 660px) {
    top: 150px;
    left: 0px;
    gap: 4px;
    @include font-size(16, $bold, 32);
    &_letter {
      padding: 10px;
      width: 40px;
      text-align: center;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
