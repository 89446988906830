@import "styles/vars";
@import "styles/mixins";

.content {
  display: flex;
  flex-direction: column;
  &_header {
    margin: 10px 0;
    display: flex;
    align-items: center;
    color: $violet;
    @include font-size(16, $thin, 40);
    margin-bottom: 2em;
  }
  ol {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    @media (max-width: 450px) {
      gap: 10px;
    }
  }
  li {
    &:first-child {
      border-top: 1px solid rgba(120, 115, 137, 0.2);
    }
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(120, 115, 137, 0.2);
    align-content: center;
    align-items: center;
    padding: 20px;
  }
  @media (max-width: 450px) {
    &_header {
      margin: 0;
      @include font-size(11, $thin, 40);
    }
    li {
      border: none;
      padding: 20px;
      box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 7px;
      &:first-child {
        border-top: none;
      }
    }
  }
}

.value {
  color: $font-color-black-secondary;
  @include font-size(14, $medium, 18);
  &_paid {
    @extend .value;
    color: rgba($font-color-black-secondary, 0.4);
  }
  @media (max-width: 450px) {
    @include font-size(12, $medium, 20);
  }
}
.date {
  color: $violet;
  @include font-size(14, $regular, 20);
  @media (max-width: 450px) {
    @include font-size(10, $medium, 20);
  }
}

.status {
  color: rgba(139, 135, 149, 1);
  @include font-size(14, $medium, 20);
  text-align: right;
  &_type {
    color: $font-color-black-secondary;
    text-align: right;
    @include font-size(14, $thin, 20);
  }
}

.btn {
  background: $background-button;
  align-self: center;
  border: none;
  margin-top: 37px;
  border-radius: 4px;
  width: max-content;
  padding: 20px 85px;
  transition: all 0.3s ease;
  color: #fff;
  @include font-size(18, $medium, 20);
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.2;
  }
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
  @media (max-width: 450px) {
    @include font-size(16, $medium, 20);
    width: 100%;
    padding: 20px;
    &_wrapper {
      padding: 20px 16px;
      background-color: $background-color;
      box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
      &_label {
        @include font-size(12, $regular, 20);
        text-align: center;
      }
    }
  }
}

.close_icon {
  align-self: flex-end;
  margin-bottom: 87px;
  transition: all 0.5s;
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
  @media (max-width: 450px) {
    align-self: flex-start;
    color: $purple;
    margin-bottom: 53px;
  }
}

.payment_schedule_button {
  border: none;
  color: #fff;
  background: $background-button;
  padding: 15px 10px;
  text-transform: uppercase;
  border-radius: 4px;
  @include font-size(16, $medium, 20);
  transition: all 0.5s;
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.2;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
}

.description {
  display: none;
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 28px;
    border-bottom: 1px solid $divider-color;
    margin-bottom: 28px;
    gap: 17px;
    &_name {
      &_label {
        color: $font-color-black-secondary;
        @include font-size(16, $medium, 20);
      }
      &_value {
        color: rgba($font-color-black-secondary, 0.6);
        @include font-size(16, $bold, 40);
      }
    }
    &_total {
      &_label {
        color: $violet;
        @include font-size(12, $regular, 18);
      }
      &_value {
        color: $violet;
        @include font-size(16, $thin, 24);
      }
    }
  }
}

.paymentSchedule_top {
  @include flex-justify-align(space-between);
  color: rgba($violet, 0.5);
  &_due {
    &_label {
      @include font-size(12, $regular, 18);
    }
    &_value {
      @include font-size(16, $regular, 24);
      &_active {
        @include font-size(16, $regular, 24);
        color: $violet;
      }
    }
  }
  &_amount {
    &_label {
      @include font-size(12, $regular, 18);
      text-align: right;
    }
    &_value {
      @include font-size(16, $medium, 24);
      &_active {
        @include font-size(16, $medium, 24);
        color: $violet;
      }
    }
  }
}

.paymentSchedule_status_name {
  color: rgba($violet, 0.5);
  @include font-size(12, $regular, 24);
}
