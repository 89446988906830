@import "styles/vars";
@import "styles/mixins";

.react_tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  @include flex-justify-align(initial, center);
  flex-direction: column;

  &__tab_list {
    position: fixed;
    height: 60px;
    background-color: $white;
    @include flex-justify-align(center, center);
    width: 100%;
  }

  &__tab {
    &_wrapper {
      padding: 0;
      gap: 30px;
      @include flex-justify-align(initial, center);
      position: fixed;

      background-color: $white;
      width: 50%;

      flex-wrap: nowrap;
      overflow-x: scroll;
      height: 60px;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 450px) {
        justify-content: initial;
        padding: 0;
        width: 100%;
      }
    }

    cursor: pointer;
    display: inline-block;
    list-style: none;
    white-space: nowrap;
    z-index: 1;

    border-radius: 1px;
    transition: border-bottom-color 0.3s ease;
    color: $font-color-black-secondary;
    padding: 0;
    @include font-size(16, $medium, 21);
    padding-bottom: 8px;

    &__selected {
      border-bottom: 1px solid $purple;
      border-radius: 0;
      color: $purple;
    }
  }

  &__tab_panel {
    display: none;
    padding-top: 20px;

    &__selected {
      svg {
        overflow: visible;
      }

      @include flex-justify-align(center, center);
      display: block;
      max-width: 100%;
      height: 100%;

      width: 2000px;
      max-height: 500px;
    }
  }

  @media (max-width: 450px) {
    height: 100%;

    &__tab_panel {
      &__selected {
        height: calc(100% - 40px);
        width: 100%;
        max-height: initial;
      }
    }
  }
}
