@import "styles/vars";
@import "styles/mixins";

.modal_fail {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;

  @include flex-justify-align(space-between);
  width: 100%;
  padding: 20px 40px;

  background-color: #e84a5f;
  color: white;

  &_title {
    @include font-size(16, $medium, 36);
    @include flex-justify-align(center);
    gap: 10px;
    svg {
      fill: white;
      width: 18px;
      height: 18px;
    }
    div {
      @include font-size(14, $regular, 20);
    }
  }

  &_button {
    padding: 7px 10px 5px;
    @include font-size(14, $bold, 16);
    border: 1px solid #fff;
  }

  @media (max-width: 772px) {
    position: absolute;
    @include flex-justify-align(center);
    flex-direction: column;
    height: max-content;

    &_title {
      @include flex-justify-align(center);
      flex-direction: column;

      svg {
        margin-top: 0;
        margin-right: 0;
        fill: white;
        width: 18px;
        height: 18px;
      }

      div {
        text-align: center;
        @include font-size(10, $bold, 20);
        p {
          @include font-size(10, $regular, 14);
          padding: 10px 0;
        }
      }
    }

    &_button {
      @include font-size(10, $bold, 16);
      border: 1px solid #fff;
      text-align: center;
    }
  }
}
