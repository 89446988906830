@import "styles/vars";
@import "styles/mixins";

.root {
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;

  padding-top: 100px;
  &__col_left {
    width: 63%;
  }

  @media (max-width: 1024px) {
    &__col_left {
      width: 55%;
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
    &__col_left {
      width: auto;
      margin: 0 10px;
    }
  }
}

.btn_wrapper {
  button {
    width: 140px;
    padding: 5px 20px;
    margin-left: 0px;
  }

  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }

  @media (max-width: 772px) {
    button {
      margin: 0px auto;
    }
  }
}

.accordion_sidebar {
  padding: 27px 22px 0px;
  margin: 0 10px;
  h3 {
    @include font-size(18, $bold, 40);
  }

  background-color: rgba(247, 248, 253, 1);
}

.btn_delete {
  border: none;
  background: $background-button;
  border-radius: 4px;
  margin: 19px 29px 12px;
  height: 60px;
  color: $white;
  @include font-size(18, $medium, 20);
  transition: all 0.5s;
  &:hover {
    opacity: 0.5;
  }
}

.confirm_modal {
  @include flex-justify-align(space-between);
  flex-direction: column;
  height: 200px;
  gap: 20px;
  margin: 40px 76px 50px;
  @media (max-width: 450px) {
    margin: 20px;
    gap: 0px;
    justify-content: flex-start;
    p {
      @include font-size(14, $regular, 15);
      width: 240px;
      padding: 0 15px;
      &_continue {
        @include font-size(14, $bold, 15);
      }
    }
  }
}
