@import "styles/vars";
@import "styles/mixins";

.root {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100vh;
  background: $font-color-black;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  &::before {
    content: "";
    background: linear-gradient(
        180deg,
        rgba(167, 76, 242, 1) 22.2%,
        rgba(117, 101, 251, 1) 98.61%
      ),
      rgba(216, 216, 216, 1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.6;
  }
}

.wrapper {
  @include font-size(14, $medium, 18, 0.12);

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  color: #fff;

  h1 {
    margin-top: 39px;
  }

  a {
    display: flex;
    align-items: center;

    margin-top: 20px;
    color: #fff;

    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }

  .arrow_icon {
    width: 15px;
    margin-right: 5px;
  }
}
