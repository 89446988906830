@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.customer_info_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 25px;

  padding: 23px 27px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &_title {
    @include font-size(16, $bold, 20);
    color: rgba($font-color-black-secondary, 0.65);
  }

  &_bottom {
    @include flex-justify-align(flex-start, center);
    gap: 13px;

    @include font-size(14, $medium, 24);
    color: $purple;

    &_button {
      @include reset-button();
      .icon {
        @include flex-justify-align(center);
        border-radius: 50%;
        padding: 4px;
        height: 24px;
        width: 24px;
        background-color: $purple;

        transition: all 0.5s;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  @media (max-width: 450px) {
    &_bottom {
      p {
        max-width: 164px;
      }
    }
  }
}

.customer_info_card_active {
  @include flex-justify-align(space-between, flex-start);

  div:first-child {
    @include font-size(14, $medium, 23);
    color: rgba($font-color-black-secondary, 0.65);
    flex-grow: 1;

    p:first-child {
      @include font-size(18, $medium, 23);
      color: rgba($font-color-black-secondary, 0.8);
      margin-bottom: 6px;
    }
  }

  div:last-child {
    .edit_icon {
      z-index: 999;
    }
  }
}
