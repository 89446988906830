@import "styles/vars";
@import "styles/mixins";

.graph_wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  @media (max-width: 450px) {
    width: 80px;
    height: 80px;
  }
}

.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
