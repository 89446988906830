@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.account {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
  border-bottom: 1px solid rgba(144, 149, 166, 0.2);

  h3 {
    @include font-size(24, $bold, 42);
    color: rgba($font-color-black-secondary, 0.56);
  }


  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    border: none;
    gap: 18px;

    p {
      @include font-size(14, $medium, 32);
      color: rgba(25, 28, 35, 0.65);
    }
  }
}

.sign_out {
  @include reset-button();

  @include flex-justify-align(flex-start);
  gap: 8px;

  @include font-size(18, $medium, 32);
  color: $font-color-black-secondary;

  transition: all 0.5s;


  svg {
    path {
      fill: $font-color-black-secondary;
    }
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 450px) {
    @include font-size(14, $medium, 22);
  }
}


.link {
  @include font-size(18, $medium, 32);
  color: $font-color-black-secondary;

  &:hover {
    color: $font-color-black-secondary;

    opacity: 0.65;
  }
}