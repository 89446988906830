@import "styles/vars";
@import "styles/mixins";

.root {
  position: absolute;
  top: 0;
  @include flex-justify-align(center);
  display: none;
  width: 100%;
  height: 100px;
  z-index: 21;
  background: $background-button;

  p {
    @include font-size(16, $regular, 20);
    color: $white;
    max-width: 710px;
  }

  &_active {
    display: flex;
  }

  @media (max-width: 450px) {
    padding: 28px 30px;
    min-height: 100px;
    height: auto;

    p {
      @include font-size(14, $medium, 22);
      max-width: 100%;
    }
  }
}
