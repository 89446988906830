@import "styles/vars";
@import "styles/mixins";

.table_summary {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-bottom: 30px;
  @media (max-width: 1100px) {
    gap: 5%;
  }
  @media (max-width: 768px) {
    gap: 12px;
  }
  @media (max-width: 450px) {
    gap: 0px;
  }
}

.slider {
  margin-left: 10px;
}

.card {
  div {
    span {
      @include font-size(16, $thin, 20);
      margin-top: 21px;
      @media (max-width: 450px) {
        margin-top: 9px;
        margin-bottom: 23px;
        @include font-size(14, $thin, 20);
      }
    }
  }
}
