@import "styles/vars";
@import "styles/mixins";

.no_items {
  height: 40vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    @include font-size(14, $medium, 21);
    color: rgba(125, 134, 169, 1);
    margin-bottom: 20px;
  }

  button {
    height: 40px;
    width: 100%;
    max-width: 120px;

    outline: none;
    border-radius: 4px;
    border: 1px solid $purple;
    background-color: $purple;

    @include font-size(14, $bold, 20);
    color: $white;

    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
}