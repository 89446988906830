@import "styles/vars";
@import "styles/mixins";

.content {
  @include flex-justify-align(center);
  padding: 50px 76px;
  flex-direction: column;
  gap: 20px;
  &_title {
    color: $violet;
    @include font-size(24, $medium, 40);
  }
  &_description {
    color: $violet;
    @include font-size(16, $regular, 20);
    width: 408px;
    text-align: center;
    &_continue {
      @include font-size(16, $medium, 20);
      margin: 0 3px;
    }
  }
  &_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 48px;
    text-transform: uppercase;
    &_cancel {
      width: 100px;
      text-transform: uppercase;
      border: none;
      height: 100%;
      border-radius: 4px;
      background-color: rgba(239, 239, 243, 1);
      @include font-size(16, $regular, 24);
      color: rgba(139, 135, 149, 1);
      transition: all 0.5s ease-in-out;
      &:hover:not(:disabled) {
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.2;
      }
    }
    &_continue {
      width: 162px;
      text-transform: uppercase;
      background: $background-button;
      border-radius: 4px;
      border: none;
      height: 100%;
      @include font-size(16, $regular, 24);
      color: #fff;
      transition: all 0.5s ease-in-out;
      &:hover:not(:disabled) {
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.2;
      }
    }
  }
  @media (max-width: 450px) {
    padding: 20px;
    &_title {
      @include font-size(14, $medium, 40);
    }
    &_description {
      @include font-size(12, $regular, 15);
      width: 240px;
      &_continue {
        @include font-size(12, $bold, 15);
      }
    }
    &_buttons {
      &_cancel {
        @include font-size(14, $medium, 18);
      }
      &_continue {
        @include font-size(14, $medium, 18);
      }
    }
  }
}
