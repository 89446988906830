@import "styles/vars";
@import "styles/mixins";

.item {
  @include reset-button;

  &_container {
    height: max-content;
    width: 100%;

    padding: 16px 12px;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }

  width: 100%;

  display: flex;
  @include flex-justify-align(space-between, center);
  transition: 0.3s;

  picture {
    display: block;
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_avatar {
    @include flex-justify-align(center, center);
    height: 80px;
    width: 80px;
    overflow: hidden;

    @include font-size(50, $extra-bold, 80);
    color: rgba($violet, 0.6);
    margin-bottom: 8px;
    border-radius: 4px;
  }

  &_description {
    @include flex-justify-align(space-between, flex-start);
    flex-direction: column;
    width: 157px;
    gap: 8px;

    &_count {
      padding: 2px 8px;

      background: rgba(240, 99, 90, 0.2);
      border-radius: 10px;

      @include font-size(8, $bold, 13, 0.007);
      color: #f0635a;
    }

    &_name {
      @include font-size(14, $medium, 20, -0.41);
      color: rgba(0, 0, 0, 1);

      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;

      @media (max-width: 450px) {
        @include font-size(16, $bold, 20, -0.41);
      }
    }

    &_price {
      @include font-size(12, $medium, 13, 0.07);
      color: $purple;
    }

    &_sold__out {
      @extend .item_description;

      .item_description_count {
        @extend .item_description_count;

        background: rgba(184, 193, 204, 0.2);
      }

      .item_description_name {
        color: rgba(0, 0, 0, 0.2);
        @include font-size(14, $medium, 20);
        text-decoration-line: line-through;

        @media (max-width: 450px) {
          @include font-size(16, $bold, 20);
        }
      }

      .item_description_price {
        color: rgba(0, 0, 0, 0.2);
        max-width: 100%;
        overflow: hidden;
        text-decoration-line: line-through;
      }

      .item_options {
        color: rgba(0, 0, 0, 0.2);
        text-decoration-line: line-through;
      }
    }
  }

  &_options {
    margin-top: -6px;
    @include font-size(12, $medium, 20);
    color: rgba($font-color-black-secondary, 0.8);

    &_nodata {
      margin: 10px 0;
    }
  }

  @media (max-width: 570px) {
    align-items: center;
    text-align: center;

    &_container {
      width: 100%;
      padding: 12px 5px 11px 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    picture {
      height: 70px;
    }

    &_avatar {
      height: 70px;
      @include font-size(40, $extra-bold, 120);
    }

    &_price {
      @include font-size(10, $demibold, 20);
      color: rgba(104, 49, 243, 1);
    }
  }
}
