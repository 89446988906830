@import "styles/vars";
@import "styles/mixins";

.phone__input_wrapper {
  position: relative;

  @media (max-width: 450px) {
    width: 100%;
    min-width: 100%;
  }
}

.phone__input {
  position: relative;
  height: 60px;
  width: 280px;
  display: flex;
  transition: all 0.3s;

  border: 1px solid rgba(221, 227, 232, 1);
  border-radius: 3px;

  &:focus-within {
    border-color: $purple;
  }

  label {
    @include font-size(14, $medium, 18);
    color: rgba($font-color-black-secondary, 0.4);

    text-transform: uppercase;
  }

  &_dropdown_btn {
    @include reset-button;
    padding: 20px;

    @include flex-justify-align(space-between);
    gap: 20px;
    color: $font-color-black-secondary;

    span {
      @include font-size(16, $medium, 20);
      color: $font-color-black-secondary;
    }

    .icon {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $font-color-black-secondary;
      transform: rotate(0deg);
      transition: 0.3s;

      &_open {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    }

    &_active {
      span {
        color: $purple;
      }

      .icon {
        border-top: 5px solid $purple;
      }
    }
  }

  input {
    @include font-size(16, $regular, 20);
    padding: 20px 0px;
    outline: none;
    width: 100%;
    height: 58px;
    background: transparent;
    color: $font-color-black-secondary;
    text-align: left;
    border: none;

    &::placeholder {
      color: rgba($font-color-black-secondary, 0.4);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: $font-color-black-secondary !important;
  }

  &_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    max-height: 176px;
    overflow-y: auto;
    list-style: none;
    padding-top: 40px;
    border-radius: 4px;
    z-index: 10;

    &::-webkit-scrollbar {
      width: 4px;
      height: 24px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($font-color-black-secondary, 0.5);
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(65, 29, 117, 0.5);
    }

    &_item {
      cursor: pointer;
      @include flex-justify-align(space-between);
      padding: 5px 25px;
      transition: 0.3s;
      z-index: 10;

      span {
        @include font-size(14, $medium, 18, -0.196875);
        color: $font-color-black-secondary;

        @media (max-width: 480px) {
          @include font-size(12, $medium, 18, -0.16875);
        }
      }

      &:hover {
        background-color: $purple;
        span {
          color: $white;
        }
      }
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    min-width: 100%;
  }
}

.phone__input_menu {
  z-index: 2;

  position: absolute;
  top: 100%;

  padding: 25px 0px;

  background-color: $white;
  box-shadow: 2px 8px 20px $shadow;
  border-radius: 4px;

  @media (max-width: 450px) {
    left: 0px;
    top: 60px;
    width: 100%;
  }
}

.phone__input_menu_search {
  padding: 0 25px;

  input {
    width: 100%;

    border: none;
    border-bottom: 1px solid $divider-color;
    padding: 0px;
    padding-bottom: 17px;
    padding-left: 28px;
    border-radius: 0;

    @include font-size(16, $medium, 20);
    height: auto;

    &:focus {
      border-color: $divider-color;
    }
  }

  svg {
    position: absolute;
    top: 28px;

    path {
      fill: rgba($font-color-black-secondary, 0.3);
    }
  }
}
