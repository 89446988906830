@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.world_languages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_change {
    width: 120px;
    padding-right: 20px;
    background: transparent;
    border: "none";
    font-family: "Visuelt Pro";
    @include font-size(20, $bold, 20);
  }
  svg {
    margin-right: 0px;
  }
}
