@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $font-color-black;
}

.wrapper {
  background-color: #fff;
  animation: fade-out 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: fixed;
  height: 300px;
  top: 50%;
  left: 50%;
  width: 560px;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: 0px 32px 64px rgba(140, 136, 150, 0.3);
  padding: 40px;
  padding-bottom: 0px;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 45%;
    padding: 1em;
  }
}

.content {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: scroll;
  padding: 0px 40px;
  @media (max-width: 768px) {
    padding: 0px;
  }
}

.title {
  color: $black;
  @include font-size(24, $demibold, 40);
  @media (max-width: 768px) {
    @include font-size(16, $demibold, 60);
  }
}

.description {
  color: $violet;
  text-align: center;
  @include font-size(14, $regular, 20);
  @media (max-width: 768px) {
    @include font-size(13, $regular, 20);
  }
}

.button {
  @include reset-button;
  color: #fff;
  transition: all 0.3s ease-out;
  text-transform: uppercase;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;
  padding: auto;
  width: 180px;
  height: 60px;
  @include font-size(14, $demibold, 21, 1.16);
  @media (max-width: 768px) {
    padding: auto;
    width: 100%;
  }
  &:focus {
    opacity: 0.5;
  }
  &:hover {
    opacity: 0.8;
  }
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
