@import "styles/vars";
@import "styles/mixins";


.security {
  @include flex-justify-align(space-between);

  .login,
  .password {
    @include flex-justify-align(space-between);
    min-width: 350px;

    padding: 22px 18px 22px 16px;
    background-color: rgba(228, 234, 238, 1);
    border-radius: 4px;

    div {
      p {
        @include font-size(14, $bold, 20);
        color: rgba($font-color-black-secondary, 0.65);

        &:last-child {
          @include font-size(12, $medium, 15, -0.4);
          color: rgba($font-color-black-secondary, 0.65);
        }
      }
    }

    button {
      @include purple-btn();
      width: 74px;
      height: 34px;
      @include font-size(14, $medium, 20);
    }
  }

  @media (max-width: 854px) {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: stretch;
  }

  @media (max-width: 450px) {

    .login,
    .password {
      min-width: 100%;
    }
  }
}