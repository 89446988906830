.avatar {
  display: block;
  width: 100%;
  max-height: 41px;
  max-width: 80px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
