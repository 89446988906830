@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.main {
  padding: 40px 50px 30px;
  @include flex-justify-align(center);
  flex-direction: column;

  h2 {
    @include font-size(24, $medium, 30);
    color: $font-color-black-secondary;
    padding-bottom: 35px;
  }

  div {
    @include font-size(16, $regular, 20);
    color: $violet;
    padding-bottom: 50px;
  }

  button {
    @include purple-btn();
    height: 45px;
  }

  @media (max-width: 450px) {
    padding: 20px 20px 30px;
    h2 {
      @include font-size(16, $demibold, 22);
      padding-bottom: 0px;
    }

    div {
      @include font-size(14, $regular, 18);
      padding-bottom: 11px;
    }
  }
}
