@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.about__us {
  margin-bottom: 0;
  &_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 252px;

    h3 {
      @include font-size(10, $regular, 12);
      color: rgba($font-color-black-secondary, 0.8);
      opacity: 0.6;
    }
  }

  @include flex-justify-align(flex-start, center);
  gap: 20px;
  padding: 0;

  a {
    @include flex-justify-align(space-between, center);
    @include font-size(12, $regular, 12);
    color: rgba($font-color-black-secondary, 0.8);
    opacity: 0.6;
    gap: 4px;
  }
}
