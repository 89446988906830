@import "styles/vars";
@import "styles/mixins";

.contacts {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 0px 99px 0px 105px;
  margin-bottom: 45px;
  &_wrapper {
    @include flex-justify-align(space-between, flex-start);
  }

  @media (max-width: 1150px) {
    padding: 0px 12px 0px 25px;
    margin-bottom: 12px;
  }

  @media (max-width: 450px) {
    box-shadow: 0px 4px 16px $shadow;
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 0px;
    &_wrapper {
      flex-direction: column;
      @include flex-justify-align(space-between, flex-start);
    }
    padding: 23px 20px;
    gap: 16px;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  &_title {
    @include font-size(16, $medium, 36);
    color: $black;
  }
  &_address {
    padding: 20px 0px 0px;
    @include font-size(14, $regular, 26);
    color: $grey;
  }
  &_transaction_title {
    @include flex-justify-align(space-between, flex-start);
    flex-direction: column;
    gap: 5px;

    &_name {
      @include font-size(16, $bold, 40);
      color: rgba($font-color-black-secondary, 0.6);
    }

    &_status {
      height: 40px;
      max-width: 120px;
      @include flex-justify-align(center);
      padding: 10px;
      color: $white;
      @include font-size(14, $bold, 20);
      background-color: $font-color-black-secondary;
      border-radius: 4px;

      &_paid {
        @extend .contact_transaction_title_status;
        background-color: rgba($font-color-black-secondary, 0.3);
        color: $white;
      }
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    padding-bottom: 14px;
    &_title {
      @include font-size(16, $bold, 20);
    }

    &_address {
      color: $violet;
      padding-top: 6px;
      @include font-size(14, $regular, 18);
    }

    &_transaction_title {
      flex-direction: row;
      padding-top: 17px;
    }
  }
}

.contact_user {
  @extend .contact;
  text-align: right;
  padding-bottom: 0px;

  @media (max-width: 450px) {
    border-top: 1px solid #e4eaee;
    padding-top: 17px;
    text-align: left;
  }
}
