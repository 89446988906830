@import "styles/vars";
@import "styles/mixins";

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;

  div {
    width: 175px;
    height: 175px;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}
