@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.shipping_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 25px;
  opacity: 0.2;

  padding: 23px 27px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &_title {
    @include font-size(16, $bold, 20);
    color: rgba($font-color-black-secondary, 0.65);
  }

  &_bottom {
    @include reset-button();
    @include flex-justify-align(flex-start, center);
    gap: 13px;

    @include font-size(14, $medium, 24);
    color: $purple;

    &_button {
      @include reset-button();

      .icon {
        @include flex-justify-align(center);
        border-radius: 50%;
        padding: 4px;

        height: 24px;
        width: 24px;
        background-color: $purple;

        transition: all 0.5s;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.shipping_card_active {
  @extend .shipping_card;
  opacity: 1;
}

.shipping_card_shipping {
  @include flex-justify-align(space-between, flex-start);

  p:nth-child(1) {
    @include font-size(18, $medium, 23);
    color: rgba($font-color-black-secondary, 0.8);
    margin-bottom: 6px;
  }

  p:nth-child(2) {
    @include font-size(14, $regular, 24);
    color: rgba($font-color-black-secondary, 0.65);
    margin-bottom: 24px;
  }

  p:nth-child(3) {
    @include font-size(14, $regular, 24);
    color: $font-color-black-secondary;
  }
}

.shipping_card_pickup {
  @include flex-justify-align(space-between, flex-start);

  p:nth-child(1) {
    @include font-size(18, $medium, 23);
    color: rgba($font-color-black-secondary, 0.8);
    margin-bottom: 6px;
  }

  p:nth-child(2) {
    @include font-size(14, $regular, 24);
    color: rgba($font-color-black-secondary, 0.65);
    margin-bottom: 4px;
  }

  p:nth-child(3) {
    @include font-size(14, $regular, 24);
    color: rgba($font-color-black-secondary, 0.65);
    margin-bottom: 4px;
  }

  button {
    @include reset-button();
    display: flex;
    gap: 10px;
    @include font-size(14, $regular, 24);
    color: $purple;
  }
}
