@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.left_arrow_icon {
  transition: all 0.5s;

  &:hover {
    opacity: 0.7;
  }
}