@import "styles/vars";
@import "styles/mixins";

.table_item {
  position: relative;
  display: grid;
  grid-template-columns: 1.5fr 0.9fr 0.9fr 0.9fr 0.9fr 1.5fr;
  align-items: start;
  gap: 15px;

  background-color: $white;
  padding: 20px 30px 20px 30px;
  border-bottom: 1px solid rgba(226, 209, 249, 0.3);

  @include font-size(16, $regular, 20);
  color: $violet;

  margin-bottom: 5px;

  text-align: left;

  &:hover {
    color: $purple;
  }

  &_col:nth-child(2) {
    text-transform: capitalize;
  }

  &_col:nth-child(3) {
    text-transform: capitalize;
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }

  @media (max-width: 768px) {
    @include font-size(14, $regular, 20);
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  }
}