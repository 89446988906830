@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.info {
  position: absolute;
  top: 100%;
  padding: 21px;
  z-index: 1;
  opacity: 0;
  pointer-events: none;

  background-color: $purple;

  @include font-size(14, $medium, 18);
  color: $white;

  transition: all 0.5s;

  &_active {
    opacity: 1;
  }

  &_triangle {
    position: absolute;
    top: -30px;
    @include triangle("up", 15px, $purple);
  }

  &_up {
    @extend .info;
    top: initial;
    bottom: 100%;

    .info_triangle {
      position: absolute;
      top: initial;
      bottom: -30px;
      @include triangle("down", 15px, $purple);
    }
  }

  @media (max-width: 450px) {
    @include font-size(14, $regular, 18);
    height: max-content;

    &_up {
      top: 100%;

      .info_triangle {
        position: absolute;
        top: -30px;
        @include triangle("up", 15px, $purple);
      }
    }
  }
}
