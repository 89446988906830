@import "styles/vars";
@import "styles/mixins";

.modal,
.modal * {
  font-family: "Visuelt Pro", "Poppins", sans-serif;
}

.modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);

  &_show {
    @extend .modal;
    display: block;
  }
  &_hide {
    display: none;
  }
  &_wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    background: $white;
    width: 40%;
    min-height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 50px;
    @media (max-width: 772px) {
      width: 90%;
    }
    @media (max-width: 450px) {
      padding: 20px;
      height: max-content;
      min-height: auto;
    }
  }
  &_content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    &_header {
      @include flex-justify-align(center);
      text-align: center;
      color: $violet;
      @include font-size(24, $medium, 30);
    }
    &_text {
      display: flex;
      align-items: center;
      color: $violet;
      @include font-size(16, $regular, 20);
    }
    @media (max-width: 1024px) {
      &_header {
        @include font-size(14, $medium, 40);
      }
      &_text {
        @include font-size(12, $regular, 20);
      }
    }
  }
  &_footer {
    @include flex-justify-align(center);
    padding: 20px;

    button:first-child {
      margin-right: 20px;
      color: rgba(139, 135, 149, 1);
      background: transparent;
      padding: 13px 20px;
      @include font-size(16, $regular, 23);
      background-color: rgba(239, 239, 243, 1);
      border-radius: 4px;
    }

    &_button {
      @include reset-button;
      cursor: pointer;
      border-radius: 4px;
      background: $background-button;
      text-align: right;
      padding: 13px 20px;
      @include font-size(16, $regular, 23);
      color: $white;
      background-color: $font-color-purple;
      transition: transform 0.3s ease-in-out;
      &:hover {
        opacity: 0.8;
        color: $white;
      }
    }

    @media (max-width: 1024px) {
      button:first-child {
        @include font-size(12, $medium, 17);
      }

      &_button {
        @include font-size(12, $medium, 17);
      }
    }

    @media (max-width: 450px) {
      padding: 0px;

      button:first-child {
        @include font-size(14, $medium, 18);
        padding: 10px 20px;
      }

      &_button {
        @include font-size(14, $medium, 18);
        padding: 10px 20px;
      }
    }
  }
}
