@import "styles/vars";
@import "styles/mixins";

.remove {
  @include reset-button;
  color: $red;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 0.3;
  }
  @media (max-width: 564px) {
    padding-right: 0%;
  }
  @media (max-width: 570px) {
    @include font-size(12, $demibold, 20);
    margin: 0 5%;
    padding-right: 0%;
  }
}
