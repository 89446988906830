@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.payment_modal {
  width: 720px;
  height: 100%;

  min-height: 842px;
  max-height: 95%;
  max-width: 95%;
  overflow-y: scroll;

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

.header {
  @include flex-justify-align(space-between);
  flex-direction: row-reverse;

  padding: 38px 28px 49px;

  .close_icon {
    @include black-close-icon;
  }

  @media (max-width: 450px) {
    padding: 21px 19px 24px;

    @include flex-justify-align(flex-start);
    flex-direction: row;

    .close_icon {
      display: none;
    }

    .left_arrow_icon {
      display: block;
    }
  }
}

.title {
  padding: 0 20px 10px;

  @include font-size(38, $bold, 49);
  color: $font-color-black-secondary;

  @media (max-width: 450px) {
    padding: 0 20px;

    @include font-size(20, $bold, 42);
    color: rgba($font-color-black-secondary, 0.65);
  }
}

.subtitle {
  padding: 0 20px 20px;

  @include font-size(14, $regular, 18);
  color: $grey;

  span {
    @include font-size(14, $medium, 18);
    color: $font-color-black-secondary;
  }
}

.bank_list {
  margin: 0 20px 20px;
  @include flex-justify-align(flex-start);
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 450px) {
    margin: 0 15px 20px;
    @include flex-justify-align(center);
  }
}

.bank {
  @include reset-button();
  padding: 27px 20px;
  @include flex-justify-align(space-between);
  flex-direction: column;
  width: 140px;
  height: 130px;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;

  transition: all 0.3s;

  .logo {
    width: 60px;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 4px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  p {
    @include font-size(12, $regular, 18);
    color: $font-color-black-secondary;
  }

  &_active {
    @extend .bank;
    border: 1px solid $purple;
  }
}

.footer {
  @include flex-justify-align(center);
  background-color: rgba(252, 252, 252);

  padding: 8px;
  span {
    @include font-size(11, $regular, 24);
    color: $font-color-black-secondary;
  }
}
