@import "styles/vars";
@import "styles/mixins";

.update_email {
  min-width: 720px;
  min-height: max-content;
  min-height: 664px;
  height: 664px;

  @media (max-width: 720px) {
    min-width: 90%;
  }

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}


.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  min-height: 664px;
  min-width: 720px;
  height: 100%;

  @media (max-width: 450px) {
    min-width: 100%;
    min-height: 100%;
  }
}

.content {
  padding: 0px 20px;
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 10px;
  }
}

.header {
  padding: 38px 28px 49px;

  svg:nth-child(1) {
    @include black-close-icon;
  }

  svg:nth-child(2) {
    display: none;
  }

  @media (max-width: 450px) {
    padding: 21px 19px 24px;

    @include flex-justify-align(flex-start);

    svg:nth-child(1) {
      display: none;
    }

    svg:nth-child(2) {
      display: block;

      path {
        fill: rgba(0, 0, 0, 1);
      }
    }
  }
}

.title {
  @include font-size(38, $bold, 48);
  color: $font-color-black-secondary;
  padding: 0 20px;
  margin-bottom: 48px;

  @media (max-width: 450px) {
    @include font-size(20, $bold, 42);
    margin-bottom: 20px;
  }
}

.inputs {
  position: relative;
  @include flex-justify-align(space-between, flex-start);
  margin-bottom: 20px;

  &_wrapper {
    display: flex;
    position: relative;
    @include flex-justify-align(space-between);
    gap: 13px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 10px;

    &_wrapper {
      width: 100%;
    }
  }
}

.input {
  @include input();
  max-width: 100%;
  min-width: 280px;
  width: 280px;
  height: 60px;
  text-align: left;
  padding: 20px 60px 20px 20px;
  outline: none;

  @include font-size(16, $regular, 21);
  color: $font-color-black-secondary;

  &:focus {
    border-color: $purple;
  }

  &::placeholder {
    color: rgba($font-color-black-secondary, 0.4);
  }

  &:disabled {
    opacity: 0.2;
  }

  @media (max-width: 450px) {
    width: 100%;
    min-width: 100%;
  }
}

.success {
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  opacity: 0;

  transition: all 0.5s;

  &_active {
    opacity: 1;
  }
}

.footer {
  padding: 28px 20px 26px;

  background-color: #efefef;

  width: 100%;
  position: sticky;
  @include flex-justify-align(space-between);

  &_left {
    display: flex;
    align-items: center;
    gap: 7px;

    p {
      max-width: 338px;
      margin-left: 7px;
      @include font-size(12, $regular, 15);
      color: rgba($font-color-black-secondary, 0.65);
    }

    div {
      display: flex;
      flex-direction: column;

      img {
        width: 42.22px;
        height: 20px;
      }

      span {
        @include font-size(11, $regular, 16);
        color: $font-color-black-secondary;
        max-width: 338px;
      }
    }
  }

  &:only-child {
    flex-grow: 10;
  }

  button:nth-child(1) {
    height: 45px;
    width: 152px;

    padding: 10px;

    background-color: $white;
    border: 1px solid $purple;
    border-radius: 4px;
    text-align: center;
    @include flex-justify-align(center);
    color: $purple;
    @include font-size(16, $medium, 20);

    transition: all 0.5s;

    &:hover {
      opacity: 0.4;
    }
  }

  button:nth-child(2) {
    @include reset-button;
    @include purple-btn;
    @include font-size(16, $medium, 20);
    width: 152px;
    height: 45px;

    &:disabled {
      color: $white;
      background: $background-color;
      opacity: 0.2;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column-reverse;
    gap: 10px;
    padding: 20px 30px;

    button:nth-child(1),
    button:nth-child(2) {
      width: 100%;
    }
  }
}

.spinner_wrapper {
  position: absolute;
  left: calc(100% + 15px);

  @media (max-width: 450px) {
    left: initial;
    right: 15px;
  }
}