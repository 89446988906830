@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.payment_later_option {
  @include flex-justify-align(space-between);
  flex-direction: column;
  padding: 27px 16px;

  width: 160px;
  height: 130px;

  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 4px;

  &_active {
    border: 1px solid $purple;
  }

  @media (max-width: 450px) {
    margin: 0 10px;
  }
}
