@import "styles/vars";
@import "styles/mixins";


.cards {
  @include flex-justify-align(space-between);

  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    @include flex-justify-align(space-between, stretch);
    width: 100%;
  }
}