@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.info_modal {
  min-width: 720px;
  min-height: max-content;
  min-height: 664px;
  height: 664px;

  @media (max-width: 720px) {
    min-width: 90%;
  }

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  height: 100%;
  @include hide-scroll();
}


.customer_info_modal {
  display: flex;
  flex-direction: column;
  padding: 38px 28px 20px 20px;
}

.customer_info_main {
  padding: 20px;
  padding-bottom: 0px;

  &_title {
    @include font-size(38, $bold, 49);
    color: $font-color-black-secondary;
    margin-bottom: 30px;
  }

  .form_name {

    &_inputs {
      @include flex-justify-align(space-between);
    }
  }

  .form_address {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .input {
      @include input();
      max-width: 100%;
      width: 100%;
      height: 60px;
      text-align: left;
      padding: 20px;
      outline: none;

      @include font-size(16, $regular, 21);
      color: $font-color-black-secondary;

      &:focus {
        border-color: $purple;
      }

      &::placeholder {
        color: rgba($font-color-black-secondary, 0.4);
      }
    }
  }

  @media (max-width: 450px) {
    padding: 0px 20px 0px;

    &_title {
      @include font-size(20, $bold, 42);
      color: $font-color-black-secondary;
    }

    .form_name {
      &_inputs {
        @include flex-justify-align(flex-start, flex-start);
        flex-direction: column;
        gap: 4px;

        input {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.header {
  svg:last-child {
    display: none;
  }

  @media (max-width: 450px) {
    align-items: flex-end;
    justify-content: flex-end;
    padding: 17px 25px 20px;

    svg:first-child {
      display: block;
      align-self: flex-end;
    }
  }
}

.address_input_wrapper {
  position: relative;
}

.form_info {
  display: none;

  &_active {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.address_inputs_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.address_city_postcode_wrapper {
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 20px;
  }
}

.email_and_phone {
  position: relative;
  @include flex-justify-align(space-between);

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 15px;
  }
}

.address {
  width: 240px !important;

  @media (max-width: 450px) {
    width: 100% !important;
  }
}

.form_buttons {
  padding: 28px 20px 26px;

  background-color: #efefef;

  width: 100%;
  position: sticky;
  @include flex-justify-align(space-between);

  &_submit {
    @include purple-btn();
    width: 152px;
    height: 45px;

    &:disabled {
      color: $white;
      background: $background-button;
      opacity: 0.2;
    }
  }

  &_cancel {
    height: 45px;
    width: 152px;

    padding: 10px;

    background-color: $white;
    border: 1px solid $purple;
    border-radius: 4px;
    text-align: center;
    @include flex-justify-align(center);
    color: $purple;
    @include font-size(16, $medium, 20);

    transition: all 0.5s;

    &:hover {
      opacity: 0.4;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column-reverse;
    gap: 10px;
    padding: 20px 30px;

    button {
      width: 100%;
    }
  }
}