@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.btn {
  padding: 20px;

  width: 184px;
  height: 45px;

  @include flex-justify-align(center);

  transition: all 0.5s;
  outline: none;
  border: none;

  &:hover {
    opacity: 0.3;
  }

  &:disabled {
    opacity: 0.5;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}

.google_pay {
  @extend .btn;
  @include flex-justify-align(center);
  background-color: $font-color-black-secondary;
  border-radius: 4px;
}

.apple_pay {
  @extend .btn;

  @include flex-justify-align(center);

  background-color: $font-color-black-secondary;
  border-radius: 4px;
}

.grab_pay {
  @extend .btn;

  @include flex-justify-align(center);
  background-color: $white;
  border: 1px solid rgba(0, 156, 61, 1);
  border-radius: 4px;
}

.ali_pay {
  @extend .btn;

  @include flex-justify-align(center);
  background-color: $white;
  border: 1px solid rgba(0, 60, 139, 1);
  border-radius: 4px;
}

.error {
  margin-top: 5px;
  @include font-size(14, $thin, 18);
  color: #bf1650;

  &::before {
    color: #bf1650;
    display: inline;
    content: "⚠ ";
  }
}

.btn_wrapper {
  display: flex;
  flex-direction: column;
  width: 184px;
  text-align: center;

  @media (max-width: 450px) {
    width: 100%;
  }
}
