@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.input {
  @include input();
  max-width: 280px;
  width: 280px;
  height: 60px;
  text-align: left;
  padding: 20px;
  outline: none;

  @include font-size(16, $regular, 21);
  color: $font-color-black-secondary;

  &_error {
    @extend .input;
    border: 1px solid #bf1650;
  }

  &:focus {
    border-color: $purple;
  }

  &::placeholder {
    color: rgba($font-color-black-secondary, 0.4);
  }

  @media (max-width: 450px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 4px;
  }
}
