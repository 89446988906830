@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.settings,
.settings * {
  font-family: "Visuelt Pro", "Poppins", sans-serif;
}

.settings {
  display: flex;
  justify-content: center;
  align-items: center;
  &_wrapper {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 7%;
    @media (max-width: 1024px) {
      width: 90%;
    }
    @media (max-width: 450px) {
      margin-top: 23px;
      height: 100%;
    }
  }
  &_close_btn {
    align-self: flex-end;
    margin-bottom: 46px;
    &_icon {
      transition: all 0.4s ease-in-out;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  &_title {
    align-self: flex-start;
    @include font-size(50, $bold, 78);
    color: $font-color-black-secondary;
    margin-bottom: 24px;
    @media (max-width: 450px) {
      @include font-size(24, $bold, 31);
      margin-bottom: 30px;
    }
  }
}
