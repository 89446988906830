@import "styles/vars";
@import "styles/mixins";

.home_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 47px 0px 72px;
  @media (max-width: 1388px) {
    margin: 0px 0px 0px 32px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: initial;
    margin: 0px 0px 0px 0px;
  }

  &_subhead {
    display: flex;
    align-items: center;
    margin: -5px 0 15px 0;
    p {
      margin-left: 10px;
      color: $purple;
      @include font-size(18, $medium, 27);
      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: initial;
        @include font-size(14, $medium, 21);
      }
    }
  }
}
