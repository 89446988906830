@import "styles/vars";
@import "styles/mixins";
.react_tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  padding: 20px 0;
  box-shadow: 0px 4px 16px 0px $shadow;

  &__tab_list {
    @include flex-justify-align(center, center);
    gap: 20px;

    border-bottom: 1px solid #f2f2f2;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 450px) {
      @include flex-justify-align(unset, stretch);
      flex-wrap: nowrap;
      overflow: scroll;
      padding: 0px 10px;
    }
  }

  &__tab {
    cursor: pointer;

    display: inline-block;
    z-index: 1;
    padding: 0px 20px;
    border-bottom: 2px solid transparent;
    list-style: none;
    padding: 12px 0;
    border-radius: 1px;

    transition: border-bottom-color 0.3s ease;

    color: $font-color-black-secondary;
    @include font-size(14, $medium, 21);

    &__selected {
      border-bottom-color: $purple;
      color: $purple;
    }

    @media (max-width: 450px) {
      width: 100%;
      white-space: nowrap;
    }
  }

  &__tab_panel {
    display: none;
    &__selected {
      svg {
        overflow: visible;
      }
      max-width: 100%;
      display: block;
      border-radius: 2px;
    }
  }
}
