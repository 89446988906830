@import "styles/vars";
@import "styles/mixins";

.mobile_menu {
  display: flex;
  flex-direction: column;
  background-color: $font-color-black-secondary;
  height: 0;

  visibility: hidden;
  opacity: 0;
  overflow: hidden;

  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 999;

  &_active {
    top: 80px;
    height: calc(100% - 80px);
    visibility: visible;
    opacity: 1;
    overflow-y: auto;
  }

  @media (max-width: 772px) {
    top: 60px;
    height: calc(100% - 60px);
  }
  @media (max-width: 450px) {
    top: 60px;
    height: calc(100% - 60px);
  }
}

.links_list {
  display: flex;
  flex-direction: column;
  @include reset-ul;
  margin: 20px;
  .logout_btn {
    @include reset-button;
    @include font-size(14, $medium, 18);

    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: rgba(0, 0, 0, 0.85);
  }
}

.link {
  @include font-size(30, $medium, 42);
  display: flex;
  align-items: center;
  color: #fff;

  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.links_list_second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 18px;
  color: #fff;
  margin: 0px 20px;
  svg {
    width: 20px;
    height: 20px;
  }
  .link_second {
    color: #fff;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .item_second {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .link_second_name {
    @include font-size(14, $medium, 20);
  }
}
