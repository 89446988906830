@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.modal {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(32, 39, 43, 0.6);
  @include flex-justify-align(center);
  transition: all 1s ease-in-out;
  transition: 0.5s;

  &_show {
    @extend .modal;
    opacity: 1;
  }

  &_hide {
    @extend .modal;
    pointer-events: none;
    opacity: 0;
  }

  &_wrapper {
    cursor: auto !important;
    position: fixed;
    display: flex;
    flex-direction: column;

    background: $white;
    box-shadow: 0px 32px 64px rgba(140, 136, 150, 0.3);
    border-radius: 4px;

    padding: 0;

    transform: scale(0.5);
    transition: all 0.5s ease-in-out;

    width: 720px;
    height: 842px;
    max-height: 100%;

    overflow-y: scroll;

    &_active {
      @extend .modal_wrapper;
      transform: scale(1);
      outline: none;
    }
  }

  @media (max-width: 450px) {
    &_wrapper {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      background: rgba(252, 252, 255, 1);
    }
  }
}

.header {
  padding: 38px 28px 49px;
  display: flex;
  justify-content: flex-end;

  .left_arrow_icon {
    display: none;
  }

  .close_icon {
    @include black-close-icon;
  }

  @media (max-width: 450px) {
    padding: 20px;
    justify-content: flex-start;
    .left_arrow_icon {
      display: block;
    }

    .close_icon {
      display: none;
    }
  }

  &_pickup_location {
    @extend .header;

    justify-content: space-between;
    flex-direction: row-reverse;

    .left_arrow_icon {
      display: block;
    }

    @media (max-width: 450px) {
      flex-direction: row;
    }
  }
}

.title {
  @include font-size(38, $bold, 49);
  color: $font-color-black-secondary;
  padding: 0 20px 30px;

  @media (max-width: 450px) {
    padding: 0 20px 10px;
    @include font-size(20, $bold, 42);
    color: rgba($font-color-black-secondary, 0.65);
  }
}

.shipping_options {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 30px;
  height: 544px;
  overflow-y: scroll;

  @media (max-width: 450px) {
    padding: 0 15px;
    height: max-content;
  }
}

.bottom {
  @include flex-justify-align(space-between);

  position: sticky;

  bottom: 0;
  left: 0;
  width: 100%;
  padding: 29px 20px;
  background-color: rgba(252, 252, 255, 1);

  &_submit {
    @include purple-btn();
    width: 152px;
    height: 45px;

    &:disabled {
      color: $white;
      background: $background-button;
      opacity: 0.2;
    }
  }

  &_cancel {
    border: 1px solid $purple;

    @include font-size(16, $medium, 20);
    color: $purple;
    height: 45px;

    background-color: $white;
    border-radius: 4px;

    padding: 12px 49px;
  }

  @media (max-width: 450px) {
    @include flex-justify-align(center);
    position: absolute;
    bottom: 0;
    &_submit {
      width: 260px;
    }

    &_cancel {
      display: none;
    }
  }
}

.pickup_locations {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  @media (max-width: 450px) {
    height: 100%;
  }
}
