@import "styles/vars";
@import "styles/mixins";

.tab {
  display: flex;
  flex-direction: column;

  &_btn {
    align-self: center;
    margin: 1rem;
  }
}

.loading {
  @include flex-justify-align(center);
  width: 100%;
  height: 50vh;

  div {
    width: auto;
    height: auto;
  }
  svg {
    width: 200px !important;
    height: 200px !important;
  }

  @media (max-width: 450px) {
    width: 100%;

    svg {
      width: 100px !important;
      height: 100px !important;
    }
  }
}
